
let REQ = {
    abbrs:0,
    addAbbr:0,
    addAbbrs:0,
    addAdmin:0,
    addCapa:0,
    addDept:0,
    addDocCrRole:0,
    addDocDept:0,
    addDocPrjt:0,
    addDocPrjtStaff:0,
    addDocStaff:0,
    addFollow:0,
    addIssue:0,
    addLaw:0,
    addLaws:0,
    addLinkOrg:0,
    addLinkOrgs:0,
    addMon:0,
    addMonTplt:0,
    addMonTopic:0,
    addDoc:0,
    addDocAnnex:0,
    addDocMaterial:0,
    addDocTplt:0,
    addTerm:0,
    addTerms:0,
    addPos:0,
    addPrjt:0,
    addPrjtStaff:0,
    addRemark:0,
    addSite:0,
    addSiteStaff:0,
    addStaff:0,
    addStone:0,
    addStones:0,
    addStoneSuit:0,
    addThrdDept:0,
    availStones:0,
    attendOrg:0,
    attendPrjt:0,
    attendSite:0,
    auditMvr:0,
    avalDeptsForPrjt:0,
    bindWx:0,
    blockMvr:0,
    carryMonDay:0,
    capaInfo:0,
    cancelRemark:0,
    chgDeptForDocs:0,
    chgPwd:0,
    chgPrjtForDocs:0,
    cloneCommonDocs:0,
    closeRemark:0,
    ckbindOrgs:0,
    disableStone:0,
    disableStoneSuit:0,
    doc:0,
    docCrRolesOfPrjt:0,
    docDept:0,
    docDeptsOfPrjt:0,
    docInLib:0,
    docPrjt:0,
    docPrjtInLib:0,
    docPrjts:0,
    docsOfDept:0,
    followInfo:0,
    genDocFile:0,
    issueInfo:0,
    issueStoneSuit:0,
    law:0,
    laws:0,
    linkOrg:0,
    linkOrgs:0,
    login: 'loginByPwd',
    loginBySms:0,
    loginByWx:0,
    loginOut:0,
    markStone:0,
    mileStone:0,
    monAnswers:0,
    monTypes:0,
    monInfo:0,
    monStateOfMonth:0,
    monTasksOfDay:0,
    monTopic:0,
    monTopics:0,
    monTplt:0,
    mvrDraft:0,
    myDocPrjts:0,
    myDocs:0,
    myOrg:0,
    myManagedDocPrjts:0,
    myPrjts:0,
    myStaffs:0,
    mySites:0,
    mySitesInfo:0,
    orgInfo:0,
    orgsLikeName:0,
    orgStoneMarks:0,
    ourMonTplts:0,
    ourDepts:0,
    ourPoses:0,
    ourStaffs:0,
    passMvr:0,
    previewDoc:0,
    previewMvr:0,
    prjtMonTplts:0,
    prjtMonTplt:0,
    prjtInfo:0,
    prjtsInDocLib:0,
    prjtsWMe:0,
    prjtsOfOrg:0,
    putFile:0,
    rmAbbrs:0,
    rmAdmin:0,
    rmDoc:0,
    rmDocCrRole:0,
    rmDocDept:0,
    rmDocMaterial:0,
    rmDocPrjtStaff:0,
    rmDocStaff:0,
    rmLaws:0,
    remarksInDocPrjt:0,
    remarksTo:0,
    replaceDocFile:0,
    replaceDocStudy:0,
    rmSiteStaff:0,
    rmTerms:0,
    rmPrjtStaff:0,
    rmDocAnnex:0,
    regOrg:0,
    regUser:0,
    sendVeriSms:0,
    setPrjtMonTplt:0,
    setPrjtStoneSuit:0,
    stoneSuitOfPrjt:0,
    setTopicAnswer:0,
    setPrjtStoneWkts:0,
    siteFollows:0,
    site:0,
    siteInfo:0,
    siteIssues:0,
    siteStaffs:0,
    smsNotiWDoc:0,
    smsVeri:0,
    docsOfPrjt:0,
    sop:0,
    docTplt:0,
    docTplts:0,
    stones:0,
    stoneSuit:0,
    stoneSuits:0,
    submitMvr:0,
    terms:0,
    topicAnswer:0,
    translate:0,
    unbindWx:0,
    upDocCrRole:0,
    unsetPrjtStoneSuit:0,
    unsetPrjtStoneWkts:0,
    upAbbr:0,
    upCapa:0,
    upDocDept:0,
    upDocPrjt:0,
    upDocState:0,
    upFollow:0,
    upIssue:0,
    upLaw:0,
    upLawCotent:0,
    upLinkOrg:0,
    upMe:0,
    upMon:0,
    upMonTplt:0,
    upMonTopic:0,
    upMvrDraft:0,
    upTopicAnswer:0,
    upOrg:0,
    upPrjt:0,
    upDoc:0,
    upDocAnnex:0,
    upSite:0,
    upDocTplt:0,
    upStaff:0,
    upStone:0,
    upStoneSuit:0,
    upSubjectCount:0,
    upTerm:0,
    upThrdDept:0,
    veriTasksOfDay:0,
    veriMonDay:0,
}

for (var p in REQ) {REQ[p] = 'wx/' + (REQ[p] || p)}

export default REQ
