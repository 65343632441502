// @ is an alias to /src
import Banner from "@/components/home/Banner.vue";
import Header from "@/components/home/Header.vue";
import Footer from "@/components/Footer.vue";
import HeaderSub from "@/components/home/HeaderSub.vue";
// import FooterSub from "@/components/home/FooterSub.vue";
import PubSub from "@/lib/Pub";
export default {
	name: "home",
	methods: {
		onJoinClick() {
			if (!this.$root.userInfo.name) {
				this.$router.push("/reg/user");
			} else if (!this.$root.userInfo.type) {
				this.$router.push("/reg/2");
			} else {
				this.$message("您已经是会员了哦");
			}
		},
		openCrc() {
			if (!this.$root.userInfo.name) {
				PubSub.publish("showLoginEvent");
			} else {
				if (this.$root.userInfo.type == 1) {
					window.location.href = "/#/statistics"
				} else if (this.$root.userInfo.type == 2) {
					window.location.href = "/#/smoStatistics";
				} else {
					this.$message({
						showClose: true,
						message: "对不起，您无访问权限！",
						type: "warning"
					});
				}
			}
		},
		openTest() {
			console.log('aaaa')
			console.log(this.$root.userInfo)
			//  this.$message({
			//   showClose: true,
			//   message: "对不起，暂未开放！",
			//   type: "warning"
			// });
			if (!this.$root.userInfo.name) {
				PubSub.publish("showLoginEvent");
			} else {
				if (this.$root.userInfo.type == 4 && this.$root.userInfo.type && this.$root.userInfo.isPass != 1) {
					this.$message({
						showClose: true,
						message: "对不起，您无访问权限！",
						type: "warning"
					});
				} else {
					var h0 = window.location.href.substring(0, window.location.href.lastIndexOf(window.location.pathname))
					window.location.href = h0 + "/#/assess";
				}
			}
		},
		jumpSetPage() {
			if (!this.$root.userInfo.name) {
				PubSub.publish("showLoginEvent");
			} else {
				if (this.$root.userInfo.type == 4) {
					this.$router.push({ name: "videoList" });
				} else {
					this.$message({
						showClose: true,
						message: "对不起，您无访问权限！",
						type: "warning"
					});
				}
			}
		}
	},
	components: {
		Banner,
		Header,
		Footer,
		HeaderSub,
		// FooterSub
	}
};
