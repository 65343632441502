import REQ from "../app/REQ";
import {MONDAY_STATE} from '@/consts.js'
import {genMap, assignMul, assignOne, linkObjByProp, upWFoundById} from "../utils/xyhUtils"
import issueCard from '../cards/issue.vue'
import followCard from '../cards/follow.vue'
// import monDayCard from '../cards/monDay.vue'
import addIssueDlg from "@/dialg/addIssueDlg.vue"
import addFollowDlg from "@/dialg/addFollowDlg.vue";
import markTopicDlg from "@/dialg/markTopicDlg.vue";

export default {
	name: "monitor",
	components:{issueCard, followCard, addIssueDlg, addFollowDlg, markTopicDlg },//, monDayCard
	props: ['id'],

	data() {
		return {
			 MONDAY_STATE,

			prjt: {},
			site:{},
			hospital:{},
			dept:{},
			mon:{},
			issues:[],
			follows:[],
			veriS:[],
			cra:{},
			monDays:[],
			topics: [],
			answers: [],
			answerMap: {},

			curMonDay:null,
			topicToMark: {},

		};
	},

	created() {
		let { id } = this
		this.req2Data(REQ.monInfo, this.id, null,
			({monDays, veris, staffs})=>{
				assignMul(veris, "monDay", monDays, "visits")
				linkObjByProp(veris, "visitee", staffs, "staff")
		})

		this.req2Data(REQ.monAnswers, {monId:id}, {}, r=>{
			let {topics, answers} = r
			let answerMap = genMap(answers, 'topic')
			Object.assign(this,{topics, answers, answerMap})
		})
	},

	methods: {
		carryDayMon: function (monId, date, visiteeIds) {
			this.authedReqR(REQ.carryMonDay, {id:monId, date, visitees:visiteeIds} )
		},

		onCheckin(day) {
			let {mon,site} = this
			this.authedReqR(REQ.siteStaffs, site.id, ({staffs})=>{
				let items = staffs.filter(it=>it.id != this.$root.curStaff.id)
				if(!items.length > 0)
					return this.carryDayMon(mon.id, day.date)

				this.selMul(items, '请选择拜访对象', seleds=>{
					this.carryDayMon(mon.id, day.date, seleds.map(it=>it.id))
				})
			})
		},

		onEditSum(){
			let {id, summary:sum} = this.mon
			this.editText(sum, "修改监查小结", summary=>{
				this.req2Data(REQ.upMon, {id, summary}, 'mon')
			} )
		},

		onMarkTopic(topic){
			this.topicToMark = topic;
			this.$refs['markTopicDlg'].show()
		},

		onCreateMvr(){

		},

		onViewMvr(){

		},
	}
};
