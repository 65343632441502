import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import ADMREQ from "@/app/ADMREQ";

export default {
    name: "editDocTpltDlg",
    mixins: [formDlgMix],
    props: ['oldDocTplt','orgId'],

    data() {
        return {
            uploadUrl:XyhConf.HglSHost +'/' + REQ.putFile,
            name:'',
            file:'',
            note:'',
            files:[]
        }
    },

    watch: {
        working(){
            let {oldDocTplt} = this
            if(oldDocTplt){
                Object.assign(this, {name:oldDocTplt.name, file:oldDocTplt.file, note:oldDocTplt.note,})
                if(oldDocTplt.file) this.files = [{name:'文件一'}]
            }else {
                Object.assign(this, {name:'', file:'', note:'',})
                this.files = []
            }
        }
    },

    methods: {
        handleChange(file,fileList){
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
        },

        successUpload(res){
            this.file = res.data.url
        },

        ok() {
            let {oldDocTplt, name, file, note,orgId} =this
            if(this.chk2msg(name, '请填写姓名', file, '请上传word文档',)) return
            let req = orgId ? (oldDocTplt ? ADMREQ.upDocTplt:ADMREQ.addDocTplt):(oldDocTplt ? REQ.upDocTplt:REQ.addDocTplt)
            let upObj = {name, file, note}
            if(oldDocTplt) upObj.id = oldDocTplt.id
            if(orgId) upObj.orgId = orgId
            this.reqThenBk(req, upObj)
        },
    }
};
