
export default {
	name: "capaCard",
	props:['capa'],

	data() {
		return {
		}
	},

	methods: {
	}
};
