import REQ from '@/app/REQ'
import {genMapByVs, upWFoundById} from '@/utils/xyhUtils'
import { mapGetters } from 'vuex'
import editAbbrDlg from "./editAbbrDlg.vue";
import {GXP, HA} from "@/consts";

export default {
    components:{editAbbrDlg,},

    data() {
        return {
            abbrs:[],
            abbr:'',
            isBatch:false,
            checks: {},
            sortingProperty:'',
            order:{
                name:true,
            },
            filterWord1:'',
            filterWord2:'',
            allAbbrs:[],
            gxp:'',
            authority:'',
            abbrsToAdd:[],
            gxps: {},
        }
    },
    created() {
        this.getAbbrs()
        this.add(0)
    },

    computed: {
        ...mapGetters(['amIAdmin']),

        displayAbbrs(){
            let {filterWord2 , gxp, order, abbrs, authority} = this
            abbrs.forEach(it=> {
                if (!it.source) it.source = ''
            })
            if(filterWord2.length>0) {
                abbrs = abbrs.filter(it =>
                    it.name.toLowerCase().includes(filterWord2.toLowerCase()) ||
                    it.fullCn.toLowerCase().includes(filterWord2.toLowerCase()) ||
                    it.fullEn.toLowerCase().includes(filterWord2.toLowerCase()) ||
                    it.source.toLowerCase().includes(filterWord2.toLowerCase())
                )
            }
            if(gxp) abbrs = abbrs.filter(it=>it.gxp && it.gxp.includes(gxp))
            if(authority) abbrs = abbrs.filter(it=>it.authority && it.authority.includes(authority))
            for(let i in order){
                if(order[i]) {
                    abbrs.sort((abbr1, abbr2) => abbr1[i].toLowerCase().localeCompare(abbr2[i].toLowerCase()))
                }else {
                    abbrs.sort((abbr1, abbr2) => abbr2[i].toLowerCase().localeCompare(abbr1[i].toLowerCase()))
                }
            }
            return abbrs
        }
    },

    methods: {
        getAbbrs(){
            this.req2Data(REQ.abbrs,{}, 'abbrs', abbrs=>{
                abbrs.forEach(it=> {
                    it.upt = new Date(it.upt).YMDFmt()
                    this.checks[it.id] = false
                })
                abbrs.forEach(it => {
                    if(it.gxps){
                        it.gxps.forEach(item => it.gxp = it.gxp ? it.gxp + '、' + item : item)
                    }
                    if(it.authorities){
                        it.authorities.forEach(item => it.authority = it.authority ? it.authority + '、' + item : item)
                    }
                })
            })
        },

        search() {
            this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
        },

        sorted(propertyN){
            this.sortingProperty = propertyN
            let order = !this.order[propertyN]
            delete this.order[propertyN]
            this.$set(this.order, propertyN, order)
        },

        setFilterProp(value, propN){
            this.$set(this, propN,value)
        },

        CheckedChange() {
            this.$forceUpdate()
        },

        batchDeleteAbbr(){
            let ids=[]
            let {checks} = this
            for(let i in checks){
                if(checks[i]) ids.push(i)
            }
            if(ids.length>0) this.req2Data(REQ.rmAbbrs,{ids},{}, ()=>this.getAbbrs())
            this.isBatch=false
        },

        eidtAbbr(abbr){
            this.abbr = abbr
            this.showDlg('editAbbrDlg')
        },

        selAll(idx, propN, selN){
            for(let i in this.abbrsToAdd[idx][propN]){
                this.abbrsToAdd[idx][propN][i] = this.abbrsToAdd[idx][selN]
            }
        },

        add(idx) {
            let gxps = {}
            let authorities = {}
            for(let i in GXP){ gxps[i] = false }
            for(let i in HA){ authorities[i] = false }
            this.abbrsToAdd.splice(idx, 0, {name:'', source:'', sourceUrl:'', des:'', gxps, authorities})
        },

        strikeOut(idx) {
            this.abbrsToAdd.splice(idx, 1)
        },

        submit() {
            let {abbrsToAdd} = this
            let add = true
            abbrsToAdd.forEach((it, idx) => {
                if (this.chk2msg(it.name && it.fullEn, '请补全或删除第' + (idx+1) + '行的内容',)) {
                add = false
                return false
            }})
            let abbrs = abbrsToAdd.map(it=> {
                let {selectAll, gxps, authorities, ...abbr} = it
                abbr.gxps = []
                for(let i in gxps){
                    if(gxps[i]) abbr.gxps.push(i)
                }
                abbr.authorities = []
                for(let i in authorities){
                    if(authorities[i]) abbr.authorities.push(i)
                }
                return abbr
            })
            if(add)
                this.req2Data(REQ.addAbbrs, {abbrs}, {},()=> {
                    this.getAbbrs()
                    this.abbrsToAdd = []
                    this.add(0)
                })
        },

        //文件上传调用的方法
        loadAbbrsFromExcel(file) {
            let XLSX = require("xlsx");
            let reader = new FileReader();
            reader.onload = e => {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                let sheetNames = workbook.SheetNames; // 工作表名称集合
                let worksheet = workbook.Sheets[sheetNames[0]]; // 这里我们只读取第一张sheet
                let title = {
                    name: '简写',
                    fullCn: '中文名称',
                    fullEn: '英文名称',
                    source: '来源',
                    sourceUrl: '来源网址',
                    gxps: 'gxp分类',
                    authorities: '药政来源',
                }                    //标题
                let mustTitles = ['name', 'fullEn']
                let math = [] //全部的数值
                let allCell = [] // 全部的数据
                let names = {} //符合标题的项
                let someData = {}   //前十行
                let titleCoordinate = ''  //标题位置
                let abbrs = []
                for (let it in worksheet) {
                    let Regx = /^[A-Z0-9]*$/;
                    if (Regx.test(it)) allCell.push(it)
                }
                allCell.forEach(it => {
                    math.push(it.replace(/[^0-9]/g, ''))
                })
                for (let it in worksheet) {
                    if (it.replace(/[^0-9]/g, '') <= 10) {
                        someData[it] = worksheet[it]
                    }
                }
                for (let it in someData) {
                    for (let i in title) {
                        if (someData[it].v == title[i]) {
                            names[i] = it.replace(/[^a-zA-Z]/g, '')
                            titleCoordinate = it.replace(/[^0-9]/g, '')
                        }
                    }
                }
                for(let i of mustTitles){
                    if(!names[i]){
                        this.$message('读取失败，缺少' + title[i] +'列，请修改后再次读取')
                        return
                    }
                }
                abbrs = Array.from(
                    {length: Math.max(...math)},
                    () => ({name: '',})
                );
                abbrs.forEach((it, idx) => {
                        for (let j in title) {
                            if (worksheet[names[j] + (idx + 1)]) it[j] = worksheet[names[j] + (idx + 1)].v
                        }
                    }
                )
                abbrs.splice(0, titleCoordinate)
                abbrs.forEach(it => {
                    let gxp = {}
                    for(let i in GXP){ gxp[i] = false }
                    if (it.gxps) {
                        it.selectAllGxp = true
                        let gxps = genMapByVs(...it.gxps.split(/，|、| |,/).map(it => it.toLowerCase()))
                        for (let i in gxp) {
                            if(gxps[i.toLowerCase()]) {
                                gxp[i] = true
                            } else if (it.selectAllGxp) {
                                it.selectAllGxp = false
                            }
                        }
                    }
                    it.gxps = gxp
                    let authority = {}
                    for(let i in HA){ authority[i] = false }
                    if (it.authorities) {
                        it.selectAllAuthority = true
                        let authorities = genMapByVs(...it.authorities.split(/，|、| |,/).map(it => it.toLowerCase()))
                        for (let i in authority) {
                            if(authorities[i.toLowerCase()]) {
                                authority[i] = true
                            } else if (it.selectAllAuthority) {
                                it.selectAllAuthority = false
                            }
                        }
                    }
                    it.authorities = authority
                })
                this.abbrsToAdd = this.abbrsToAdd.concat(abbrs)
                this.$message({
                    message: '读取成功，请确认有无遗漏后进行提交',
                    type: 'success'
                });
            };
            reader.readAsBinaryString(file);
            return false;
        },

        abbrsExport() {
            let {displayAbbrs} = this
            const {export_json_to_excel} = require('@/vendor/Export2Excel');
            const tHeader = ['简写', '中文名称', '英文名称', '来源', '来源网址', 'gxp分类', '药政来源', '更新时间' ];
            const filterVal = ['name', 'fullCn', 'fullEn', 'source', 'sourceUrl', 'gxp', 'authority', 'upt'];
            export_json_to_excel(tHeader, displayAbbrs.map(v => filterVal.map(j => v[j])), `缩略语表` + new Date().SFmt());
        },
    },

}
