import REQ from '@/app/REQ'
import editDocDlg from '@/dialg/editDocDlg.vue'
import {classifyByProp, genMap, upWFoundById} from "../utils/xyhUtils"
import {mapState} from "vuex";
import {DOC_TYPE, DOC_ROLE, DOC_STATE, LANGUAGE_MODEL} from "@/consts"
import docOptions from "./docOptions"
import docEditState from "@/doc/docEditState";
import rmDocDlg from "@/doc/rmDocDlg.vue";
import {wordViewUrl} from "@/doc/consts"

const {DocPathsSMap} = docOptions

export default {
    name: 'myDocs',
    components:{editDocDlg, rmDocDlg},

    data() {
        return {
            docPaths:DocPathsSMap,
            fileUrl:'',
            filterWord1:'',
            filterWord2:'',
            docs:[],
            alls:[],
            oldDoc:null,
            tplts:[],
            type:null,
            order:{
                num:true,
                prjtN:true,
            },
            sortingProperty:'',
            docStatus:'',
            docType:'unAnnex',
            planRmDoc: {},
            roleN:'authorN',
            filterOfRole:{
                authorN:'作者',
                auditorN:'审阅者',
                translatorN:'翻译',
                allStaffN:'全部',
            },
            refreshTime:'',
        }
    },

    computed: {
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,
            userId: ({curStaff}) => curStaff?.id,
        }),
        displayDocs(){
            let {filterWord2, docStatus, docType, order, docs,roleN} = this
            if(filterWord2.length>0) docs = this.docs.filter(it=>
                it.name.toLowerCase().includes(filterWord2) ||
                it.num.toLowerCase().includes(filterWord2) ||
                (it[roleN] && it[roleN].toLowerCase().includes(filterWord2))
            )
            if(docStatus) docs = docs.filter(it=>it.state == docStatus)
            if(docType) docs = docs.filter(it => docType == 'unAnnex' ? it.type != DOC_TYPE.annex:it.type == docType)
            for(let i in order){
                if(order[i]) {
                    docs.sort((doc1, doc2) => doc1[i].toLowerCase().localeCompare(doc2[i].toLowerCase()))
                }else {
                    docs.sort((doc1, doc2) => doc2[i].toLowerCase().localeCompare(doc1[i].toLowerCase()))
                }
            }
            return docs
        },
    },

    created() {
        this.token = this.getCookie('token')
        this.getDocs()
    },

    methods: {
        search() {
            this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
        },

        statusFilter(status){
            this.$set(this,'docStatus',status)
        },

        typeFilter(type){
            this.$set(this,'docType',type)
        },

        roleFilter(role){
            this.$set(this,'roleN',role)
        },

        sorted(propertyN){
            this.sortingProperty = propertyN
            let order = !this.order[propertyN]
            delete this.order[propertyN]
            this.$set(this.order,propertyN,order)
        },

        myDocsExport(typeN, stateN) {
            const {export_json_to_excel} = require('@/vendor/Export2Excel');
            const tHeader = ['文档编号', '中文名称', '计划起草完成时间', '计划定稿时间', '计划发布时间', '计划生效时间', '类型(' + typeN + ')', '状态(' + stateN + ')',];
            const filterVal = ['num', 'name', 'planRemarkDate', 'planFixDate', 'planIssueDate', 'planDate', 'type', 'state',];
            const data = this.displayDocs.map(doc=> {
                let {...doc2} = doc
                doc2.type = this.DocTypesSMap[doc.type]
                doc2.state = this.DocStatesSMap[doc.state]
                return doc2
            }).map(v => filterVal.map(j => v[j]))
            export_json_to_excel(tHeader, data, `我的文档`);
        },

        getDocs() {
            this.req2Data(REQ.myDocs, {}, null, ({docs,tplts,prjts,docStaffs,staffs,prjtStaffs})=>{
                upWFoundById(docs, prjts, 'prjtId', (doc, prjt) => {
                    if(prjtStaffs.find(it=>it.prjtId == doc.prjtId && it.role == DOC_ROLE.PM && it.staffId == this.userId)) doc.amIPM = true
                    doc.canPreview = doc.tpltId || ((!doc.type || doc.type == DOC_TYPE.sop) && prjt.sopTpltId) || (doc.type == DOC_TYPE.policy && prjt.policyTpltId) || (doc.type == DOC_TYPE.manual && prjt.manualTpltId)
                    doc.prjtN = prjt.name
                })
                upWFoundById(docStaffs, staffs, "staffId", (docStaff, staff) => docStaff.staffN = staff.name)
                let docIdOfStaffs = classifyByProp(docStaffs, 'docId')
                docs.forEach(doc=>{
                    let staffs = docIdOfStaffs[doc.id]
                    if(staffs){
                        let authors = staffs.filter(it=>it.role == DOC_ROLE.author)
                        let auditors = staffs.filter(it=>it.role == DOC_ROLE.auditor)
                        let translators = staffs.filter(it=>it.role == DOC_ROLE.translator)
                        let allStaffs = Object.values(genMap(staffs, 'staffId'))
                        doc.amIStaff = staffs.find(it=>it.staffId == this.userId) ? true:false
                        doc.amIAuthor = authors.find(it=>it.staffId == this.userId) ? true:false
                        doc.amITranslator = translators.find(it=>it.staffId == this.userId) ? true:false
                        doc.authorN = authors.map(it=>it.staffN).join('、')
                        doc.auditorN = auditors.map(it=>it.staffN).join('、')
                        doc.translatorN = translators.map(it=>it.staffN).join('、')
                        doc.allStaffN = allStaffs.map(it=>it.staffN).join('、')
                    }
                })
                upWFoundById(docs, tplts, 'tpltId', (doc, tplt) => doc.tpltN = tplt.name)
                docs.forEach(it=> {
                    if(!it.type) it.type = DOC_TYPE.sop
                    if(!it.state) it.state = DOC_STATE.remarking
                    it.url = ((it.type == DOC_TYPE.annex || it.type == DOC_TYPE.file) && it.file) ? it.file:
                        XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + it.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
                })
                this.refreshTime = new Date().HMFmt()
            })
        },

        editDoc(oldDoc,type){
            this.oldDoc = oldDoc
            this.type = type
            this.showDlg('editDocDlg')
        },

        preview(url){
            window.open(wordViewUrl + encodeURIComponent(url))
        },
    },

}
