import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "editTermDlg",
    mixins: [formDlgMix],
    props: ['beTerm'],

    data() {
        return {
            term: {
                name: '',
                name_E: '',
                des: '',
                des_E: '',
                source: '',
                sourceUrl: '',
                gxps: [],
                authorities: [],
            },
        }
    },

    watch: {
        working() {
            let {beTerm} = this
            if (beTerm) {
                this.term = {
                    name: beTerm.name,
                    name_E: beTerm.name_E,
                    des: beTerm.des,
                    des_E: beTerm.des_E,
                    source: beTerm.source,
                    sourceUrl: beTerm.sourceUrl,
                    gxps: beTerm.gxps,
                    authorities: beTerm.authorities,
                }
            } else {
                this.term = {name: '', name_E: '', des: '', des_E: '', source: '', sourceUrl: '', gxps: [], authorities: []}
            }
        }
    },

    methods: {
        ok() {
            let {term, beTerm} = this
            let {name, name_E} = term
            if (this.chk2msg(name || name_E, '中文名称或英文名称至少存在一个')) return;
            if (beTerm) {
                let newTerm = {id: beTerm.id}
                for (let it in term) {
                    if (term[it] != beTerm[it]) newTerm[it] = term[it]
                }
                if(newTerm.name || newTerm.name_E || newTerm.des || newTerm.des_E || newTerm.gxps || newTerm.source || newTerm.sourceUrl || newTerm.authorities){
                    this.reqThenBk(REQ.upTerm, newTerm)
                }else {
                    this.$message('请至少修改一项内容')
                }
            } else {
                this.reqThenBk(REQ.addTerm, term)
            }

        }
    }
};
