
import prjtCon from "@/coms/prjt.vue";
import prjtInfoRts from "@/views/prjtInfoRts";
import siteRts from "@/views/siteRts";
import prjtInfoCon from "@/views/prjtInfo.vue";
import prjtStoneSuit from "@/coms/prjtStoneSuit.vue"

export default {
	path: 'prjt/:pid',
	component: prjtCon,
	props: true,
	children:[
		prjtInfoRts,
		siteRts,
		{path: 'prjtStoneSuit', props: true, component: prjtStoneSuit},
		{ path: '', component: prjtInfoCon} ,
	],
}