
import dlgFoot from "@/generalComs/dlgFoot.vue"

export default {
    components:{ dlgFoot },

    data() {
        return {
            formLabelWidth: '120px',
            working: false,
            okFun:null,
            title:'',
        }
    },

    methods:{
        close(){ this.working = false },

        show(title, okFun, ...params) {
            this.setParams(...params)
            Object.assign(this, {working:true, title, okFun})
            return this
        },

        onOk() {
            let reses = this.results()
            if(!(reses && reses.length > 0)) return
            let msg = this.okFun(...reses)
            if(!msg) this.close()
            else if(typeof msg == 'string' )
                this.warn(msg)
        }
    }
}