<template>
    <el-form style="width: 75%;float: right">
      <el-select v-model="selected" placeholder="请选择">
        <el-option v-for="it in itemss" :label="valKN ? (it[labelKN || valKN]):it" :value="valKN ? it[valKN]:it"></el-option>
      </el-select>
    </el-form>
</template>

<script>
export default {
  name: "sel",
  props:['selecting','items','valK','labelK', 'fd'],

  data() {
    return {
      itemss:[],
      selected:'',
      valKN: 'id',
      labelKN: 'name',
    }
  },

  watch:{
    selected(){
      this.$emit('func', {fd:this.fd, value:this.selected})
    }
  },

  created() {
    this.setParams(this.items, this.selecting, this.valKN, this.labelKN)
  },

  methods:{
    setParams(itemss, selected, valK, labelK) {
      if(typeof valK == 'undefined') this.valKN = 'id'
      if(typeof labelK == 'undefined') this.labelKN = 'name'
      Object.assign(this,{itemss, selected})
    },

  }

}
</script>

<style scoped>

</style>