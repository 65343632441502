import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "selRefDocDlg",
    mixins: [formDlgMix],
    props: ['prjts','myId','refFlows', 'prjt'],

    data() {
        return {
            docs:[],
            docId:'',
            prjtId:'',
        }
    },

    watch: {
        working(){
            Object.assign(this,{docs:[], docId:'', prjtId:'',})
            this.getDocPrjt()
        }
    },

    methods: {
        getDocPrjt() {
            let {id} = this.prjt
            this.req2Data(REQ.docPrjt, id, {docs:'docs'},({docs})=> {
                this.docId = ''
                docs.sort((doc1, doc2) => doc1.num.toLowerCase().localeCompare(doc2.num.toLowerCase()))
                for(let i = 0; i < docs.length; i++){
                    if(docs[i].id == this.myId){
                        this.docs.splice(i,1)
                        break
                    }
                }
            })
        },

        onOk(){
            let {docs,docId,refFlows} = this
            if(this.chk2msg(docId,'请选择文件',!refFlows.find(it=>it.id == docId),'不可重复添加')) return
            let doc = docs.find(it=>it.id == docId)
            this.$emit('oked',doc)
            this.working =false
        }
    }
};