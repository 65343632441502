import Vue from 'vue'
import Vuex from 'vuex'
import localStore from "@/utils/localStore";

Vue.use(Vuex)

const keyUInfo = 'curUser'

const store = new Vuex.Store({
    state: {
        result: '',
        id: '',

        myself:null,
        myConf:{},
        curOrg:null,
        curStaff:null,
        staffConf:{},

    },

    getters:{
        amIAdmin: ({curStaff}) => curStaff?.admin ,
        amIPlatAdm: ({myself}) => myself?.platAdm ,
    },

    mutations: {

        loadUserInfo(state) {
            let {token, curUser} = localStore.load(keyUInfo)
            this.commit('setCurUser', {user:curUser, token, memOnly:true})
            let {id:uid} = curUser || {}
            let keyStaff = uid?`uStaff_${uid}` :''
            if(uid) {
                let {curOrg, curStaff} = localStore.load(keyStaff)
                this.commit('setCurOrg', {org:curOrg, staff:curStaff, memOnly:true})
            }
        },

        setCurUser(state, {user, token, memOnly} ) {
            if (!user || !user.id || !token) {
                Object.assign(state, {token: null, myself: null, myConf: {}, curOrg: null, curStaff: null, staffConf: {}})
                !memOnly && localStore.remove(keyUInfo)
                return
            }

            Object.assign(state, {token, myself: user, myConf: {}, curOrg: null, curStaff: null, staffConf: {}})

            !memOnly && localStore.save(keyUInfo, {token, curUser: user})
            let {id: uid} = state.myself
            let myConf = localStore.load(`uConf_${uid}`)
            Object.assign(state, {myConf})
        },

        setCurOrg(state, {org, staff, memOnly}) {
            let {id: uid} = state.myself || {}
            let keyStaff = uid?`uStaff_${uid}` :''

            if (!uid || !org || !staff || !staff.id) {
                Object.assign(state,{curOrg: null, curStaff: null, staffConf: {}})
                uid && !memOnly && localStore.remove(keyStaff)
                return
            }
            let staffConf = localStore.load(`staffConf_${uid}_${staff.id}`)
            Object.assign(state, {curOrg: org, curStaff: staff, staffConf})
            !memOnly && localStore.save(keyStaff, {curOrg: org, curStaff: staff})
        },

        newResult(state, msg) {
            state.result = msg
        },

        getId(state, id) {
            state.id = id
        },
    }
})

export default store
