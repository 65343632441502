import {mapGetters, mapState} from "vuex";

let admMenus = [
    {n: '方案管理', p: '/profiles', icon: 'icon2'},
    {n: '平台概况', p: '/platformStatistics', icon: 'icon0'},
    {n: '机构管理', p: '/CraMechanismList', icon: 'icon1'},
    {n: '企业管理', p: '/BidCompanyList', icon: 'icon2'},
    {n: '组织审核', p: '/review', icon: 'icon2'},
    {n: '机构审核', p: '/main/auditOrg/1', icon: 'icon2'},
    {n: '企业审核', p: '/main/auditOrg/2', icon: 'icon2'},
    {n: '带教审核', p: '/main/auditOrg/3', icon: 'icon2'},
]

let stoneNavs=[
	{n: '临床项目', p: 'myPrjts'},
	{n: '我的中心', p: 'mySites'},
	{n: '里程碑', p: 'stoneMarkState'},
	{n: '关联单位', p: 'linkOrgs'}
]

let sopNavs=[
	{n: '文档项目', p: 'myDocPrjts',},
	{n: '我的文档', p: 'myDocs'},
	// {n: 'sop管理', p: 'sops'},
	// {n: 'sop模板', p: 'editSopModel'},
	// {n: '文档模板管理', p: 'docTplts', admin:true},
	{n: '缩略语库', p: 'abbrs',},
	{n: '术语库', p: 'terms',},
	{n: '法规库', p: 'refLaws'},
]

let docLibNavs=[
	{n: '平台文档库', p: 'libDocPrjts'},
]

let Navs = [
	{n: '公司项目', p: 'companyPrjt', admin:true},
	{n: '我的单位', p: 'myOrg', admin:true},
]

 let devNavs = [
// 	{n: '*任务', p: 'tasks',},
// 	{n: '*Issue', p: 'issues',},
// 	{n: '*消息', p: 'msgs',},
// 	{n: '*方案', p: 'profiles',},
 ]


export default {
	name: 'primaryNav',
	props: ['prjts', 'activeIndex'],
	data() {
		return {
			Navs,
			devNavs,
			stoneNavs,
			sopNavs,
			navs:{
				stone:stoneNavs,
				sop:sopNavs,
				docLib:docLibNavs,
			},
			deving:XyhConf.deving,
			active: this.activeIndex || 0,
		}
	},

	computed: {
		...mapState({orgProducts: ({ curOrg }) => curOrg?.products}),
		...mapGetters(['amIAdmin']),
	},

	methods: {

	}
}
