export default {
	name: "Footer",
	data: () => {
		return {
			contactList: [
				{
					icon: "wecat",
					type: "联系人微信",
					link: ""
				},
				{
					icon: "mail",
					type: "联系人邮箱",
					link: ""
				},
				{
					icon: "tel",
					type: "联系人电话",
					link: ""
				},
				{
					icon: "wePub",
					type: "",
					link: ""
				}
			]
		};
	},
	components: {}
};
