import REQ from '@/app/REQ'
import editDocTpltDlg from "@/dialg/editDocTpltDlg.vue";

export default {
    name: 'docTplts',
    components:{editDocTpltDlg,},

    data() {
        return {
            docTplts:[],
            docTplt:null,
        }
    },

    created() {
        this.getDocTplts()
    },

    methods: {
        getDocTplts() {
            this.req2Data(REQ.docTplts, {},'docTplts')
        },

        editDocTplt(docTplt){
            this.docTplt = docTplt
            this.showDlg('editDocTpltDlg')
        },
    },

}
