import { render, staticRenderFns } from "./currencyInput.vue?vue&type=template&id=38a0ea34&scoped=true&"
import script from "./currencyInput.js?vue&type=script&lang=js&"
export * from "./currencyInput.js?vue&type=script&lang=js&"
import style0 from "../assets/css/doc.less?vue&type=style&index=0&id=38a0ea34&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a0ea34",
  null
  
)

export default component.exports