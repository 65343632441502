import REQ from '@/app/REQ'
import {formatDate, genMapByVs, isDate} from "../utils/xyhUtils"
import {mapGetters} from 'vuex'
import reviseRefLawDlg from './reviseRefLaw.vue'
import {GXP, HA} from "@/consts";
import dateFilterMix from "./dateFilterMix";

export default {
    components:{reviseRefLawDlg,},
    mixins:[dateFilterMix],

    data() {
        return {
            refLaws:[],
            refLaw:'',
            isBatch:false,
            checks: {},
            lawsToAdd:[],
            gxps: {},
            filterWord1:'',
            filterWord2:'',
            authority:'',
            gxp:'',
            state:'',
            type:'',
            finalDate:['',''],
            sortingProperty:'',
            order:{
                name:true,
            },
        }
    },

    created() {
        this.getData()
        this.add(0)
    },

    computed: {
        ...mapGetters(['amIAdmin','amIPlatAdm']),

        displayLaws(){
            let {filterWord2 , gxp, state, type, authority, order, refLaws, finalDate} = this
            if(finalDate && (finalDate[0] || finalDate[1])) refLaws = refLaws.filter(it => finalDate[0] <= it.finalDate && finalDate[1] >= it.finalDate)
            refLaws.forEach(it=>{
                if(!it.org) it.org = ''
                if(!it.releaseNum) it.releaseNum = ''
            })
            if(filterWord2.length>0) {
                refLaws = refLaws.filter(it =>
                    it.name.toLowerCase().includes(filterWord2.toLowerCase()) ||
                    it.org.toLowerCase().includes(filterWord2.toLowerCase()) ||
                    it.releaseNum.toLowerCase().includes(filterWord2.toLowerCase())
                )
            }
            if(authority) refLaws = refLaws.filter(it=>it.authority && it.authority.includes(authority))
            if(gxp) refLaws = refLaws.filter(it=>it.gxp && it.gxp.includes(gxp))
            if(state) refLaws = refLaws.filter(it=>it.state == state)
            if(type) refLaws = refLaws.filter(it=>it.type == type)
            for(let i in order){
                if(order[i]) {
                    refLaws.sort((term1, term2) => term1[i].toLowerCase().localeCompare(term2[i].toLowerCase()))
                }else {
                    refLaws.sort((term1, term2) => term2[i].toLowerCase().localeCompare(term1[i].toLowerCase()))
                }
            }
            return refLaws
        }
    },

    methods: {
        getData(){
            this.req2Data(REQ.laws,{sDate:this.sDate,eDate:this.eDate}, 'refLaws', refLaws=> {
                refLaws.forEach(it => {
                    if(it.gxps){
                        it.gxps.forEach(item => it.gxp = it.gxp ? it.gxp + '、' + item : item)
                    }
                    if(it.authorities){
                        it.authorities.forEach(item => it.authority = it.authority ? it.authority + '、' + item : item)
                    }
                    it.finalDate = it.validDate || it.releaseDate || it.adviceDate || ''
                })
            })
        },

        setFilterWord() {
            this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
        },

        sorted(propertyN){
            this.sortingProperty = propertyN
            let order = !this.order[propertyN]
            delete this.order[propertyN]
            this.$set(this.order, propertyN, order)
        },

        setFilterProp(value, propN){
            this.$set(this, propN,value)
        },

        batchDeleteRefLaw(){
            let ids=[]
            let {checks, displayLaws} = this
            displayLaws.forEach((it)=> {if (checks[it.id]) ids.push(it.id)})
            if(ids.length>0) this.req2Data(REQ.rmLaws,{ids},{}, ()=>this.getData())
            this.isBatch=false
        },

        eidtRefLaw(refLaw){
            this.refLaw = refLaw
            this.showDlg('reviseRefLawDlg')
        },

        selAll(idx, propN, selN){
            for(let i in this.lawsToAdd[idx][propN]){
                this.lawsToAdd[idx][propN][i] = this.lawsToAdd[idx][selN]
            }
        },

        add(idx) {
            let gxps = {}
            let authorities = {}
            for(let i in GXP){ gxps[i] = false }
            for(let i in HA){ authorities[i] = false }
            this.lawsToAdd.splice(idx, 0, {
                name: '',
                type: '',
                org: '',
                releaseNum: '',
                state: '',
                adviceDate: '',
                validDate: '',
                releaseDate: '',
                linkUrl: '',
                gxps,
                authorities,
            })
        },

        strikeOut(idx) {
            this.lawsToAdd.splice(idx, 1)
        },

        submit() {
            let {lawsToAdd} = this
            let add = true
            lawsToAdd.forEach((it, idx) => {if (this.chk2msg(it.name && it.type && it.state, '请补全第' + (idx+1) + '行的内容',)) {
                add = false
                return false
            }})
            let laws = lawsToAdd.map(it=> {
                let {selectAll, gxps, authorities, ...law} = it
                law.gxps = []
                for(let i in gxps){
                    if(gxps[i]) law.gxps.push(i)
                }
                law.authorities = []
                for(let i in authorities){
                    if(authorities[i]) law.authorities.push(i)
                }
                return law
            })
            if (add)
                this.req2Data(REQ.addLaws, {laws}, {}, () => {
                    this.getData()
                    this.lawsToAdd = []
                    this.add(0)
                })
        },

        //文件上传调用的方法
        loadLawsFromExcel(file) {
            let XLSX = require("xlsx");
            let reader = new FileReader();
            reader.onload = e => {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                let sheetNames = workbook.SheetNames; // 工作表名称集合
                let worksheet = workbook.Sheets[sheetNames[0]]; // 这里我们只读取第一张sheet
                let title = {
                    name: '名称',
                    type: '法规类型',
                    state: '状态',
                    org: '发布机构',
                    releaseNum: '发布号',
                    gxps: 'gxp分类',
                    authorities: '药政来源',
                    adviceDate: '征求意见稿时间',
                    releaseDate: '发布时间',
                    validDate: '生效时间',
                    linkUrl: '法规来源',
                }                    //标题
                let mustTitles = ['name',]
                let math = [] //全部的数值
                let allCell = [] // 全部的数据
                let names = {} //符合标题的项
                let someData = {}   //前十行
                let titleCoordinate = ''  //标题位置
                let laws = []
                for (let it in worksheet) {
                    let Regx = /^[A-Z0-9]*$/;
                    if (Regx.test(it)) allCell.push(it)
                }
                allCell.forEach(it => {
                    math.push(it.replace(/[^0-9]/g, ''))
                })
                for (let it in worksheet) {
                    if (it.replace(/[^0-9]/g, '') <= 10) {
                        someData[it] = worksheet[it]
                    }
                }
                for (let it in someData) {
                    for (let i in title) {
                        if (someData[it].v == title[i]) {
                            names[i] = it.replace(/[^a-zA-Z]/g, '')
                            titleCoordinate = it.replace(/[^0-9]/g, '')
                        }
                    }
                }
                for(let i of mustTitles){
                    if(!names[i]){
                        this.$message('读取失败，缺少' + title[i] +'列，请修改后再次读取')
                        return
                    }
                }
                laws = Array.from(
                    {length: Math.max(...math)},
                    () => ({name: '',})
                );
                laws.forEach((it, idx) => {
                        for (let j in title) {
                            if (worksheet[names[j] + (idx + 1)]) it[j] = worksheet[names[j] + (idx + 1)].v
                        }
                    }
                )
                laws.splice(0, titleCoordinate)
                for(let it of laws){
                    let idx = 2
                    it.type = this.LawTypesSMap2Id[it.type]
                    it.state = this.LawStatesSMap2Id[it.state]
                    if(it.adviceDate && typeof it.adviceDate == "number") it.adviceDate = formatDate(it.adviceDate,'-')
                    if(it.releaseDate && typeof it.releaseDate == "number") it.releaseDate = formatDate(it.releaseDate,'-')
                    if(it.validDate && typeof it.validDate == "number") it.validDate = formatDate(it.validDate,'-')
                    if((it.adviceDate && !isDate(it.adviceDate)) || (it.releaseDate && !isDate(it.releaseDate)) || (it.validDate && !isDate(it.validDate))) {
                        this.$message('第' + (idx+1) + '行的日期格式不正确,请修改后再行提交')
                        return;
                    }
                    idx++
                }
                laws.forEach(it=> {
                    let gxp = {}
                    for(let i in GXP){ gxp[i] = false }
                    if (it.gxps) {
                        it.selectAllGxp = true
                        let gxps = genMapByVs(...it.gxps.split(/，|、| |,/).map(it => it.toLowerCase()))
                        for (let i in gxp) {
                            if(gxps[i.toLowerCase()]) {
                                gxp[i] = true
                            } else if (it.selectAllGxp) {
                                it.selectAllGxp = false
                            }
                        }
                    }
                    it.gxps = gxp
                    let authority = {}
                    for(let i in HA){ authority[i] = false }
                    if (it.authorities) {
                        it.selectAllAuthority = true
                        let authorities = genMapByVs(...it.authorities.split(/，|、| |,/).map(it => it.toLowerCase()))
                        for (let i in authority) {
                            if(authorities[i.toLowerCase()]) {
                                authority[i] = true
                            } else if (it.selectAllAuthority) {
                                it.selectAllAuthority = false
                            }
                        }
                    }
                    it.authorities = authority
                })
                this.lawsToAdd = this.lawsToAdd.concat(laws)
                this.$message({
                    message: '读取成功，请确认有无遗漏后进行提交',
                    type: 'success'
                });
            };
            reader.readAsBinaryString(file);
            return false;
        },

        lawsExport() {
            let {refLaws} = this
            const {export_json_to_excel} = require('@/vendor/Export2Excel');
            let tHeader = ['名称', '法规类型', '发布机构', '发布号', '状态', '法规来源', 'gxp分类', '药政来源', '征求意见稿时间', '发布时间', '生效时间'];
            let filterVal = ['name', 'type', 'org', 'releaseNum', 'state', 'linkUrl', 'gxp', 'authority', 'adviceDate', 'releaseDate', 'validDate'];
            const data = refLaws.map(law=> {
                let {...law2} = law
                law2.type = this.LawTypesSMap[law.type]
                law2.state = this.LawStatesSMap[law.state]
                return law2
            }).map(v => filterVal.map(j => v[j]))
            export_json_to_excel(tHeader, data, `法规表` + new Date().SFmt());
        },
    },

}
