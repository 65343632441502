
import prjtInfoCon from "@/views/prjtInfo.vue";
import deving from "@/coms/deving.vue";
import prjt from "@/coms/prjt.vue"


export default {
	path: 'info',
	component: prjtInfoCon,
	props: true,
	children: [
		{ path: 'filter', component: deving, },
		{ path: 'launch', component: deving, },
		{ path: 'subjects', component: deving, },
		{ path: 'issues', component: deving, },
		{ path: 'mvrs', component: deving, },
		{ path: 'tasks', component: deving, },
		{ path: 'stone', component: deving, },
		{ path: 'team', component: deving, },
		{ path: '', props: true, component: prjt, },
	],
}