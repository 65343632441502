import REQ from "../app/REQ";
import addDeptDlg from "@/dialg/addDeptDlg.vue";
import editStaffDlg from "@/dialg/editStaffDlg.vue";
import {STAFF_STATE} from "@/consts";

export default {
    name: 'myOrg',
    components:{addDeptDlg,editStaffDlg,},

    data() {
        return {
            org:{},
            depts: [],
            staffs:[],
            poses: [],
            monTplts:[],
            stones: [],
            staff:false,
            showAllStaff:false,

            newDept:null,
    }},

    computed:{
        disPalyStaffs(){
            let {staffs, showAllStaff} = this
            if(!showAllStaff) staffs = staffs.filter(staff => staff.state != STAFF_STATE.stopped)
            return staffs
        }
    },

    created() {
        this.getMyOrg()
    },

    methods: {
        getMyOrg(){
            this.req2Data(REQ.myOrg, null, null, ({staffs})=>{
                staffs.forEach(staff =>{if(!staff.state) staff.state = STAFF_STATE.ok})
            })
        },

        editAdmin(isAdmin,staffId){
            this.$confirm('确认'+ (isAdmin ? '取消':'设为') +'管理员吗？','提示',{
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning',
            }).then(()=>{
                this.req2Data(isAdmin ? REQ.rmAdmin:REQ.addAdmin, {staffId},{},()=>this.getMyOrg())
            })
        },

        addStaff(){
            this.staff=false
            this.showDlg('editStaffDlg')
        },

        editStaff(staff){
            this.staff=staff;
            this.showDlg('editStaffDlg')
        },

        editStaffState(staff){
            let des = '停用'
            let state = STAFF_STATE.stopped
            if(staff.state == STAFF_STATE.stopped){
                des = '启用'
                state = STAFF_STATE.ok
            }
            this.confirm('此操作将'+ des + '该账号, 是否继续?', ()=>{
                this.req2Data(REQ.upStaff, {id:staff.id, state}, {}, ()=>this.getMyOrg())
            })
        },

        onStaffAdded() {
            this.getMyOrg()
            this.staff=false
        },

        onDeptAdded(dept) {
            this.req2Data(REQ.ourDepts, null, 'depts')
            this.newDept = dept
        },

        onEdit(){

        }
    },

}
