import { REQ } from '../../services/config'

import Cookies from "js-cookie";
import Nav from './Nav.vue'
import PubSub from '@/lib/Pub'

export default {
	name: 'Header',
	props: ['activeBar',],
	methods: {
		login() {
			PubSub.publish('showLoginEvent')
		},
		loginOut() {
		    this.authedReqR(REQ.loginOut, null, null, null, ()=>{
		        this.setCurUser()
		    })
		},
		register() {
			PubSub.publish('registerUser')
		},
	},
	components: {
		Nav,
	},
}
