import REQ from '@/app/REQ'
import { mapGetters } from 'vuex'
import editTermDlg from "@/dialg/editTermDlg.vue";
import { GXP, HA, } from '@/consts.js'
import {genMapByVs} from "@/utils/xyhUtils";

export default {
    components:{editTermDlg,},

    data() {
        return {
            terms:[],
            term: null,
            isBatch:false,
            checks: {},
            filterWord1:'',
            filterWord2:'',
            gxp:'',
            authority:'',
            sortingProperty:'',
            order:{
                name:true,
            },
            termsToAdd:[],
            gxps: {},
        }
    },
    created() {
        this.getTerms()
        this.add(0)
    },

    computed: {
        ...mapGetters(['amIAdmin']),

        displayTerms(){
            let {filterWord2 , gxp, order, terms, authority} = this
            terms.forEach(it=> {
                if (!it.source) it.source = ''
                if (!it.name_E) it.name_E = ''
            })
            if(filterWord2.length>0) {
                terms = terms.filter(it =>
                    it.name.toLowerCase().includes(filterWord2.toLowerCase()) ||
                    it.name_E.toLowerCase().includes(filterWord2.toLowerCase()) ||
                    it.source.toLowerCase().includes(filterWord2.toLowerCase())
                )
            }
            if(gxp) terms = terms.filter(it=>it.gxp && it.gxp.includes(gxp))
            if(authority) terms = terms.filter(it=>it.authority && it.authority.includes(authority))
            for(let i in order){
                if(order[i]) {
                    terms.sort((term1, term2) => term1[i].toLowerCase().localeCompare(term2[i].toLowerCase()))
                }else {
                    terms.sort((term1, term2) => term2[i].toLowerCase().localeCompare(term1[i].toLowerCase()))
                }
            }
            return terms
        }
    },

    methods: {
        getTerms(){
            this.req2Data(REQ.terms,{}, 'terms', terms=>{
                this.checks = terms.map(_=>false)
                terms.forEach(it => {
                    if(it.gxps){
                        it.gxps.forEach(item => it.gxp = it.gxp ? it.gxp + '、' + item : item)
                    }
                    if(it.authorities){
                        it.authorities.forEach(item => it.authority = it.authority ? it.authority + '、' + item : item)
                    }
                })
            })
        },

        search() {
            this.$set(this,'filterWord2',this.filterWord1.toLowerCase())
        },

        sorted(propertyN){
            this.sortingProperty = propertyN
            let order = !this.order[propertyN]
            delete this.order[propertyN]
            this.$set(this.order, propertyN, order)
        },

        setFilterProp(value, propN){
            this.$set(this, propN,value)
        },

        batchDeleteTerm(){
            let ids=[]
            let {checks} = this
            for(let i in checks){
                if(checks[i]) ids.push(i)
            }
            if(ids.length>0) this.req2Data(REQ.rmTerms,{ids},{}, ()=>this.getTerms())
            this.isBatch=false
        },

        eidtTerm(term){
            this.term = term
            this.showDlg('editTermDlg')
        },

        selAll(idx, propN, selN){
            for(let i in this.termsToAdd[idx][propN]){
                this.termsToAdd[idx][propN][i] = this.termsToAdd[idx][selN]
            }
        },

        add(idx) {
            let gxps = {}
            let authorities = {}
            for(let i in GXP){ gxps[i] = false }
            for(let i in HA){ authorities[i] = false }
            this.termsToAdd.splice(idx, 0, {name:'', source:'', sourceUrl:'', des:'', gxps, authorities})
        },

        strikeOut(idx) {
            this.termsToAdd.splice(idx, 1)
        },

        submit() {
            let {termsToAdd} = this
            let add = true
            termsToAdd.forEach((it, idx) => {if (this.chk2msg(it.name && it.des, '请补全第' + (idx+1) + '行的内容',)) {
                add = false
                return false
            }})
            let terms = termsToAdd.map(it=> {
                let {selectAll, gxps, authorities, ...term} = it
                term.gxps = []
                for(let i in gxps){
                    if(gxps[i]) term.gxps.push(i)
                }
                term.authorities = []
                for(let i in authorities){
                    if(authorities[i]) term.authorities.push(i)
                }
                return term
            })
            if(add)
                this.req2Data(REQ.addTerms, {terms}, {},()=> {
                    this.getTerms()
                    this.termsToAdd = []
                    this.add(0)
                })
        },

        //文件上传调用的方法
        loadTermsFromExcel(file) {
            let XLSX = require("xlsx");
            let reader = new FileReader();
            reader.onload = e => {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                let sheetNames = workbook.SheetNames; // 工作表名称集合
                let worksheet = workbook.Sheets[sheetNames[0]]; // 这里我们只读取第一张sheet
                let title = {
                    name: '中文名称',
                    name_E: '英文名称',
                    source: '来源',
                    sourceUrl: '来源网址',
                    gxps: 'gxp分类',
                    authorities: '药政来源',
                    des: '中文描述',
                    des_E: '英文描述',
                }                    //标题
                let mustTitles = ['name','des']
                let math = [] //全部的数值
                let allCell = [] // 全部的数据
                let names = {} //符合标题的项
                let someData = {}   //前十行
                let titleCoordinate = ''  //标题位置
                let terms = []
                for (let it in worksheet) {
                    let Regx = /^[A-Z0-9]*$/;
                    if (Regx.test(it)) allCell.push(it)
                }
                allCell.forEach(it => {
                    math.push(it.replace(/[^0-9]/g, ''))
                })
                for (let it in worksheet) {
                    if (it.replace(/[^0-9]/g, '') <= 10) {
                        someData[it] = worksheet[it]
                    }
                }
                for (let it in someData) {
                    for (let i in title) {
                        if (someData[it].v == title[i]) {
                            names[i] = it.replace(/[^a-zA-Z]/g, '')
                            titleCoordinate = it.replace(/[^0-9]/g, '')
                        }
                    }
                }
                for(let i of mustTitles){
                    if(!names[i]){
                        this.$message('读取失败，缺少' + title[i] +'列，请修改后再次读取')
                        return
                    }
                }
                terms = Array.from(
                    {length: Math.max(...math)},
                    () => ({name: '',})
                );
                terms.forEach((it, idx) => {
                        for (let j in title) {
                            if (worksheet[names[j] + (idx + 1)]) it[j] = worksheet[names[j] + (idx + 1)].v
                        }
                    }
                )
                terms.splice(0, titleCoordinate)
                terms.forEach(it => {
                    let gxp = {}
                    for(let i in GXP){ gxp[i] = false }
                    if (it.gxps) {
                        it.selectAllGxp = true
                        let gxps = genMapByVs(...it.gxps.split(/，|、| |,/).map(it => it.toLowerCase()))
                        for (let i in gxp) {
                            if(gxps[i.toLowerCase()]) {
                                gxp[i] = true
                            } else if (it.selectAllGxp) {
                                it.selectAllGxp = false
                            }
                        }
                    }
                    it.gxps = gxp
                    let authority = {}
                    for(let i in HA){ authority[i] = false }
                    if (it.authorities) {
                        it.selectAllAuthority = true
                        let authorities = genMapByVs(...it.authorities.split(/，|、| |,/).map(it => it.toLowerCase()))
                        for (let i in authority) {
                            if(authorities[i.toLowerCase()]) {
                                authority[i] = true
                            } else if (it.selectAllAuthority) {
                                it.selectAllAuthority = false
                            }
                        }
                    }
                    it.authorities = authority
                })
                this.termsToAdd = this.termsToAdd.concat(terms)
                this.$message({
                    message: '读取成功，请确认有无遗漏后进行提交',
                    type: 'success'
                });
            };
            reader.readAsBinaryString(file);
            return false;
        },

        termsExport() {
            let {displayTerms} = this
            const {export_json_to_excel} = require('@/vendor/Export2Excel');
            let tHeader = ['中文名称', '英文名称', '来源', '来源网址', '中文描述', '英文描述', 'gxp分类', '药政来源'];
            let filterVal = ['name', 'name_E', 'source','sourceUrl', 'des', 'des_E', 'gxp', 'authority',];
            export_json_to_excel(tHeader, displayTerms.map(v => filterVal.map(j => v[j])), `术语表` + new Date().SFmt());
        },
    },

}
