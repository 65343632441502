
import {
	DOC_TYPE,
} from '../consts.js'

import {genMap, genSMap, foldToObjs, addOrderedId} from '../utils/xyhUtils'

function genOpts(...data) { return foldToObjs(data, 'id', 'name') }
function genOptsAutoId(...data) { return addOrderedId(foldToObjs(data, 'name')) }

const DocPaths = genOpts(
	DOC_TYPE.sop, 'editSop',
	DOC_TYPE.policy, 'editPolicy',
	DOC_TYPE.manual, 'editManual',
)

let rawExport = {
	DocPaths,
}

let {...finalExport} = rawExport
for (let p in rawExport) {
	finalExport[p+'Map'] = genMap(rawExport[p])
	finalExport[p+'SMap'] = genSMap(rawExport[p])
}

// module.exports = finalExport

export default finalExport
