<template>
  <div style="width: 85%;float: right">
    <el-input v-model="inputC"></el-input>
  </div>
</template>

<script>


export default {
  name: "inputStr",
  props:['inputContent','fd'],

  data(){
    return{
      inputC: '',
    }
  },

  watch:{
    inputC(){
      this.$emit('func', {fd:this.fd, value:this.inputC})
    },
    inputContent(){
      this.inputC = this.inputContent
    }
  },

  created(){
      this.inputC = this.inputContent
  },

}
</script>

<style scoped>

</style>