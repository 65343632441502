import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "rmDocDlg",
    mixins: [formDlgMix],
    props: ['doc'],

    data() {
        return {
            num:'',
        }
    },

    watch: {
        working(){
            Object.assign(this,{num:''})
        }
    },

    methods: {

        ok() {
            let {num , doc} = this
            if(this.chk2msg(num == doc.num, '请确认正确的编号',)) return
            this.reqThenBk(REQ.rmDoc, {id:doc.id})
        }
    }
};