
import Home from './Home.vue'
import Index from './Index.vue'
export default {
	path: '/home',
	component: Home,
	children: [
		{
			path: '',
			redirect: 'index',
		}, {
			path: 'index',
			name: 'index',
			component: Index
		},
		{
			path: 'about',
			name: 'about',
			component: () => import('./About.vue')
		},
		{
			path: 'background',
			name: 'background',
			component: () => import('./Background.vue')
		},
		{
			path: 'contact',
			name: 'contact',
			component: () => import('./Contact.vue')
		},
		{
			path: 'teachers',
			name: 'teachers',
			component: () => import('./Teachers.vue')
		},
		{
			path: 'service',
			name: 'service',
			component: () => import('./Service.vue'),
			children: [
				{
					path: '1',
					name: 'server1',
					component: () => import('./Server1.vue'),
				}, {
					path: '2',
					name: 'server2',
					component: () => import('./Server2.vue'),
				}, {
					path: '3',
					name: 'server3',
					component: () => import('./Server3.vue'),
				},
			]
		}
	]
}