import REQ from '@/app/REQ'
import editWktsDlg from "@/dialg/editWktsDlg.vue";
import {StoneRoles,} from "@/consts.js"

export default {
    props:['pid'],
    components:{editWktsDlg,},

    data() {
        return {
            suit: {},
            stones: [],
            // checks:[],
            // stoneIds:[],
            prjtStones:[],
            stoneId:'',
            wkts:{},
            prjtStoneWkts:{},
            prjtStoneWktNote:'',
        }
    },

    created() {
        this.getStones()
    },

    methods: {
        getStones() {
            this.req2Data(REQ.stoneSuitOfPrjt, {prjtId:this.pid}, null,()=>this.screenStoneWkts())
        },

        screenStoneWkts(){
            this.stones.forEach(it=> {
                let prjtStone = this.prjtStones.find(item => item.stoneId == it.id);
                let {...a} = it.wkts
                it.oldWkts = {...a}
                it.prjtStoneWkts = prjtStone ? prjtStone.wkts:{}
                it.prjtStoneWktNote = prjtStone ? prjtStone.note:''
                StoneRoles.forEach(item => {
                    if (it.prjtStoneWkts[item] && (it.wkts[item] != it.prjtStoneWkts[item])) {
                        it.wkts[item] = it.prjtStoneWkts[item]
                    }
                    if (!it.wkts[item]) delete it.wkts[item]
                })
            })
        },

        editWkts(wkts,prjtStoneWkts,stoneId,prjtStoneWktNote){
            this.stoneId = stoneId
            this.wkts = wkts
            this.prjtStoneWkts = prjtStoneWkts
            this.prjtStoneWktNote = prjtStoneWktNote
            this.showDlg('editWktsDlg')
        },

        unsetStoneWkts(idx,stoneId){
            this.req2Data(REQ.unsetPrjtStoneWkts,{prjtId:this.pid, stoneId},{},()=>this.getStones())
        },

        clickTab(){
            this.checks.forEach((it,idx)=>{if(it)this.stoneIds.push(this.stones[idx].id)})
        },
    },

}
