
import taskCon from '@/views/task.vue'
import deving from "@/coms/deving";

export default {
    path: 'tasks/',
    component: taskCon,
    props: true,
    children: [
        { path: 'today', component: deving, },
        { path: 'week', component: deving, },
        { path: 'month', component: deving, },
        { path: 'more', component: deving, },
        { path: 'screen', component: deving, },
        { path: '', component: deving, },
    ],
}