import { REQP } from '../services/config'
import PubSub from "@/lib/Pub";

export default {
	data() {
		return {
			formData: {
				user: "",
				password: ""
			},
			rules: {
				user: [
					{ required: true, message: "必填", trigger: "blur" },
//					{
//						type: "integer",
//						message: "请输入手机号！",
//						trigger: "blur"
//					}
				],
				password: [{ required: true, message: "请输入密码", trigger: "blur" }]
			}
		};
	},
	methods: {
		sign: function () {
			PubSub.publish("LoginSuccessEvent");
			this.$router.push("/reg/user");
		},
		close() {
			PubSub.publish("LoginSuccessEvent");
		},

		submitForm(formData) {
			this.$refs[formData].validate(async valid => {
				if (!valid) return
				this.postReq(REQP.login, this.formData, ({token, myself, orgs, staffs}) => {
				    this.setCurUser(myself, token)
					this.setCurOrg(orgs[0], staff[0])
				})
			});
		},

		findPwd() {
			PubSub.publish("LoginSuccessEvent");
			this.$router.push("/findPwd/1");
		}
	}
};
