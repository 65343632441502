import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import {DOC_TYPE} from "@/consts"

export default {
	name: "editDocDlg",
	mixins: [formDlgMix],
	props: ['oldDoc','typeN','type','dId', 'pId', 'depts'],

	data() {
		return {
			name:'',
			name_E:'',
			num:'',
			ver:'',
			planFixDate: null,
			planIssueDate: null,
			planDate: null,
			tpltId:'',
			deptId:'',
			docTplts:[],
			uploadUrl:XyhConf.HglSHost +'/' + REQ.putFile,
			file:'',
			files:[],
		}
	},

	watch: {
		working() {
			let {oldDoc} = this
			this.req2Data(REQ.docTplts, {}, 'docTplts',)
			if(oldDoc){
				Object.assign(this, {
					name: oldDoc.name,
					name_E: oldDoc.name_E,
					num: oldDoc.num,
					ver: oldDoc.ver,
					planFixDate: oldDoc.planFixDate,
					planIssueDate: oldDoc.planIssueDate,
					planDate: oldDoc.planDate,
					tpltId: oldDoc.tpltId,
					file: oldDoc.file
				})
			}else {
				Object.assign(this, {name:'',name_E:'', num:'', ver:'', tpltId:'', file:'', files:[], deptId:'',})
			}
		}
	},

	methods: {
		successUpload(res){
			this.file = res.data.url
		},

		beforeUpload(file) {
			const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
			const whiteList = ["doc", "docx",];
			if (whiteList.indexOf(fileSuffix) === -1) {
				this.$message("上传文件只能是 doc、docx格式", "error");
				return false;
			}
		},

		handleChange(file,fileList){
			if (fileList.length > 1) {
				fileList.splice(0, 1);
			}
		},

		ok() {
			let {name,name_E, num , ver, file, tpltId, oldDoc, type, dId, pId, deptId, planFixDate, planIssueDate, planDate} = this
			if(this.chk2msg(name, '请填写名称', num, '请填写编号',)) return
			if(oldDoc){
				this.reqThenBk(REQ.upDoc,Object.assign({id:oldDoc.id, name,name_E, num, ver, planFixDate, planIssueDate, planDate},(type == DOC_TYPE.annex || type == DOC_TYPE.file) ? {file}:{tpltId}))
			}else {
				this.reqThenBk(REQ.addDoc, Object.assign({deptId:dId || deptId, name,name_E, num, ver, type, planFixDate, planIssueDate, planDate},
					(type == DOC_TYPE.annex || type == DOC_TYPE.file) ? {file}:{tpltId}, pId ? {prjtId:pId}:{}))
			}
		}
	}
};
