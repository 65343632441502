
// import 'swiper/dist/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
	name: 'Banner',
	components: {
		Swiper,
		SwiperSlide
	},
	data: () => {
		return {
			swiperOption: {
				spaceBetween: 30,
				loop: true,
				centeredSlides: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: false
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			}
		}
	},
	methods: {
		join() {
			this.$emit('joinClick')
		}
	}
}
