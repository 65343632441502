export default {
	path: '/findPwd',
	component: () => import('./index.vue'),
	children: [
		{
			path: '',
			redirect: '/findPwd/1'
		},
		{
			path: '1',
			name: 'findPwdMail',
			component: () => import('./FindPwdMail.vue')
		}
	],
}