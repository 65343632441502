import REQ from "../app/REQ";
import {upWFoundById} from "../utils/xyhUtils"
import monCard from '../cards/monitor.vue'
import issueCard from '../cards/issue.vue'
import followCard from '../cards/follow.vue'
import addMonDlg from "@/dialg/addMonDlg.vue"
import addIssueDlg from "@/dialg/addIssueDlg.vue"
import addFollowDlg from "@/dialg/addFollowDlg.vue";
import addPrjtStaffDlg from "@/dialg/addPrjtStaffDlg.vue";

export default {
	name: "site",
	components:{ monCard, issueCard, followCard, addFollowDlg, addMonDlg, addIssueDlg, addPrjtStaffDlg,},
	props: ['id'],

	data() {
		return {
			prjt: {},
			site:{},
			hospital:{},
			dept:{},
			mons:[],
			issues:[],
			follows:[],
			siteStaffs:[],
		};
	},

	created() {
		this.getSiteInfo()
	},

	methods: {
		getSiteInfo(){
			let {id} = this
			this.req2Data(REQ.siteInfo, id, null,
				({siteStaffs,staffs}) => {
					upWFoundById(siteStaffs,staffs,'staffId',(siteStaff,staff)=> {
						siteStaff.siteStaffN = staff.name
					})
				})
		},

		removeSiteStaff(siteStaffId){
			this.req2Data(REQ.rmSiteStaff, {siteStaffId},{},()=>this.getSiteInfo())
		},
	}
};
