import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import { DEPT_TYPE, } from '@/consts.js'

export default {
	name: "addDeptDlg",
	mixins:[ formDlgMix ],
	data() {
		return {
			dept: {
				name: '',
				sDate: null,
				role:DEPT_TYPE.other,
			}
		}
	},

	methods: {
		ok() { this.reqThenBk(REQ.addDept, this.dept) }
	}
};
