import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "editAbbrDlg",
    mixins: [formDlgMix],
    props:['beAbbr'],

    data() {
        return {
           abbr:{
               name: '',
               fullEn: '',
               fullCn: '',
               source: '',
               sourceUrl: '',
               gxps: [],
               authorities: [],
           } ,
        }
    },

    watch: {
        working(){
            let {beAbbr} = this
            if (beAbbr) {
                this.abbr = {
                    name: beAbbr.name,
                    fullEn: beAbbr.fullEn,
                    fullCn: beAbbr.fullCn,
                    source: beAbbr.source,
                    sourceUrl: beAbbr.sourceUrl,
                    gxps: beAbbr.gxps,
                    authorities: beAbbr.authorities,
                }
            } else {
                this.abbr = {name: '', fullEn: '', fullCn: '', source: '', sourceUrl: '', gxps: [], authorities: []}
            }
        }
    },

    methods: {
        ok() {
            let {abbr, beAbbr} = this
            let {name, fullEn, fullCn} = abbr
            if(this.chk2msg(name, '请输入简写', fullCn, '请输入中文名称', fullEn, '请输入英文名称')) return
            if (beAbbr) {
                let newAbbr = {id:beAbbr.id}
                for (let it in abbr){
                    if(abbr[it] != beAbbr[it]) newAbbr[it] = abbr[it]
                }
                if (newAbbr.name || newAbbr.fullEn || newAbbr.fullCn || newAbbr.gxps || newAbbr.source || newAbbr.sourceUrl || newAbbr.authorities) {
                    this.reqThenBk(REQ.upAbbr, newAbbr)
                } else {
                    this.$message('请至少修改一项内容')
                }
            } else {
                this.reqThenBk(REQ.addAbbr, abbr)
            }
        }
    }
};
