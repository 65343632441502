

export default {
	name: "monCard",
	props:['monitor', 'site'],

	data() {
		return {
		}
	},

	methods: {
	}
};
