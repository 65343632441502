import { render, staticRenderFns } from "./selRefDlg.vue?vue&type=template&id=f2ba5254&"
import script from "./selRefDlg.js?vue&type=script&lang=js&"
export * from "./selRefDlg.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports