import REQ from '@/app/REQ'
import {upWFoundById} from '@/utils/xyhUtils'
import { mapGetters } from 'vuex'

export default {

    data() {
        return {
            issuers:[],
            suits: [],
        }
    },
    created() {
        this.getStoneSuits()
    },

    computed: {
        ...mapGetters(['amIAdmin']),
    },

    methods: {
        getStoneSuits() {
            this.req2Data(REQ.stoneSuits, {}, {issuers: 'issuers', suits: 'suits'}, () => {
                // upWFoundById(this.suits, this.issuers, 'issuerId', (suit, issuer) => suit.issuer = issuer.name)
                this.interceptDate()
            })
        },

        addSuit(){
            this.editStr('','请填写模板名称',name=> {
                this.req2Data(REQ.addStoneSuit, {name},'suit',()=>{this.goto('stoneSuit/' + this.suit.id)})
            })
        },

        interceptDate(){
            this.suits.forEach(it=> {if (it.issueT) it.issueT = it.issueT.slice(0, 10)})
        },

        disableSuit(id){
            this.req2Data(REQ.disableStoneSuit, {id},{},()=>this.getStoneSuits())
        },

        release(id) {
            this.req2Data(REQ.issueStoneSuit, {id},)
            this.getStoneSuits()
        },
    },

}
