import REQ from "@/app/REQ";
import {mapState} from "vuex";
import {assignBypath, classifyByProp, genMap, upWFoundById} from '@/utils/xyhUtils'
import {DOC_ROLE, DOC_TYPE, LANGUAGE_MODEL, REMARK_STATE, DOC_STATE, STAFF_STATE} from "@/consts";
import selRefDocDlg from '@/dialg/selRefDocDlg.vue';
import selRefDlg from '@/doc/selRefDlg.vue'
import smsNotiDlg from "@/doc/smsNotiDlg.vue"
import docEditState from "@/doc/docEditState";
import {wordViewUrl} from "@/doc/consts"
import selDeptDlg from "@/doc/selDeptDlg.vue";

export default {
    props: ['id'],
    components:{selRefDocDlg,smsNotiDlg,selRefDlg, selDeptDlg},

    data() {
        return {
            annexes: [],
            docs:[],
            lang: LANGUAGE_MODEL.Cn,
            doc: {},
            content2: null,
            remarks: [],
            kMax: 200,
            commenting:false,
            fdPath:'',
            discuss:'',
            amIAuthor:false,
            amIPM:false,
            repliedId:null,
            translating:false,
            contentTranslate:[],
            addDoc:false,
            refIdxToSet:null,
            refPropN:'',
            terms:[],
            abbrs:[],
            laws:[],
            pmStaffs:[],
            authorStaffs:[],
            auditorStaffs:[],
            amIAuditor:false,
            Strs:[],
            recommendDocs:[],
            recommendTerms:[],
            recommendAbbrs:[],
            recommendAnnexes:[],
            relation:false,
            refNamePath:'',
            depts:[],
            roles:[],
        }
    },

    provide:function () {
        return {
            getRemarksMap:()=>{return this.remarksMap},
            comment:this.comment,
            cancelRemark:this.deleteRemark,
            closeRemark:this.closeRemark,
            selRelationDoc:this.selRelationDoc,
            updateDisplayContent:this.updateDisplayContent,
        }
    },

    created() {
        docEditState.clean()
        this.getDoc()
        this.getTerms()
        this.getAbbrs()
        this.getLaws()
    },

    beforeRouteLeave(to, from, next) {
        if(this.doc.state != DOC_STATE.fixed && (this.amIPM || this.amIAuthor)){
            setTimeout(()=> this.confirm('请确认所有内容是否已保存?', ()=>next(), '现在离开', '我再看看'),200)
        }else {
            next()
        }
    },

    computed: {
        editing: _ => docEditState.editing,   //编辑状态
        showComment: _ => docEditState.showComment,  //评论显示状态
        remarksMap: _ => docEditState.remarksMap,   //评论内容
        lanMode: _ => docEditState.lanMode,   //语言

        ...mapState({
            staffId: ({curStaff}) => curStaff?.id,   //我在公司内部的id
        }),
        CnEditing() {
            return  docEditState.editing && docEditState.lanMode !== LANGUAGE_MODEL.Cn2En   //中文编辑状态
        },
        EnEditing(){
            return docEditState.lanMode === LANGUAGE_MODEL.Cn2En ||  docEditState.editing   //英文编辑状态
        },
        CnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.En   //中文相关模式
        },
        EnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.Cn   //英文相关模式
        },
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,   //公司id
        }),

        optionalVals: _ => docEditState.optionalVals,   //推荐值列表
        displayContent: _ => docEditState.displayContent,
    },

    methods:{
        getDocDeptsOfPrjt(){
            let {prjtId} = this.doc
            this.req2Data(REQ.docDeptsOfPrjt, {prjtId}, null)
        },

        getDocCrRolesOfPrjt(){
            let {prjtId} = this.doc
            this.req2Data(REQ.docCrRolesOfPrjt, {prjtId}, {roles:'roles'}, ()=>this.getRefRoles())
        },

        //排序
        sorted(arrN, propN){
            this.content[arrN].sort((parm1, parm2) => parm1[propN].toLowerCase().localeCompare(parm2[propN].toLowerCase()))
        },

        //获取文档列表
        getDocPrjt() {
            this.req2Data(REQ.docPrjt, this.doc.prjtId, {docs:'docs'},({docs})=> {
                this.docId = ''
                docs.sort((doc1, doc2) => doc1.num.toLowerCase().localeCompare(doc2.num.toLowerCase()))
                for(let i = 0; i < docs.length; i++){
                    if(docs[i].id == this.myId){
                        this.docs.splice(i,1)
                        break
                    }
                }
                this.annexes = docs.filter(it=>it.type == DOC_TYPE.annex)
                this.getAnnexes()
            })
        },

        //根据正文内容获取推荐文件列表
        autoLinkRefDocs(typeN){
            this.extractStrOfObj(this.content)
            let {docs} = this
            let {refFlows, annexes} =this.content
            let docList = []
            if(typeN == 'doc') {
                this.recommendDocs = []
                docList = refFlows
            }else if(typeN == 'annex'){
                this.recommendAnnexes = []
                docList = annexes
            }
            let text = this.Strs.join('')
            let filterResult = [...text.matchAll(/《(.(?!\n)(?!《)(?!》))*[(（]((.(?!\n)(?!《)(?!》))*)[)）][\s]{0,5}》/g)]
            if(filterResult){
                let res = Object.values(genMap(filterResult, 0));
                for (let val of res) {
                    let doc = docs.find(item => item.num.toLowerCase() == val[2].toLowerCase())
                    if (doc && !docList.find(item=> item.refId == doc.id)){
                        if(doc.type != DOC_TYPE.annex && typeN == 'doc') {
                            this.recommendDocs.push(doc)
                        }else if(doc.type == DOC_TYPE.annex && typeN == 'annex'){
                            this.recommendAnnexes.push(doc)
                        }
                    }
                }
            }
        },

        //获取术语推荐列表（同上）
        autoLinkTerms(){
            this.extractStrOfObj(this.content)
            this.req2Data(REQ.terms,{}, 'terms', terms=>{
                this.recommendTerms = []
                let regCn = new RegExp("^[\u4e00-\u9fa5]")
                terms.forEach(it => {
                    let name = regCn.test(it.name.substring(0,1)) ? it.name:('\\b' + it.name)
                    if(!regCn.test(it.name.substring(it.name.length-1,it.name.length))){
                        name = name + '\\b'
                    }
                    let reg = new RegExp(name)
                    if (!this.content.terms.find(term=>it.name == term.name) && this.Strs.find(item =>  item.search(reg) >= 0)) this.recommendTerms.push(it)
                })
            })
        },

        //获取缩略语术语推荐列表（同上）
        autoLinkAbbrs(){
            this.extractStrOfObj(this.content)
            this.req2Data(REQ.abbrs,{}, 'abbrs', abbrs=>{
                this.recommendAbbrs = []
                let regCn = new RegExp("^[\u4e00-\u9fa5]")
                abbrs.forEach(it => {
                    let name = regCn.test(it.name.substring(0,1)) ? it.name:('\\b' + it.name)
                    if(!regCn.test(it.name.substring(it.name.length-1,it.name.length))){
                        name = name + '\\b'
                    }
                    let reg = new RegExp(name)
                    if (!this.content.abbrs.find(abbr=>it.name == abbr.name) && this.Strs.find(item => item.search(reg) >= 0)) this.recommendAbbrs.push(it)
                })
            })
        },

        //打开选择文件弹框
        selRelationDoc(path, relation){
            this.relation=true;
            this.refNamePath = path;
            if(relation == 'doc'){
                this.showDlg('selRefDocDlg')
            }else if(relation == 'law') {
                this.onSetRefLaw()
            }else {
                this.showDlg('selDeptDlg')
            }
        },

        //引用法规
        onSetRefLaw() {
            let {laws} = this
            let lawsMap = genMap(laws)
            this.selSgl(laws, '选择法规', id => {
                if (!this.content.refLaws.find(it => it.refId == id)) {
                    let nameOfLaws = this.content.refLaws.find(it => it.name == lawsMap[id].name)
                    if (nameOfLaws) {
                        nameOfLaws.refId = id
                        nameOfLaws.linkUrl = lawsMap[id].linkUrl
                    } else {
                        this.refIdxToSet = this.content.refLaws.length
                        this.refPropN = 'law'
                        this.onSetRef(lawsMap[id])
                    }
                }
                this.getTxt1CursorPosition('〈' + lawsMap[id].name + '_' + lawsMap[id].org + '(' + lawsMap[id].releaseNum + ')〉')
            })
        },

        //正文填充文件名
        getTxt1CursorPosition(addStr) {
            let oTxt1 = document.getElementById(this.refNamePath);
            let cursurPosition = -1;
            if (oTxt1.selectionStart) {//非IE浏览器
                cursurPosition = oTxt1.selectionStart;
            } else if(oTxt1.length>0) {//IE
                let range = document.selection.createRange();
                range.moveStart("character", -oTxt1.value.length);
                cursurPosition = range.text.length;
            }
            let strN = oTxt1.value
            let list_i = strN.split("") //str转成list
            list_i.splice(cursurPosition, 0, addStr)
            oTxt1.value = list_i.join("") // list转成str
            oTxt1.dispatchEvent(new Event('input'));
        },

        getDocs(){
            this.req2Data(REQ.docPrjt, this.doc.prjtId, {docs: 'docs'},()=> {
                this.extractStrOfObj(this.content)
                this.getRefFlows()
            })
        },

        //更改对应引用格式
        updateDisplayContent(path, str){
            let filterLawResult = [...str.matchAll(/(〈)((.(?!\n)(?!《)(?!》)(?!〈)(?!〉))*)[-_]((.(?!\n)(?!《)(?!》)(?!〈)(?!〉))*)([(（])((.(?!\n)(?!《)(?!》)(?!〈)(?!〉))*)([)）][\s]{0,5})(〉)/g)]
            let lawRes = Object.values(genMap(filterLawResult, 0))
            for (let val of lawRes) {
                let law = this.laws.find(item => item.name.toLowerCase() == val[2].toLowerCase())
                if (law && law.linkUrl) {
                    str = str.replaceAll(val[0], '<a style="color: #0D8FBF" href="' + law.linkUrl + '" target="_blank">' + val[0] + '</a>')
                } else {
                    str = str.replaceAll(val[0], '<span style="color:red">' + val[0] + '</span>')
                }
            }
            let filterResult = [...str.matchAll(/(《)((.(?!\n)(?!《)(?!》))*)([(（])((.(?!\n)(?!《)(?!》))*)([)）][\s]{0,5})(》)/g)]
            let res = Object.values(genMap(filterResult, 0));
            for (let val of res) {
                let doc = this.docs.find(item => item.num.toLowerCase() == val[5].toLowerCase())
                if (doc) {
                    let docN = val[2].trim() != doc.name.trim() ? (val[1] + '<span style="color:red">' + val[2] + '</span>' + val[4] + val[5] + val[7] + val[8]) : val[0]
                    // let url = wordViewUrl + encodeURIComponent((doc.type == DOC_TYPE.annex || doc.type == DOC_TYPE.file) ?
                    //     doc.file : `${XyhConf.HglSHost}/${REQ.previewDoc}?id=${doc.id}&orgId=${this.orgId}&t=${new Date().getTime()}`)
                    let url = window.location.origin + '/#/main/doc/' + doc.id
                    str = str.replaceAll(val[0], '<a style="color: #0D8FBF" href="' + url + '" target="_blank">' + docN + '</a>')
                } else {
                    str = str.replaceAll(val[0], '<span style="color:red">' + val[0] + '</span>')
                }
            }
            docEditState.displayContent[path] = str
            if(!docEditState.editing){
                docEditState.editing = !docEditState.editing
                this.$nextTick(function () {
                    docEditState.editing = !docEditState.editing
                })
            }
        },

        //提取正文内容（对象）
        extractStrOfObj(content, path){
            for(let i in content){
                if(i != 'refFlows' && i != 'terms' && i != 'abbrs' && i != 'annexes'){
                    let fdpath = (path || 'content') + '.' + i
                    if(Array.isArray(content[i])){
                        this.extractStrOfArr(content[i],fdpath)
                    }else {
                        if (content[i] && typeof content[i] === 'string') {
                            this.updateDisplayContent(fdpath, content[i])
                            this.Strs.push(content[i])
                        } else if (content[i]) {
                            this.extractStrOfObj(content[i],fdpath)
                        }
                    }
                }
            }
        },

        //数组
        extractStrOfArr(arr, fdpath){
            arr.forEach(it=>{
                let itPath = fdpath + '.' + it.k
                if(Array.isArray(it)){
                    this.extractStrOfArr(it)
                }else if(it && typeof it === 'string'){
                    this.Strs.push(it)
                }else if(it){
                    this.extractStrOfObj(it, itPath)
                }
            })
        },

        //获取三种权限者名单
        screenStaffs(docStaffs,prjtStaffs,staffs){
            let pmStaffs = []
            let authorStaffs = []
            let auditorStaffs = []
            upWFoundById(docStaffs,staffs,'staffId',(docStaff,staff)=>{
                docStaff.name = staff.name
                if(docStaff.role == DOC_ROLE.PM){
                    pmStaffs.push(docStaff)
                }else if (docStaff.role == DOC_ROLE.author){
                    authorStaffs.push(docStaff)
                }else if (docStaff.role == DOC_ROLE.auditor){
                    auditorStaffs.push(docStaff)
                }
            })
            upWFoundById(prjtStaffs,staffs,'staffId',(prjtStaff,staff)=>{
                prjtStaff.name = staff.name
                if(prjtStaff.role == DOC_ROLE.PM){
                    pmStaffs.push(prjtStaff)
                }else if (prjtStaff.role == DOC_ROLE.author){
                    authorStaffs.push(prjtStaff)
                }else if (prjtStaff.role == DOC_ROLE.auditor){
                    auditorStaffs.push(prjtStaff)
                }
            })
            Object.assign(this,{pmStaffs, authorStaffs, auditorStaffs})
        },

        //自动关联未关联角色
        autoRefRole(){
            let {roles} = this
            let {duties} = this.content
            duties.forEach(it => {
                let role = roles.find(role => it.role == role.name)
                if (!it.refId && role){
                    it.refId = role.id
                }
            })
        },

        //获取角色列表内容改变的推荐值（已绑定的角色）
        getRefRoles() {
            let {optionalVals, roles} = this
            if(this.content.duties){
                this.content.duties.forEach(it=>{
                    if(it.refId && !roles.find(item=>it.refId == item.id)){
                        it.nothingness = true
                    }
                })
                upWFoundById(this.content.duties,roles,'refId',(duty,role)=>{
                    if(duty.role != role.name)this.$set(optionalVals,'content.duties.' + duty.k + '.role', {v:role.name})
                })
            }
        },

        //获取文档列表内容改变的推荐值（已绑定的文档）
        getRefFlows() {
            let {optionalVals, docs} = this
            this.content.refFlows.forEach(it=>{
                if(it.refId && !docs.find(item=>it.refId == item.id)){
                    it.nothingness = true
                }
            })
            upWFoundById(this.content.refFlows,docs,'refId',(refFlow,doc)=>{
                if(refFlow.num != doc.num)this.$set(optionalVals,'content.refFlows.'+refFlow.k + '.num', {v:doc.num})
                if(refFlow.name != doc.name)this.$set(optionalVals,'content.refFlows.'+refFlow.k + '.name', {v:doc.name})
                if(refFlow.ver != doc.ver)this.$set(optionalVals,'content.refFlows.'+refFlow.k + '.ver', {v:doc.ver})
            })
        },

        //获取附件推荐值（同上）
        getAnnexes() {
            let {annexes,optionalVals} = this
            this.content.annexes.forEach(it=>{
                if(it.refId && !annexes.find(item=>it.refId == item.id)){
                    it.nothingness = true
                }
            })
            upWFoundById(this.content.annexes,annexes,'refId',(annex,docAnnex)=>{
                if(annex.num != docAnnex.num)this.$set(optionalVals,'content.annexes.'+annex.k + '.num', {v:docAnnex.num})
                if(annex.name != docAnnex.name)this.$set(optionalVals,'content.annexes.'+annex.k + '.name', {v:docAnnex.name})
            })
        },

        //预览参考文件
        previewRefFlow(id){
            let {docs} = this
            let doc = docs.find(doc=>doc.id == id)
            if(doc?.type == DOC_TYPE.file || doc?.type == DOC_TYPE.annex){
                this.previewAnnexe(id)
            }else if(doc){
                window.open(wordViewUrl +
                    encodeURIComponent(XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()))
            }
        },

        //自动关联附件
        autoLinkAnnexes(){
            let {annexes,content} = this
            let numToannexes = genMap(annexes,'num')
            let nameToannexes = genMap(annexes,'name')
            content.annexes.forEach(it=>{
                if(!it.refId && ((it.num && numToannexes[it.num]) || (!it.num && nameToannexes[it.name]))) this.$set(it,'refId',it.num ? numToannexes[it.num].id:nameToannexes[it.name].id)
            })
            let annexMap = genMap(content.annexes,'refId')
            annexes.forEach(it=> {
                if (!annexMap[it.id]) content.annexes.push({num:it.num,name:it.name,num_E:'',refId:it.id,k:this.genNewK()})
            })
        },

        //预览附件
        previewAnnexe(id){
            window.open(wordViewUrl + encodeURIComponent(this.docs.find(it=>it.id == id).file))
        },

        //设置编辑状态
        onSetEditing() { docEditState.editing = !docEditState.editing },

        //显示评论
        onSetShowComment() { docEditState.showComment = !docEditState.showComment },

        //设置语言
        onSetLanMode(mode) { docEditState.lanMode = mode },

        onSetRefDept(dept){
            this.getTxt1CursorPosition(dept.name)
        },

        //参考文件列表填充
        onSetRefDoc(doc){
            let {refIdxToSet, relation} = this
            if(doc.type == DOC_TYPE.annex){
                if(!this.content.annexes.find(it=>it.refId == doc.id)){
                    let docNumOfAnnexes = this.content.annexes.find(it => it.num == doc.num)
                    if(docNumOfAnnexes) {
                        docNumOfAnnexes.refId = doc.id
                        this.getAnnexes()
                    }else {
                        this.refIdxToSet = this.content.annexes.length
                        this.refPropN = 'annex'
                        this.onSetRef(doc)
                    }
                }
                this.getTxt1CursorPosition('《' + doc.name + '(' + doc.num + ')' + '》')
                return
            }
            if(relation) {
                if(!this.content.refFlows.find(it=>it.refId == doc.id)) {
                    let docNumOfRefFlows = this.content.refFlows.find(it => it.num == doc.num)
                    if(docNumOfRefFlows) {
                        docNumOfRefFlows.refId = doc.id
                        this.getRefFlows()
                    }else {
                        this.content.refFlows.push({num: doc.num, name:doc.name, name_E:doc.name_E, ver: doc.ver, refId:doc.id, k:this.genNewK()})
                    }
                }
                this.getTxt1CursorPosition('《' + doc.name + '(' + doc.num + ')' + '》')
            }else {
                this.content.refFlows.splice(refIdxToSet,0,{num:doc.num,name:doc.name,name_E:doc.name_E,ver:doc.ver,ver_E:doc.ver_E,refId:doc.id,k:this.genNewK()})
                docEditState.editing = !docEditState.editing
                this.$nextTick(function () {
                    docEditState.editing = !docEditState.editing
                })
                this.getRefFlows()
            }
        },

        //参考列表填充
        onSetRef(ref){
            let {refIdxToSet, refPropN } = this
            if(refPropN == 'annex'){
                this.content.annexes.splice(refIdxToSet, 0, {num: ref.num, name: ref.name, name_E: ref.name_E, refId: ref.id, k: this.genNewK()})
                this.getAnnexes()
            } else if(refPropN == 'term'){
                this.content.terms.splice(refIdxToSet, 0, {name: ref.name, des:ref.des, refId: ref.id, k: this.genNewK()})
            }else if(refPropN == 'abbr'){
                this.content.abbrs.splice(refIdxToSet, 0, {name: ref.name, fullEn:ref.fullEn, fullCn:ref.fullCn, refId: ref.id, k: this.genNewK()})
            }else if(refPropN == 'law'){
                this.content.refLaws.splice(refIdxToSet, 0, {name: ref.name, issuer:ref.issuer, num: ref.num, linkUrl:ref.linkUrl, date:ref.validDate, refId: ref.id, k: this.genNewK()})
            }
            docEditState.editing = !docEditState.editing
            this.$nextTick(function () {
                docEditState.editing = !docEditState.editing
            })
        },

        //获取术语
        getTerms(){
            this.req2Data(REQ.terms,{}, 'terms', )
        },

        //获取缩略语
        getAbbrs(){
            this.req2Data(REQ.abbrs,{}, 'abbrs', )
        },

        //获取法规
        getLaws(){
            this.req2Data(REQ.laws,{}, 'laws', )
        },

        //触发翻译
        onTranslate(){
            this.confirm('该操作将会自动翻译未有翻译的空白内容，确认吗？',()=>{
                this.contentTranslate = []
                this.translateCn(this.content,'content')
                this.translate()
            })
        },

        //翻译内容对应path生成
        translateCn(content,path){
            for(let i in content){
                if(!i.includes('_E')){
                    if(Array.isArray(content[i])){
                        content[i].forEach((it,idx)=>this.translateCn(content[i][idx],path + '.' + i + '.' + idx))
                    }else if(i != 'k' && content[i] && !content[i + '_E']){
                        this.contentTranslate.push({path:path + '.' + i + '_E',v:content[i]})
                    }
                }
            }
        },

        //提交需翻译内容
        translate(){
            if(this.contentTranslate.length>0)
                this.req2Data(REQ.translate, {content:this.contentTranslate.map(it=>it.v)},{},res=>{
                    this.contentTranslate.forEach((it,idx)=> {
                        assignBypath(this,it.path,res[idx])
                        this.$forceUpdate()
                    })
                    this.contentTranslate = []
                })
        },

        //检测k是否存在，同时为保持兼容性，此时k值由idx生成（文档代码初期k由idx生成）
        checkToInitK(obj) {
            for (let i in obj) {
                if (typeof obj[i] !== 'string') {
                    if (Array.isArray(obj[i])) {
                        obj[i].forEach((it, idx) => {
                            if (typeof it === 'string') {
                                obj[i][idx] = {k: idx, v: it}
                            } else if (it) {
                                if (it.k) return true
                                it.k = idx
                                if (this.checkToInitK(it)) return true
                            }
                        })
                    } else {
                        if (this.checkToInitK(obj[i])) return true
                    }
                }
            }
        },

        //打开评论框
        comment(fdPath,repliedId) {
            this.repliedId = repliedId || null
            this.discuss = ''
            this.commenting = true
            this.fdPath = fdPath
        },

        //添加评论
        onAddRemark(){
            let {id,fdPath,discuss,repliedId} = this
            let remark = {docId: id, fdPath:fdPath, content:discuss}
            if(repliedId) remark.repliedId = repliedId
            this.req2Data(REQ.addRemark, remark, {}, _ => this.getRemarks())
            this.commenting = false
        },

        //获取评论
        getRemarks() {
            this.getDocDeptsOfPrjt()
            this.getDocCrRolesOfPrjt()
            this.req2Data(REQ.remarksTo, {objId: this.id}, {}, ({remarkers, remarks}) => {
                upWFoundById(remarks, remarkers, "remarkerId", (remark, remarker) => {
                    remark.remarkerState = remarker.state
                    remark.remarker = remarker.name
                })
                remarks.forEach(remark=>remark.remarks = [])
                remarks.forEach(remark=> {
                    remark.createDate = new Date(parseInt(remark.id.substr(0, 8), 16) * 1000).MDHMFmt()
                    remark.textStyle = (remark.state == REMARK_STATE.closed) ? {'background':'beige','color':'#BBBBBB'}:{}
                    if(remark.repliedId) this.findRemarkSource(remarks,remark,remark)
                })
                remarks.forEach(remark=> {
                    if (!remark.repliedId) remark.remarks.sort((remark1, remark2) => remark1.createDate.localeCompare(remark2.createDate))
                })
                docEditState.remarksMap = classifyByProp(remarks, "fdPath")
            })
        },

        //保存
        onSave() {
            this.req2Data(REQ.upDoc, {id: this.id, content: this.content, kMax: this.kMax}, {},
                () => {
                    this.ok('修改成功')
                    this.getDoc()
                });
        },

        //表格内上移下移
        swap(arr, idx, arr1) {
            let t = arr[idx]
            arr.splice(idx + 2, 0, t)
            arr.splice(idx, 1)
            if(arr1){
                let t1 = arr1[idx]
                arr1.splice(idx + 2, 0, t1)
                arr1.splice(idx, 1)
            }
        },

        setValue(data) {
            this.content[data.fd] = data.value
        },

        //生成k
        genNewK() {
            return ++this.kMax
        },

        //表格添加内容
        add(arr, idx, obj, arr1) {
            arr.splice(idx + 1, 0, obj)
            if (arr1) arr1.splice(idx + 1, 0, null)
        },

        //删除
        strikeOut(arr, idx, arr1) {
            arr.splice(idx, 1)
            if (arr1) arr1.splice(idx, 1)
        },

        //查询评论的源头
        findRemarkSource(remarks,remark,lowestRemark){
            let superiorRemark = remarks.find(it=>it.id == remark.repliedId)
            if(superiorRemark && superiorRemark.repliedId) {
                this.findRemarkSource(remarks,superiorRemark,lowestRemark)
            }else if(superiorRemark){
                superiorRemark.remarks.push(lowestRemark)
            }
        },


        //取消评论
        deleteRemark(remark) {
            if (remark.state != REMARK_STATE.canceled &&
                (this.staffId == remark.uper || (this.amIPM && remark.remarkerState == STAFF_STATE.stopped)))
                this.confirm('此操作将取消该评论, 是否继续?', () => {
                    this.req2Data(REQ.cancelRemark, {id: remark.id}, {}, () => {
                        this.getRemarks()
                        this.ok('该评论已取消成功')
                    })
                })
        },

        //关闭评论
        closeRemark(remark) {
            if (remark.state != REMARK_STATE.closed &&
                (this.staffId == remark.uper || (this.amIPM && remark.remarkerState == STAFF_STATE.stopped)))
                this.confirm('此操作将永久关闭该评论列, 是否继续?', () => {
                    this.req2Data(REQ.closeRemark, {id: remark.id}, {}, () => {
                        this.getRemarks()
                        this.ok('该评论已关闭成功')
                    })
                })
        },
    }
}