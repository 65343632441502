import { render, staticRenderFns } from "./docItemRemarks.vue?vue&type=template&id=c71f242c&scoped=true&"
import script from "./docItemRemarks.vue?vue&type=script&lang=js&"
export * from "./docItemRemarks.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/doc.less?vue&type=style&index=0&id=c71f242c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c71f242c",
  null
  
)

export default component.exports