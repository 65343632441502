<template>
    <div class="dialog-footer">
      <el-button @click="$emit('cancel')">取 消</el-button>
      <el-button type="primary" @click="yes">确 定</el-button>
    </div>
</template>

<script>
export default {
  name: "dlgFoot",

  data() {
    return {
      processing:false,
    }
  },

  methods: {
    yes(){
      if(this.processing) return;
      this.processing=true
      this.$emit('ok')
      setTimeout(()=> {
        this.processing = false;
      },2000)
    }
  },
};
</script>

