import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import { CR_ROLE, SITE_STATE, } from '@/consts.js'


const openStates = new Set([SITE_STATE.working, SITE_STATE.closed])

export default {
	name: "addSiteDlg",
	props: [ "prjtId" ],
	mixins:[ formDlgMix ],

	watch: {
		prjtId(nv) {this.site.prjtId = nv},
		working() {
			this.getlinkOrgs()
			Object.assign(this, {site: {prjtId:this.prjtId,
					hospitalN: '',
					hospitalId:'',
					deptId:'',
					deptN:'',
					name: '',
					num:'',
					des: '',
					diseaseN: '',
					state: SITE_STATE.working,
					myRole: CR_ROLE.CRA,
					sDate:'',
					eDate:'',
					type:'',
				},})
		},
	},

	data() {
		return {

			hospitalsLikes:[],
			depts:[],
			hospitalSeled:null,

			site: {
				prjtId:this.prjtId,
				hospitalN: '',
				hospitalId:'',
				deptId:'',
				deptN:'',
				name: '',
				num:'',
				des: '',
				diseaseN: '',
				state: SITE_STATE.working,
				myRole: CR_ROLE.CRA,
				sDate:'',
				eDate:'',
				type:'',
			},
			siteType:false,
			linkOrgs: [],
			lOrg:{},
		}
	},

	computed:{
		opened(){return openStates.has(this.site.state)},
		closed(){return this.site.state == SITE_STATE.closed} ,
	},

	methods: {
		getlinkOrgs() {
			this.req2Data(REQ.linkOrgs, null, 'linkOrgs', () => {
				this.linkOrgs = this.linkOrgs.filter(it => it.type == this.ORG_TYPE.hospital)
			})
		},

		searchHospital() {

		},

		onQueryHospitals(qs, cb) {
			if (!qs) return cb([])
			this.linkOrgs.forEach(it => it.value = it.name)
			cb(this.linkOrgs.filter(it => it.name.includes(qs) || it.sname.includes(qs)))
		},

		onSelHospital(hospital) {
			this.site.name = hospital.sname
			this.req2Data(REQ.linkOrg, {id: hospital.id}, null, () => {
				this.depts = this.depts.filter(it => it.type == this.DEPT_TYPE.treat || it.type == this.DEPT_TYPE.HOO)
			})
		},

		onOk() {
			let {depts, site, siteType, lOrg, linkOrgs} = this
			site.type = siteType ? this.SITE_TYPE.leader : this.SITE_TYPE.common
			if(linkOrgs.find(it=>it.name == site.hospitalN || it.sname == site.hospitalN))site.hospitalId = lOrg.id
			if(depts.find(it=> it.id == site.deptId)) {
				site.deptN = depts.find(it => it.id == site.deptId).name
			}else {
				site.deptN = site.deptId
				site.deptId = ''
			}
			this.authedReqR(REQ.addSite, site, () => {
				this.working = false
				this.siteType = false
					this.$emit("siteAdded")
				})
		},


	}
};
