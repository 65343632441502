import docItemRemarks from './docItemRemarks.vue'
import docEditState from "@/doc/docEditState";
import {LANGUAGE_MODEL} from "@/consts";

export default {
    name: "currencyInput",
    props:['val','path','modified','type','w', 'relation', 'options', 'refObj', 'lang'],
    components:{docItemRemarks},
    inject: ['comment', 'selRelationDoc', 'updateDisplayContent'],

    data() {
        return{
            strN:'',
            border:{'width':this.w + 'px'},
            pre:{'width':this.w-10 + 'px'},
        }
    },

    computed: {
        showComment: _ => docEditState.showComment,

        editing() {
            switch (docEditState.lanMode) {
                case LANGUAGE_MODEL.Cn2En:
                    return this.lang === LANGUAGE_MODEL.En
                default:
                    return docEditState.editing
            }
        },

        optionalVals: _ => docEditState.optionalVals,
        displayContent: _ => docEditState.displayContent,
    },

    created() {
        this.strN = this.val
    },

    watch:{
        val(){
            this.strN = this.val
        },
    },

    methods:{
        transfer(v){
            this.updateDisplayContent(this.path, v)
            this.$emit('update:val',v)
        },

        getTxt1CursorPosition() {
            let oTxt1 = document.getElementById(this.path);
            let cursurPosition = -1;
            if (oTxt1.selectionStart) {//非IE浏览器
                cursurPosition = oTxt1.selectionStart;
            } else if(oTxt1.length>0) {//IE
                let range = document.selection.createRange();
                range.moveStart("character", -oTxt1.value.length);
                cursurPosition = range.text.length;
            }
            let list_i = this.strN.split("") //str转成list
            list_i.splice(cursurPosition, 0,'ceshi') //注意不用重新赋值
            let str_i = list_i.join("") // list转成str
            this.transfer(str_i)
        },

        deleteOptionalVal(path){
            delete this.optionalVals[path]
        },
    }
}