import formDlgMix from "@/gDlgs/formDlgMix";

export default {
    name: "selRefDlg",
    mixins: [formDlgMix],
    props: ['annexes','docAnnexes', 'terms', 'docTerms', 'abbrs','docAbbrs', 'laws', 'docLaws', 'refPropN'],

    data() {
        return {
            annexId:'',
            termId:'',
            abbrId:'',
            lawId:'',
        }
    },

    watch:{
        working(){
            Object.assign(this,{annexId:'', termId:'', abbrId:'', lawId:'',})
        }
    },

    methods: {

        onOk(){
            let {annexes, annexId, docAnnexes, terms, termId, docTerms, abbrs, abbrId, docAbbrs, laws, lawId, docLaws, refPropN} = this
            let ref = {}
            if (refPropN == 'annex') {
                if (this.chk2msg(annexId, '请选择附件', !annexes.find(it => it.refId == annexId), '不可重复添加')) return
                ref = docAnnexes.find(it => it.id == annexId)
            } else if (refPropN == 'term') {
                if (this.chk2msg(termId, '请选择术语', !terms.find(it => it.refId == termId), '不可重复添加')) return
                ref = docTerms.find(it => it.id == termId)
            } else if (refPropN == 'abbr') {
                if (this.chk2msg(abbrId, '请选择缩略语', !abbrs.find(it => it.refId == abbrId), '不可重复添加')) return
                ref = docAbbrs.find(it => it.id == abbrId)
            } else if (refPropN == 'law') {
                if (this.chk2msg(lawId, '请选择法规', !laws.find(it => it.refId == lawId), '不可重复添加')) return
                ref = docLaws.find(it => it.id == lawId)
            }
            this.$emit('oked', ref)
            this.working =false
        }
    }
};