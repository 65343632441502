
import {foldToObjs} from "../utils/xyhUtils"
const HOST = XyhConf.HglSHost
import REQ from "../app/REQ"

const MSG = {
    pwdChged: '修改成功请重新登录',
    regOk: '恭喜注册成功，现在可以登录了',
    veriSent: '验证码已经发送至您的手机',
    logined: '登录成功',
    deptAdded: '添加部门成功！',
}

function reqp(req, okmsg) { return {req, okmsg} }

let REQP = {}
for(let i in REQ) REQP[i] = {req:REQ[i]||i, okmsg:MSG[i] || "操作成功"}

const LoginUrl = `${HOST}/API/GLS/checkUser`
const LoginUrl2 = `${HOST}/API/ALS/checkUser`
const UserInfoUrl = `${HOST}/API/GLS/userinfo`
const UserInfoUrl2 = `${HOST}/API/ALS/userinfo`
const LoginOutUrl = `${HOST}/API/GLS/loginOut`
const UploadUrl = `${HOST}/API/GLS/uploadFile`
const UploadHeadUrl = `${HOST}/API/ALS/uploadFile` //上传头像
const ValidateEmailUrl = `${HOST}/API/GLS/validateEmail`
const ActiveEmailUrl = `${HOST}/API/SEND/activationEmail`
const SignCrcUrl = `${HOST}/API/GLS/regGUser`
const SignMechanismUrl = `${HOST}/API/GLS/regInstitution` //添加机构信息
const SignComponyUrl = `${HOST}/API/GLS/regEnterprise`
const SignTeachUrl = `${HOST}/API/GLS/regTeach`
const InstitutionListUrl = `${HOST}/API/GLS/institutionList`
const InstitutionListPageUrl = `${HOST}/API/ALS/institutionListPage` //机构分页列表
const EnterpriseListPageUrl = `${HOST}/API/ALS/enterpriseListPage`//  企业分页列表
const TeachListPageUrl = `${HOST}/API/ALS/teachListPage` //带教老师分页列表
const InstitutionInfoUrl = `${HOST}/API/ALS/institutionInfo` //机构详情
const EnterpriseInfoUrl = `${HOST}/API/ALS/enterpriseInfo` //  获取申请企业的信息
const TeachInfoUrl = `${HOST}/API/ALS/teachInfo` //带教老师的信息
const AduitInstitutionUrl = `${HOST}/API/ALS/aduitInstitution`  //审核机构
const AduitEnterpriseUrl = `${HOST}/API/ALS/aduitEnterprise` //审核企业
const AduitTeachUrl = `${HOST}/API/ALS/aduitTeach`  //审核带教老师 
const InstitutionSignInfoUrl = `${HOST}/API/GLS/institutionInfo `  //机构 注册信息
const EnterpriseSignInfoUrl = `${HOST}/API/GLS/enterpriseInfo  ` //企业 注册信息
const TeachSignInfoUrl = `${HOST}/API/GLS/teachInfo`  //带教老师 注册信息

const DownloadInsUrl = `${HOST}/API/GLS/downloadIns` //下载 机构表
const DownloadEnterUrl = `${HOST}/API/GLS/downloadEnter` //下载 机构表
const DownloadTeachUrl = `${HOST}/API/GLS/downloadTeach` //下载 机构表

const DownloadVideoUrl = `${HOST}/API/GLS/downloadTeach` //下载 在线课程列表

const ReviewHistoryList = `${HOST}/API/ALS/auditList` //下载 审核历史列表

const DownMessageList = `${HOST}/API/ALS/auditCountAndList` //下载 前三条消息列表
const DownAllMessageList = `${HOST}/API/ALS/auditListPage` //下载 全部消息列表
const SignAll = `${HOST}/API/ALS/signAll` //下载 全部消息列表
const SignById = `${HOST}/API/ALS/signById` //已读

const ValidateEmailAndCode = `${HOST}/API/GLS/validateEmailAndCode` //邮箱验证+发送验证码

const GetCategories = `${HOST}/API/VOD/GetCategories` // 
const GetVideoPlayInfo =`${HOST}/API/VOD/getVideoPlayInfo` //播放列表信息
const GetVideoList =`${HOST}/API/VOD/getVideoList` //播放列表信息

const UpdateAdminInfo =`${HOST}/API/ALS/updateAdminInfo` //审核模块 修改头像
const UpdateMmByEmail =`${HOST}/API/GLS/updateMmByEmail` //门户的找回密码


export {
    REQ, MSG, REQP,

    LoginUrl,
    LoginUrl2,
    UserInfoUrl,
    UserInfoUrl2,
    LoginOutUrl,
    UploadUrl,
    UploadHeadUrl,
    ActiveEmailUrl,
    ValidateEmailUrl,
    SignCrcUrl,
    SignMechanismUrl,
    SignComponyUrl,
    SignTeachUrl,
    InstitutionListUrl,
    InstitutionListPageUrl,
    EnterpriseListPageUrl,
    TeachListPageUrl,
    InstitutionInfoUrl,
    EnterpriseInfoUrl,
    TeachInfoUrl,
    AduitInstitutionUrl,
    AduitEnterpriseUrl,
    AduitTeachUrl,
    // InstitutionUrl,
    // EnterpriseUrl,
    // TeachUrl,
    InstitutionSignInfoUrl,
    EnterpriseSignInfoUrl,
    TeachSignInfoUrl,
    DownloadEnterUrl,
    DownloadInsUrl,
    DownloadTeachUrl,
    DownloadVideoUrl,
    ReviewHistoryList,
    DownMessageList,
    DownAllMessageList,
    ValidateEmailAndCode,
    GetCategories,
    GetVideoPlayInfo,
    GetVideoList,
    UpdateAdminInfo,
    UpdateMmByEmail,
    SignAll,
    SignById
}
