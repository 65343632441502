

function idxToId(list, idx, def = 0) {
	return idx < 0 || idx >= list.length ? def : list[idx].id
}

//生成属性名与值相同的对象,
// 1, 'a', 'b' => {1:1, a:'a', b:'b'}
function genMapByVs(...vals) {
	let r = {}
	vals.forEach(it=>r[it] = it)
	return r
}

//以对象的属性值为key，属性名为值，从给定的对象构建map
//{a:b,...} => {b:a,...}
function invMapOf(obj) {
	let r = {}
	for (let k in obj) {
		r[obj[k]] = k
	}
	return r
}

//以指定属性值为key, 从对象数组构建对象的map,
//[{id:1,...}, {id:2,...}] => {1：{id:1,...},:2:{id:2,...}
function genMap(objList, kProp = 'id') {
	let r = {}
	objList.forEach(i => {
		if (typeof (i[kProp]) != 'undefined') r[i[kProp]] = i
	})
	return r
}

//以对象中指定属性1的值为key, 指定的属性2的值为值，从给定的对象数组构建简单map
// [{id:1,name:"n1"}, {id:2,name:'n2'}] => {1:'n1', 2:'n2'}
function genSMap(objList, vProp= 'name', kProp = 'id') {
	let r = {}
	objList.forEach(i => r[i[kProp]] = i[vProp])
	return r
}

// 内嵌对象折叠
// {a1:{b：{c：'d1'}}, a1:{b：{c：'d2'}}}  => {a1:'d1", a2:'d2"}
function foldDeepMap(map, ...props) {
	let r = {}
	for(let k in lv1Map) {
		let v = map[k]
		for(let p in props) {
			if (!v) break
			v = v[p]
		}
		r[k] = v
	}
	return r
}

// 链式映射折叠
// {a:'b'}, {b:'c"}, {c:'d'}=> {a:'d"}
function foldLinkedMaps(map1, ...maps) {
	let r = {}
	for(let k in map1) {
		let v = map[k]
		for(let m in maps) {
			if (typeof v == 'undefined') break
			v = m[v]
		}
		r[k] = v
	}
	return r
}


//按照 array(list) 中property名字值交替出现的顺序构建 object
// 1, 2, 'a', 3, 'b', 4 => {1:2, a:3, b:4}
function objFrom(...list) {
	let o = {}
	let n = list.length / 2
	for(let i = 0; i < n; i += 1)  o[list[2*i]] = list[2*i+1]
	return o
}

//按照属性名列表（props)的顺序，把array（list）的数据折叠为object的array
//[1,2,3,4,5,6], 'a','b' => [{a:1,b:2},{a:3,b:4},{a:5,b:6}]
function foldToObjs(list, ...props) {
	let n = list.length / props.length
	let r = []
	for(let i = 0; i < n; i += 1) {
		let o = {}
		for(let k = 0; k< props.length; k+= 1) o[props[k]] = list[props.length*i +k]
		r.push(o)
	}
	return r
}

//根据每个元素计算出的类别值，给每个元素分类
function classify(list, classOf) {
	let r = {}
	list.forEach(it=>{
		let v = classOf(it)
		if(!r[v]) r[v] = []
		r[v].push(it)
	})
	return r
}

//根据对象中指定的属性值分组
//[{id:1,v:4},{id:2,v:5},{id:3,v:4}], 'v' => {4:[{id:1,v:4},{id:3,v:4}], 5:[{id:2,v:5}]}
function classifyByProp(list, propBy) {
	return classify(list, e=>e[propBy])
}

//根据子对象中指定的父对象的id，把子对象分配到父对象中, 父对象可以拥有多个子对象
// [{f:1,s:1},{f:2,s:2},{f:1,s:3}], 'f', [{id:1,v:1},{id:2,v:2}], 'sons'
// => [{id:1,v:1, sons:[{f:1,s:1},{f:1, s:3}]}, {id:2,v:2, sons:[{f:2,s:2}]}]
function assignMul(sons, propToFId, fathers, propToSons) {
	let r = classifyByProp(sons, propToFId)
	fathers.forEach( f => f[propToSons] = f.id? r[f.id] : [] )
}

//根据子对象中指定的父对象的id，把子对象分配到父对象中，父对象只保留一个子对象
// [{f:1,s:1},{f:2,s:2},{f:1,s:3}], 'f', [{id:1,v:1},{id:2,v:2}], 'son'
// => [{id:1,v:1, son:{f:1, s:3}}, {id:2,v:2, sons:[{f:2,s:2}]}]
function assignOne(sons, propBy, fathers, propToSon) {
	let r = genMap(sons, propBy)
	fathers.forEach( f => f[propToSon] = r[f.id] )
}

function hasVIn(obj, v) { return Object.values(obj).indexOf(v) >= 0 }

function upWFoundById(destObjs, srces, prop, upCall) {
	let srcMap = genMap(srces)
	destObjs.forEach(it=>{
		let s = srcMap[it[prop]]
		if(s) upCall(it, s)
	})
}

//
function linkObjByProp(destList, prop, srces, destProp) {
	let dProp = destProp || prop+'O'
	let srcMap = genMap(srces)
	destList.forEach(it=>{ it[dProp] = srcMap[it[prop]] })
	return destList
}

function cleanObj(o) {
	for (let i in o) if (o[i] == null || typeof o[i] =='undefined') delete o[i]
	return o
}

function cloneObj(o) { return JSON.parse(JSON.stringify(o)) }

function setSupProp(o, prop, subProp, v) {
	if(!o[prop]) o[prop] = {}
	o[prop][subProp] = v
}

function addOrderedId(objL, idFrom = 1) {
	let id = idFrom
	let inc = idFrom < 0 ? -1 : 1
	for (let o of objL) {o.id = id; id += inc }
	return objL
}

function genOptStr(optionsOrId){
	let opt = ''
	if (typeof (optionsOrId) == 'object') {
		for (let i in optionsOrId) {
			if (optionsOrId[i] != null)
				opt += (opt ? '&' : '') + i + '=' + optionsOrId[i]
		}
	} else if (typeof (optionsOrId) != 'undefined') {
		opt = 'id=' + optionsOrId
	}
	return (opt ? '?' : '') + opt
}

function joinPropByIds(map, keyL, prop = 'name', seprator=',') {
	let objL = keyL.map(k=>map[k])
	return objL.map(it=>map[prop]).join(seprator)
}

function joinPropByIdsStr(map, keysStr, prop = 'name', seprator=',') {
	let keyL = keysStr.split(seprator).map(it=>it.trim())
	return joinPropByIds(map, prop, seprator)
}

function joinValByKeysStr(map, keysStr, seprator=',') {
	let keyL = keysStr.split(seprator).map(it=>it.trim())
	return  keyL.map(k=>map[k]).join(seprator)
}

function parseUrlParams(paramsStr) {
	if(paramsStr.startsWith('?'))
		paramsStr = paramsStr.substr(1)
	let r = {}
	paramsStr.split("&").forEach(it=> {
		let p = it.split('=')
		r[p[0]] = p[1]
	})
	return r
}

function assignBypath(obj,path,v){
	let propNs = path.split('.')
	propNs.forEach((it,idx)=>{
		if(idx<propNs.length-1) {
			obj = obj[it]
		}else {
			obj[it] = v
		}
	})
}

//链表
function collectLinked(arr,refPropN,groupPropN){
	arr.forEach(it=>it[groupPropN] = [])
	let arrMap = genMap(arr)
	arr.filter(it=>it[refPropN]).forEach(item=> {
		let higherGroup = {}
		let higherObj = arrMap[item[refPropN]]
		while (higherObj && higherObj[refPropN] && !higherGroup[higherObj[refPropN]]) {
			higherObj[groupPropN].push(item)
			higherGroup[higherObj[refPropN]] = true
			higherObj = arrMap[higherObj[refPropN]]
		}
		if(higherObj && !higherObj[refPropN]) higherObj[groupPropN].push(item)
	})
	return arr
}

//生成树形结构数据
function buildTree(list, parentKN, childrenKN = 'children') {
	let listMap = genMap(list); // 用来储存{key: obj}格式的对象
	let trees = []; // 用来储存最终树形结构数据的数组

	for (let j = 0; j < list.length; j++) {
		// 判断父级是否存在
		let parent = listMap[list[j][parentKN]]
		if (parent) {
			// 如果有没有父级children字段，就创建一个children字段
			!parent[childrenKN] && (parent[childrenKN] = [])
			// 在父级里插入子项
			parent[childrenKN].push(list[j])
		} else {
			// 如果没有父级直接插入到最外层
			trees.push(list[j])
		}
	}
	return trees
}

//从1900年开始按数字转换日期 numb为数值，format为间隔符号
function formatDate(numb, format = '-') {
	const old = numb - 1;
	const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
	const time = new Date(1900, 0, old, 0, 0, t);
	const year = time.getFullYear() + ''
	const month = time.getMonth() + 1 + ''
	const date = time.getDate() + ''
	return year + format + (month < 10 ? '0' + month : month) + format + (date < 10 ? '0' + date : date);
}

//判断字符串是否适用于yyyy-mm-dd格式
function isDate(dateString) {
	if (dateString.trim() == "") return true
	const r = dateString.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
	if (r == null) return false
	const d = new Date(r[1], r[3] - 1, r[4]);
	const num = (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]);
	return num;
}

//根据数字获取excel的列名
function convertToTitle(columnNumber) {
	// 构建字典，便于查询
	const num_set = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
	// 存放结果
	let result = [];
	// 初始化数据
	let num = columnNumber;
	let index;
	// 循环判断，并在结果列前插入，直到小于等于26再退出循环
	while(num > 26) {
		index = num % 26;
		num = Math.floor(num / 26);
		if (index === 0) {
			result.unshift(num_set[25]);
			num = num-1;
		} else {
			result.unshift(num_set[index-1]);
		}
	}
	// 将最后一个值插入
	result.unshift(num_set[num-1]);
	// 输出字符串结果
	return result.join("");
}

// module.exports = {
export {
	convertToTitle,
	buildTree,
	formatDate,
	isDate,
	idxToId,
	invMapOf,
	genMap,
	genSMap,
	genMapByVs,
	foldToObjs,
	foldDeepMap,
	foldLinkedMaps,
	objFrom,
	hasVIn,
	classify,
	classifyByProp,
	assignMul,
	assignOne,
	upWFoundById,
	linkObjByProp,
	cleanObj,
	cloneObj,
	setSupProp,
	genOptStr,
	addOrderedId,
	joinPropByIds,
	joinPropByIdsStr,
	joinValByKeysStr,
	parseUrlParams,
	assignBypath,
	collectLinked,
}