
import Vue from "vue";
import {LANGUAGE_MODEL} from "@/consts";


const docEditState = Vue.observable({
    lanMode: LANGUAGE_MODEL.Cn,
    editing: true,
    doc: {},
    design: {},
    deptRole: {},
    content: {},
    content2: false,
    amIPM:false,
    amIAuthor:false,
    amIAuditor:false,
    amITranslator:false,
    // remarks: [],
    showComment: true,
    remarksMap: {},
    optionalVals: {},
    displayContent: {},
    kMax: 200,
    docStaffs:[],
    prjtStaffs:[],
    staffs:[],
    laws: [],
    // commenting:false,
    // fdPath:'',
    // discuss:'',
    // amIAuthor:false,
    // amIPM:false,
    // repliedId:null,
    // translating:false,
    // contentTranslate:[],
    // prjts:[],
    // addDoc:false,
    // refDocIdxToSet:null,

    clean(){
        this.lanMode = LANGUAGE_MODEL.Cn
        this.editing = true
        this.doc = {}
        this.design = {}
        this.deptRole = {}
        this.content = {}
        this.content2 = false
        this.amIPM = false
        this.amIAuthor = false
        this.amIAuditor = false
        this.amITranslator = false
        this.kMax = 200
        this.displayContent = {}
        this.remarksMap = {}
        this.optionalVals = {}
        this.docStaffs = []
        this.prjtStaffs = []
        this.staffs = []
        this.laws = []
    }
})

export default docEditState
