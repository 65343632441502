export default {
	path: '/courseware',
	component: () => import('./index.vue'),
	children: [
		{
			path: '',
			redirect: '/courseware/videoList'
		},
		{
			path: 'videoList',
			name: 'videoList',
			component: () => import('./videoList.vue')
		},
		{
			path: 'videoPlay/:id',
			name: 'videoPlay',
			component: () => import('./videoPlay.vue')
		}
	]
}