import REQ from '@/app/REQ'
import {upWFoundById} from '@/utils/xyhUtils'


export default {

    data() {
        return {
            prjtStaffs:[],
            prjtStones:[],
            prjts:[],
            siteStaffs:[],
            sites:[],
            staffs:[],
            stoneMarks:[],
            stones:[],
            month:'',
        }
    },

    created() {
        this.month = new Date().YMFmt()
        this.getStoneSuits()
    },

    methods: {
        getStoneSuits() {
            let {month,} = this
            this.req2Data(REQ.orgStoneMarks, {month}, null,
                ({prjtStaffs, prjtStones, prjts, siteStaffs, sites, staffs, stoneMarks, stones}) => {
                    let a = []
                    stoneMarks.forEach(it => {
                        it.timess = 1
                        let stoneMark = a.find(item => it.objId == item.objId && it.stoneId == item.stoneId)
                        if (stoneMark) {
                            stoneMark.timess++
                        } else {
                            a.push(it)
                        }
                    })
                    stoneMarks = a
                    upWFoundById( stoneMarks, stones,"stoneId", (stoneMark, stone) => {
                        stoneMark.wkts = stone.wkts
                        stoneMark.stoneN = stone.name
                    })

                    upWFoundById( prjtStaffs, staffs,"staffId", (prjtStaff, staff) => prjtStaff.name = staff.name)
                    prjts.forEach(it=>it.staffs=[])
                    upWFoundById( prjtStaffs, prjts,"prjtId", (prjtStaff, prjt) => prjt.staffs.push(prjtStaff))

                    upWFoundById( siteStaffs, staffs,"staffId", (siteStaff, staff) => siteStaff.name = staff.name)
                    sites.forEach(it=>it.staffs=[])
                    upWFoundById( siteStaffs, sites,"siteId", (siteStaff, site) => site.staffs.push(siteStaff))
                    upWFoundById( stoneMarks, sites,"objId", (stoneMark, site) => {
                        stoneMark.siteStaffs = site.staffs
                        stoneMark.siteType = site.type
                        stoneMark.siteN = site.name
                        stoneMark.prjtId = site.prjtId
                    })
                    upWFoundById( stoneMarks, prjts,"prjtId", (stoneMark, prjt) => {
                        stoneMark.prjtStaffs = prjt.staffs
                        stoneMark.prjtN = prjt.name
                    })
                    upWFoundById( stoneMarks, prjts,"objId", (stoneMark, prjt) => {
                        stoneMark.prjtId = prjt.id
                        stoneMark.prjtStaffs = prjt.staffs
                        stoneMark.prjtN = prjt.name
                    })
                    stoneMarks.forEach(it=> {
                        let stoneMark=prjtStones.find(item=>it.prjtId == item.prjtId && it.stoneId == item.stoneId)
                        if(stoneMark) it.prjtWkts = stoneMark.wkts
                    })

                    stoneMarks.forEach(it => {
                        if(it.prjtWkts){
                            if(it.prjtWkts.CPM===0 || it.prjtWkts.CPM) it.wkts.CPM = it.prjtWkts.CPM
                            if(it.prjtWkts.PMgr===0 || it.prjtWkts.PMgr) it.wkts.PMgr = it.prjtWkts.PMgr
                            if(it.prjtWkts.director===0 || it.prjtWkts.director) it.wkts.director = it.prjtWkts.director
                            if(it.prjtWkts.CRA===0 || it.prjtWkts.CRA) it.wkts.CRA = it.prjtWkts.CRA
                        }
                        if(it.wkts.CPM===0 || it.wkts.CPM) it.cpmWkt = it.wkts.CPM * it.timess
                        if(it.wkts.CPM===0 ||it.wkts.PMgr) it.pmgrWkt = it.wkts.PMgr * it.timess
                        if(it.wkts.CPM===0 ||it.wkts.director) it.directorWkt = it.wkts.director * it.timess
                        if(it.wkts.CPM===0 ||it.wkts.CRA) it.craWkt = it.wkts.CRA * it.timess
                        it.prjtStaffs.forEach(item => {
                            if (item.role == 'CPM') it.cpmN = it.cpmN ? it.cpmN + '、' + item.name : item.name
                            if (item.role == 'PMgr') it.pmgrN = it.pmgrN ? it.pmgrN + '、' + item.name : item.name
                            if (item.role == 'director') it.directorN = it.directorN ? it.directorN + '、' + item.name : item.name
                        });
                        if (it.siteStaffs) it.siteStaffs.forEach(item => {
                            if (item.role == 'CRA') it.craN = it.craN ? it.craN + '、' + item.name : item.name
                        })
                    })
                    this.stoneMarks = stoneMarks
                })
        },

        onToExcel() {
            let {month} = this
            //window.location.href='<%=basePath%>pmb/excelShowInfo.do';
            //获取表格
            let exportFileContent = document.getElementById("table_report").outerHTML;
            //设置格式为Excel，表格内容通过btoa转化为base64，此方法只在文件较小时使用(小于1M)
            let blob = new Blob([exportFileContent], {type: "text/plain;charset=utf-8"});     	//解决中文乱码问题
            blob = new Blob([String.fromCharCode(0xFEFF), blob], {type: blob.type});
            //设置链接
            let link = window.URL.createObjectURL(blob);
            let a = document.createElement("a");    //创建a标签
            a.download = month.slice(0, 4) + '年' + month.slice(5, 7) + '月打卡详情.xls';  //设置被下载的超链接目标（文件名）
            a.href = link;                            //设置a标签的链接
            document.body.appendChild(a);            //a标签添加到页面
            a.click();                                //设置a标签触发单击事件
            document.body.removeChild(a);            //移除a标签
        }
    },

}
