<template>
      <el-menu class="left">
        <el-submenu v-for="(item,idx) in Navs" :index="idx">
          <template slot="title">
            <span @click="onToPrjtSub(item.p)">{{item.n}}</span>
          </template>
          <el-menu-item v-for="it in tasks[idx]" class="a">
            {{ it.task }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
</template>

<script>
let Navs = [
  {n: '当天任务', p: 'today',},
  {n: '一周内任务', p: 'week',},
  {n: '一月内任务', p: 'month',},
  {n: '一月以上', p: 'more',},
  {n: '任务筛选', p: 'screen',},
]

export default {
  name: 'task',

  data() {
    return {
      Navs,
      tasks: [
        [{task: '任务一'}, {task: '任务二'}],
        [{task: '任务三'}, {task: '任务四'}],
        [{task: '任务五'}, {task: '任务六'}],
        [{task: '任务七'}, {task: '任务八'}],
      ],
    }
  },

  methods: {
    onToPrjtSub(sub) {
      let p = '/main/tasks/' + sub
      this.$router.push(p)
    }
  }

}

</script>

<style scoped>
.a{
  background-color: #4E5C7D;
}
.left {
  float: left;
  width: 150px;
  height: 900px;
  background: #4E5C7D;
}

</style>