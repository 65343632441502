import REQ from "../app/REQ";
import {upWFoundById} from "../utils/xyhUtils"
import siteCard from '../cards/site.vue'
import addSiteDlg from "@/dialg/addSiteDlg.vue";

export default {
	name: "prjtHdr",
	props: ['prjt'],

	data() {
		return {
		};
	},

	methods: {

	}
};
