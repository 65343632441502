import gDlgMix from "@/gDlgs/gDlgMix";

export default {
	name: "editDateDlg",
	mixins:[ gDlgMix ],

	data() {
		return {
			content:'',
			initContent:''
		}
	},

	methods: {
		setParams(content) {
			Object.assign(this, {content, initContent:content} )
		},

		results() {
			let {content, initContent} = this
			if(content != initContent) return [content]
		}
	}
};
