import {PLATEFORM} from "@/consts";

function genPgRtP(component, name, title, pN = 'id') {
	return { path: `${name}/:${pN}`, name, props: true, component,
		meta: {title:title + (title?'--':'')+ PLATEFORM },
	}
}

function genPgRt(component, name, title) {
	return { path: name, name, component,
		meta: {title:title + (title?'--':'')+ PLATEFORM },
	}
}

export {
	genPgRt,
	genPgRtP,
}
