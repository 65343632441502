<template>
<el-form style="width: 100%;float: right">
  <el-date-picker style="width: 100%" v-model="date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"/>
</el-form>
</template>

<script>
export default {
  name: "selDate",
  props:['oldDate', 'fd'],

  data() {
    return {
      date:'',
    }
  },

  watch:{
    date(){
      this.$emit('func', {fd:this.fd, value:this.date})
    },
    oldDate(){
      this.date = this.oldDate
    }
  },

  created() {
    this.date = this.oldDate
  }
}
</script>

<style scoped>

</style>