import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "editStaffDlg",
	mixins: [formDlgMix],
	props: ['editStaff'],

	data() {
		return {
			staff: {
				name: '',
				mobile: '',
				email: '',
			},
			deving:XyhConf.deving,
			unbind:false,
		}
	},

	watch: {
		working() {
			let {editStaff} = this
			if (editStaff) {
				this.staff = {id:editStaff.id,name: editStaff.name, mobile: editStaff.mobile, email: editStaff.email}
			}else {
				this.staff= {name: '', mobile: '', email: ''}
			}
			this.unbind = false
		}
	},

	methods: {
		ok() {
			let {staff, unbind, editStaff} = this
			if(this.chk2msg(staff.name, '请填写姓名', staff.mobile, '请填写手机号',)) return
			if (this.editStaff) {
				if (unbind) staff.unbind = true
				if(staff.mobile != editStaff.mobile && !unbind) {
					this.confirm('手机号已修改，应解绑之前账号，避免原员工仍能登录，如要继续提交请点击确认',
						() => {this.reqThenBk(REQ.upStaff, staff)})
				}else {
					this.reqThenBk(REQ.upStaff, staff)
				}
			} else {
				this.reqThenBk(REQ.addStaff, staff)
			}
		}
	}
};
