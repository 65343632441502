import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "editLinkOrgDlg",
    mixins: [formDlgMix],
    props: ['oldLinkOrgDept','lOrgId'],

    data() {
        return {
            name:'',
            type:'',
        }
    },

    watch: {
        working(){
            let {oldLinkOrgDept} = this
            if(oldLinkOrgDept){
                Object.assign(this, {name:oldLinkOrgDept.name, type:oldLinkOrgDept.type,})
            }else {
                Object.assign(this, {name:'', type:'',})
            }
        }
    },

    methods: {

        ok() {
            let {name, type, oldLinkOrgDept, lOrgId} =this
            console.log(lOrgId)
            if(this.chk2msg(name, '请填写姓名', type, '请选择单位类型')) return
            if(oldLinkOrgDept){
                this.reqThenBk(REQ.upThrdDept, {id:oldLinkOrgDept.id, name, type})
            }else {
                this.reqThenBk(REQ.addThrdDept, {lOrgId, name, type})
            }
        },
    }
};
