import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import { MON_TYPE, } from '@/consts.js'

export default {
	name: "addMonDlg",
	props:['siteId'],
	mixins:[ formDlgMix ],

	watch:{
		siteId(nv){ this.mon.siteId = nv}
	},

	data() {
		return {
			mon: {
				siteId:this.siteId,
				type:MON_TYPE.common,
				idx:0,
				panDes:'',
				planSDate:'',
				planEDate:'',
			}
		}
	},

	methods: {
		onOk() {
			this.authedReqR(REQ.addMon, this.mon, ()=>{
				this.working = false
				this.$emit("monAdded")
			})
		}
	}
};
