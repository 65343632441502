import formDlgMix from "@/gDlgs/formDlgMix";
import gDlgMix from "@/gDlgs/gDlgMix";

export default {
	name: "selMulDlg",
	mixins:[ gDlgMix ],

	data() {
		return {
			items:[],
			kN:'name',
			marks:[],
		}
	},

	methods: {

		setParams(items, kN) {
			if(typeof kN == 'undefined') kN = 'name'
			let marks = items.map(_=>false)
			Object.assign(this,{items, marks, kN})
		},

		results() {
			let {items, marks} = this
			let seleds = items.filter((_, idx)=> marks[idx])
			if(seleds.length > 0) return [seleds]
		},
	}
};
