<template>
  <ul class="left">
    <li v-for="it in Navs" @click="onToPrjtSub(it.p)">
      {{ it.n }}
    </li>
  </ul>
</template>

<script>
let Navs = [
  {n: '受试者招募', p: '/subjects',},
  {n: '监查预约和打卡', p: '/monitors',},
  {n: '检查报告', p: '/mvrs',},
  {n: 'Issue列表', p: '/issues',},
  {n: 'CAPA列表', p: '/capas',},
  {n: '待跟进措施', p: '/follows',},
  {n: '里程碑', p: '/stones',},
  {n: '团队', p: '/team',},
]

export default {
  name: 'siteNav',
  props: ['prjts'],
  data() {
    return {
      Navs,
    }
  },
  methods: {
    onToPrjtSub(sub) {
      let p =  '/main/prjt/'+this.$route.params.pid+'/site/'+this.$route.params.sid +sub
      this.$router.push(p)
    }
  }

}

</script>

<style scoped>
.left {
  float: left;
  width: 150px;
  height: 875px;
  background: #4E5C7D;
}

</style>