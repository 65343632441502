import formDlgMix from "@/gDlgs/formDlgMix";

export default {
    name: "selDeptDlg",
    mixins: [formDlgMix],
    props: ['depts'],

    data() {
        return {
            targetDeptId:'',
        }
    },

    watch: {
        working(){
            Object.assign(this,{targetDeptId: '',})
        }
    },

    computed: {

    },

    methods: {
        onOk(){
            let {targetDeptId, depts} = this
            if(this.chk2msg(targetDeptId,'请选择目标部门')) return
            this.$emit('oked', depts.find(it=>it.id == targetDeptId))
            this.working =false
        }
    }
};