import gDlgMix from "@/gDlgs/gDlgMix";

export default {
    name: "selSglDlg",
    mixins:[ gDlgMix ],

    data() {
        return {
            items:[],
            selected:'',
            labelKN:'name',
            valKN:'id',
        }
    },

    methods: {

        setParams(items,valKN, labelKN) {
            if(typeof valKN == 'undefined') valKN = 'id'
            if(typeof labelKN == 'undefined') labelKN = 'name'
            Object.assign(this,{items, labelKN, valKN})
        },

        results() {
            if (this.selected) {
                return [this.selected]
            } else {
                this.$message({
                    message: '该选项不能为空',
                    type: 'warning'
                });
            }
        },
    }
};
