import { render, staticRenderFns } from "./docItem.vue?vue&type=template&id=47ef08b7&scoped=true&"
import script from "./docItem.js?vue&type=script&lang=js&"
export * from "./docItem.js?vue&type=script&lang=js&"
import style0 from "../assets/css/doc.less?vue&type=style&index=0&id=47ef08b7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ef08b7",
  null
  
)

export default component.exports