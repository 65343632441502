import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "addPrjtStaffDlg",
	mixins: [formDlgMix],
	props: ['Id','addCPM','addSiteStaff'],

	data() {
		return {
			staffId:'',
			ourStaffs: [],
			role:'',
			sDate:'',
			eDate:'',
		}
	},

	watch: {
		working() {
			let {sDate,} = this
			this.req2Data(REQ.ourStaffs,null,'ourStaffs')
			sDate = new Date().YMDFmt()
			Object.assign(this, {staffId:'', role:'', sDate, eDate:'',})
		}
	},

	methods: {

		ok() {
			let {Id, staffId, role, addCPM, addSiteStaff, sDate, eDate} = this
			if(addSiteStaff) {
				role ='CRA'
				this.reqThenBk(REQ.addSiteStaff, {siteId: Id, staffId, role, sDate, eDate,})
			} else {
				if (addCPM) role = 'CPM';
				this.reqThenBk(REQ.addPrjtStaff, {prjtId: Id, staffId, role, sDate, eDate,})
			}

		}
	}
};
