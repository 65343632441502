import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import {mapState} from "vuex";


export default {
	name: "editDocPrjtDlg",
	mixins:[ formDlgMix ],
	props:['oldDocPrjt'],

	data() {
		return {
			name: '',
			des: '',
			orgN: '',
			open:false,
			docTplts:[],
			sopTpltId:'',
			policyTpltId:'',
			manualTpltId:'',
		}
	},

	computed: {
		...mapState({
			isResPrivider: ({ curOrg }) => curOrg?.isResPrivider,
		}),
	},

	watch:{
		working(){
			let {oldDocPrjt} =this
			this.req2Data(REQ.docTplts, {}, 'docTplts',)
			if(oldDocPrjt){
				Object.assign(this, {name:oldDocPrjt.name, des:oldDocPrjt.des, orgN:oldDocPrjt.orgN, sopTpltId:oldDocPrjt.sopTpltId,
					open:oldDocPrjt.open,policyTpltId:oldDocPrjt.policyTpltId,manualTpltId:oldDocPrjt.manualTpltId})
			}else {
				Object.assign(this, {name:'', des:'', orgN:'', open:'', sopTpltId:'', policyTpltId:'',manualTpltId:''})
			}
		}
	},

	methods: {
		ok() {
			let {name,des, orgN, open, oldDocPrjt,sopTpltId,policyTpltId,manualTpltId,} = this
			if(oldDocPrjt) {
				this.reqThenBk(REQ.upDocPrjt, {id:oldDocPrjt.id, name, des, open, orgN, sopTpltId,policyTpltId,manualTpltId})
			} else {
				this.reqThenBk(REQ.addDocPrjt, {name, des, open, orgN, sopTpltId,policyTpltId,manualTpltId})
			}
		}
	}
};
