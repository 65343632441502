
import REQ from "@/app/REQ";
import PrjtHdr from "@/coms/prjtHdr.vue";
import prjtInfoNav from "@/nav/prjtInfo.vue";


export default {
	name: 'prjtInfoCon',
	props:['prjt'],
	components: { PrjtHdr, prjtInfoNav},

	data() {
		return {}
	},

	created() {
	},

	methods: {
		onSiteAdded(site){
			this.sites.unshift(site)
		}
	}
}

