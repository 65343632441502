<template>

  <el-container>
    <el-aside width="150px">
      <task-nav/>
    </el-aside>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import taskNav from "@/nav/task.vue"

export default {
  name: "task",
  components: {taskNav},
}
</script>

<style scoped>

</style>