import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "editWktsDlg",
    mixins:[ formDlgMix ],
    props:['referToWkts', 'wkts', 'prjtId', 'stoneId', 'note'],

    data() {
        return {
        }
    },

    methods: {
        ok() {
            let {wkts, prjtId, stoneId, note,} = this
            for (let it in wkts) {if (!wkts[it]) delete wkts[it]}
            this.reqThenBk(REQ.setPrjtStoneWkts, {prjtId, stoneId, wkts, note})
        }
    }
};
