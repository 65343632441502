import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import addStoneDlg from "./addStoneDlg.vue";
import {mapGetters} from "vuex";

export default {
    components:{addStoneDlg},
    mixins:[ formDlgMix ],
    props:['id'],

    data() {
        return {
            roles:[],
            stone: {},
            studyOrSite:false,
        }
    },

    computed: {
        ...mapGetters(['amIAdmin']),
    },

    created() {
        this.getStone()
    },

    methods: {
        getStone(){
            this.req2Data(REQ.mileStone, {id:this.id}, 'stone',()=> {
                if (this.stone.level < this.STONE_LV.site) this.studyOrSite = true
            })
        },

        ok() {
            let {stone} = this
            for (let i in stone.wkts)if(!stone.wkts[i]) delete stone.wkts[i]
            this.req2Data(REQ.upStone, stone)
        }


    }
};