
export default {
	name: "followCard",
	props:['follow', 'site'],

	data() {
		return {
		}
	},

	methods: {
	}
};
