
import dlgFoot from "@/generalComs/dlgFoot.vue"

export default {
    components:{ dlgFoot },

    data() {
        return {
            formLabelWidth: '120px',
            working: false,
        }
    },

    methods:{
        close(){this.working = false},
        show(){this.working = true},

        onOk(){ this.ok() },

        reqThenBk(req, data) {
            this.req2Data(req, data, {}, res=>{
                this.close()
                this.$emit("oked", res)
            })
        }
    }
}