import REQ from "../app/REQ";
import editDocPrjtDlg from "@/dialg/editDocPrjtDlg.vue";
import {upWFoundById} from "@/utils/xyhUtils";
import {mapGetters, mapState} from "vuex";

export default {
	name: "myDocPrjts",
	components:{editDocPrjtDlg },

	data() {
		return {
			prjts: [],
			docPrjt:null,
			deving:XyhConf.deving,
		};
	},

	computed: {
		...mapState({
			userId: ({curStaff}) => curStaff?.id,
		}),
		...mapGetters(['amIAdmin']),
	},

	created() {
		this.getMyDocPrjts()
	},

	beforeRouteEnter(to, from, next) {
		to.meta.isFromDocPrjt = (from.meta.title == '文档项目详情')
		next()
	},

	methods: {
		getMyDocPrjts(){
			this.req2Data(REQ.myDocPrjts, null, null,({prjts, prjtStaffs, staffs})=>{
				if(prjts.length == 1 && !this.$route.meta.isFromDocPrjt) {
					this.goto('/main/docPrjtTabs/' + prjts[0].id)
					return
				}
				upWFoundById(prjtStaffs, staffs, "staffId", (prjtStaff, staff) => prjtStaff.staffN = staff.name)
				upWFoundById(prjtStaffs, prjts, "prjtId", (prjtStaff, prjt) => {
					if(prjtStaff.role=='PM') {
						if(prjtStaff.staffId == this.userId) prjt.amIPM = true
						prjt.pmN = prjt.pmN ? prjt.pmN + '、' + prjtStaff.staffN : prjtStaff.staffN
					}
				})
			})
		},

		editDocPrjt(docPrjt){
			this.docPrjt = docPrjt
			this.showDlg('editDocPrjtDlg')
		},
	}
};
