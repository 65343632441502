import REQ from "../app/REQ";
import {upWFoundById} from "@/utils/xyhUtils";
import addPrjtStaffDlg from "@/dialg/addPrjtStaffDlg.vue";
import addDocPrjtStaffDlg from "@/dialg/addDocPrjtStaffDlg.vue";
import {mapState} from "vuex";

export default {
	name: "companyPrjt",
	components:{addPrjtStaffDlg, addDocPrjtStaffDlg},

	data() {
		return {
			prjts: [],
			pid:'',
			docPrjts:[],
			docPid:'',
		};
	},

	computed: {
		...mapState({orgProducts: ({ curOrg }) => curOrg?.products}),
	},

	created() {
		this.getPrjts()
		this.getDocPrjts()
	},

	methods: {
		getPrjts(){
			this.req2Data(REQ.prjtsOfOrg, null, null,
				({prjts, prjtStaffs, staffs}) => {
					prjts.sort((e1, e2) => e2.id - e1.id)
					upWFoundById(prjtStaffs, staffs, "staffId", (prjtStaff, staff) => prjtStaff.staffN = staff.name)
					upWFoundById(prjtStaffs, prjts, "prjtId", (prjtStaff, prjt) => {
						if(prjtStaff.role=='CPM') prjt.cpmN = prjt.cpmN ? prjt.cpmN+ '、' + prjtStaff.staffN:prjtStaff.staffN
					})
					Object.assign(this, {prjts})
				})
		},

		getDocPrjts(){
			this.req2Data(REQ.docPrjts, null, {prjts: 'docPrjts'},({prjts, prjtStaffs, staffs})=>{
				upWFoundById(prjtStaffs, staffs, "staffId", (prjtStaff, staff) => prjtStaff.staffN = staff.name)
				upWFoundById(prjtStaffs, prjts, "prjtId", (prjtStaff, prjt) => {
					if(prjtStaff.role=='PM') prjt.pmN = prjt.pmN ? prjt.pmN+ '、' + prjtStaff.staffN:prjtStaff.staffN
				})
			})
		},

		addDocPrjtPM(pid){
			this.docPid=pid
			this.showDlg('addDocPrjtStaffDlg')
		},

		addPrjtCPM(pid){
			this.pid=pid
			this.showDlg('addPrjtStaffDlg')
		},

		onDelPrjt(id) {

		},

		onEditPrjt(id) {

		},
	}
};
