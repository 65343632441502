import REQ from "../app/REQ";

export default {
	name: "libDocPrjts",

	data() {
		return {
			prjts: [],
		};
	},

	created() {
		this.getDocLibPrjts()
	},

	beforeRouteEnter(to, from, next) {
		to.meta.isFromDocPrjt = (from.meta.title == '文档项目详情')
		next()
	},

	methods: {
		getDocLibPrjts(){
			this.req2Data(REQ.prjtsInDocLib, null, 'prjts',(prjts)=>{
				if(prjts.length == 1 && !this.$route.meta.isFromDocPrjt) {
					this.goto('/main/libDocPrjt/' + prjts[0].id)
					return
				}
			})
		},

	}
};
