import { render, staticRenderFns } from "./linkOrgs.vue?vue&type=template&id=736f275d&scoped=true&"
import script from "./linkOrgs.js?vue&type=script&lang=js&"
export * from "./linkOrgs.js?vue&type=script&lang=js&"
import style0 from "./linkOrgs.less?vue&type=style&index=0&id=736f275d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736f275d",
  null
  
)

export default component.exports