

export default {

    data() {
        return {
            date:['',''],
            sDate:'',
            eDate:'',
        }
    },

    created() {

    },


    methods:{
        changeDate(type){
            let {sDate,eDate} = this
            sDate = new Date()
            eDate = new Date()
            if(type == 'currentWeek'){
                sDate = sDate.weekFirstDay()
                eDate = new Date()
            }else if(type == 'lastWeek'){
                sDate.setDate(new Date().weekFirstDay().getDate() - 7)
                eDate.setDate(new Date().weekFirstDay().getDate() - 1)
            }else if(type == 'currentMonth'){
                sDate = new Date(eDate.getFullYear(), eDate.getMonth(), 1)
                eDate = new Date()
            }else if(type == 'lastMonth'){
                sDate = new Date(eDate.getFullYear(), eDate.getMonth()-1, 1)
                eDate = new Date(eDate.getFullYear(), eDate.getMonth(), 0)
            }
            this.date = [sDate,eDate]
            Object.assign(this,{sDate:sDate.YMDFmt(), eDate:eDate.YMDFmt()})
            this.getData()
        },

        setFilterDate(date){
            this.sDate = date ? date[0].YMDFmt():''
            this.eDate = date ? date[1].YMDFmt():''
            this.getData()
        },
    }
}