import addGroupStaffDlg from "./addGroupStaffDlg.vue"
import REQ from '@/app/REQ'
import inputStr from '@/doc/inputStr.vue'
import inuptText from '@/doc/inuptText.vue'
import sel from '@/doc/sel.vue'
import selDate from '@/doc/selDate.vue'
import {classifyByProp, cloneObj, upWFoundById} from '@/utils/xyhUtils'
import elDragDialog from "@/directive/el-drag-dialog";
import dlgFoot from "@/generalComs/dlgFoot.vue"
import {DOC_ROLE, REMARK_STATE, DOC_STATE} from "@/consts"
import docItemRemarks from "./docItemRemarks.vue";
import currencyInput from "./currencyInput.vue";
import docItem from "./docItem.vue";
import docMix from "./docMix";
import docEditState from "@/doc/docEditState";

function person(k){return {name: '', name_E:'',position: '',position_E:'',dept_E:'', dept: '', duty_E:'',duty: '', k,}}
function annexe(k){return {name: '',name_E:'', num: '',num_E:'', k}}
function abbr(k){return {name: '',name_E:'', fullEn: '', fullCn: '', k,}}
function term(k){return {name: '',name_E:'',des_E:'', des: '', k,}}
function refLaw(k){return {name: '',name_E:'', issuer: '',issuer_E:'', num: '',num_E:'', date: '',date_E:'', k,}}
function refFlow(k){return {num: '',name: '',name_E:'', ver: '',ver_E:'', k,}}
function history(k){return {ver: '',ver_E:'',note_E:'', note: '', k,}}
function scope(k){return {name: '',name_E:'',des_E:'', des: '', k,}}


export default {
    name: "editSop",
    components: {
        dlgFoot,
        addGroupStaffDlg,
        'component-a': inputStr,
        'component-b': sel,
        'component-c': selDate,
        'component-d': inuptText,
        docItemRemarks,
        currencyInput,
        docItem
    },
    directives: { elDragDialog },
    mixins:[docMix],

    data() {
        return {

            fd: ['name', 'num', 'ver', 'issueDate', 'sDate', 'planSDate', 'actualSDate', 'purpose', 'scopeNote',],

            content: {
                authors: [
                    person(100),
                ],
                auditors: [
                    person(101),
                ],
                authorizers: [
                    person(102),
                ],
                duties: [
                    {role: '',role_E:'', des: [{k: 103, v: '',v_E:'',}], k: 104,},
                ],
                principleGroups: [{name: '', name_E:'',principles: [{name: '',name_E:'', des: [{k: 105, v: '',v_E:'',}], k: 106},], k: 107}],
                flows: [
                    {title: '', title_E:'',steps: [{role: '', role_E:'',content: [{k: 108, v: '',v_E:''}], k: 109},], k: 110}
                ],
                refLaws: [
                    refLaw(111),
                ],
                refFlows: [
                    refFlow(112),
                ],
                abbrs: [
                    abbr(113),
                ],
                terms: [
                    term(114),
                ],
                annexes: [
                    annexe(115),
                ],
                histories: [
                    history(116),
                ],
                scopes: [scope(118),],
                scopeNote: '',
                scopeNote_E:'',
                purpose: '',
                purposes_E:'',
                sDate: '',
                sDate_E:'',
                issueDate: '',
                issueDate_E:'',
                deptIssue: '',
                deptIssue_E:'',
                deptsRecv: '',
                deptsRecv_E: '',
            },
        }
    },

    methods: {
        Person(){return person(this.genNewK())},

        Annexe(){return annexe(this.genNewK())},

        Abbr(){return abbr(this.genNewK())},

        Term(){return term(this.genNewK())},

        RefLaw(){return refLaw(this.genNewK())},

        RefFlow(){return refFlow(this.genNewK())},

        History(){return history(this.genNewK())},

        Scope(){return scope(this.genNewK())},

        getDoc() {
            this.req2Data(REQ.doc, this.id, {doc: 'doc',}, ({docStaffs,doc,prjtStaffs,staffs}) => {
                if (doc.content) {
                    docEditState.editing = false
                    if(doc.kMax)this.kMax = doc.kMax
                    if(doc.content.principles){
                        if (!doc.content.principleGroups) {
                            doc.content.principleGroups = [{name: '', principles: doc.content.principles}]
                        }
                        delete doc.content.principles
                    }
                    this.checkToInitK(doc.content)
                    this.content = doc.content
                    this.content2 = cloneObj(doc.content);
                }
                if(prjtStaffs.find(it=>it.role == DOC_ROLE.PM && it.staffId == this.staffId)) this.amIPM = true
                if(docStaffs.find(it=>it.role == DOC_ROLE.author && it.staffId == this.staffId)) this.amIAuthor = true
                if(docStaffs.find(it=>it.role == DOC_ROLE.auditor && it.staffId == this.staffId)) this.amIAuditor = true
                if(!this.amIAuthor) docEditState.editing = false
                this.getDocPrjt()
                this.getRemarks()
                this.screenStaffs(docStaffs,prjtStaffs,staffs)
                this.getDocs()
                if(doc.state == DOC_STATE.fixed) docEditState.editing = false
            })
        },
    }
};
