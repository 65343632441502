
export default {
	name: "siteCard",
	props:['site', 'hospital', 'dept'],

	data() {
		return {
		}
	},

	methods: {
	}
};
