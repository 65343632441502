export default {
	name: "Nav",
	props: ["activeBar"],
	data: () => {
		return {
			navList: [
				{
					title: "首页",
					name: "home",
					link: "/home/index"
				},
				{
					title: "行业背景",
					link: "/home/background"
				},
				{
					title: "平台介绍",
					link: "/home/about"
				},
				{
					title: "平台服务",
					menus: [
						{
							name: '进修',
							path: "/home/service/1"
						}, {
							name: '管理',
							path: "/home/service/2"
						}, {
							name: '评估',
							path: '/home/service/3'
						}
					]
				},
				{
					title: "教师风采",
					link: "/home/teachers",
				},
				{
					title: "联系我们",
					link: "/home/contact"
				}
			]
		};
	},
	methods: {
		handleCommand(command) {
			this.$router.replace(command)
		}
	}
};
