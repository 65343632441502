import REQ from '@/app/REQ'
import editDocDlg from '@/dialg/editDocDlg.vue'
import {upWFoundById} from "../utils/xyhUtils"
import {mapState} from "vuex";
import {DOC_TYPE} from "@/consts"
import {wordViewUrl} from "@/doc/consts"

export default {
    name: 'sops',
    components:{editDocDlg,},

    data() {
        return {
            fileUrl:'',
            filterWords:'',
            docs:[],
            allDocs:[],
            oldDoc:null,
            tplts:[],
            type:null,
        }
    },

    computed: {
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,
        }),
    },

    created() {
        this.token = this.getCookie('token')
        this.getDocs()
    },

    methods: {
        getDocs() {
            this.req2Data(REQ.docsOfPrjt, {}, null, ({tplts, docs})=>{
                docs.sort((doc1,doc2)=> doc1.num.toLowerCase().localeCompare(doc2.num.toLowerCase()))
                upWFoundById(docs, tplts, 'tpltId', (doc, tplt) => doc.tpltN = tplt.name)
                docs.forEach(it=> {
                    if(!it.type) it.type = DOC_TYPE.sop
                    it.url = encodeURIComponent(XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + it.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime())
                })
                this.allDocs = docs
            })
        },

        editDoc(oldDoc,type){
            this.oldDoc = oldDoc
            this.type = type
            this.showDlg('editDocDlg')
        },

        preview(url){
            window.open(wordViewUrl + url)
            // this.fileUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=' + url;
            // this.download(REQ.previewSop, id)
        },

        // //搜索按钮
        search() {
            if(this.filterWords || this.filterWords == 0){
                this.docs = this.allDocs.filter(it=>it.name.toLowerCase().includes(this.filterWords.toLowerCase()) || it.num.toLowerCase().includes(this.filterWords.toLowerCase()))
            } else {
                this.docs = this.allDocs
            }
        },
    },

}
