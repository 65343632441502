import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import {SMSNOTIWDOC_TYPE} from "@/consts"

export default {
    name: "smsNotiDlg",
    mixins: [formDlgMix],
    props: ['auditorStaffs','pmStaffs','authorStaffs','docId','staffId','amIAuthor','amIAuditor'],

    data() {
        return {
            type:'',
            checkAuthor: {},
            checkPm: {},
            checkAuditor: {},
            selectAllPm:false,
            selectAllAuthor:false,
            selectAllAuditor:false,
            isIndeterminatePm: false,
            isIndeterminateAuthor: false,
            isIndeterminateAuditor: false,
        }
    },

    watch: {
        working(){
            this.pmStaffs.forEach(it=>this.checkPm[it.staffId] = false)
            this.authorStaffs.forEach(it=>this.checkAuthor[it.staffId] = !this.amIAuthor && this.amIAuditor)
            this.auditorStaffs.forEach(it=>this.checkAuditor[it.staffId] = this.amIAuthor)
            Object.assign(this,{
                type:this.amIAuthor ? SMSNOTIWDOC_TYPE.docUped:SMSNOTIWDOC_TYPE.docRemarked,
                selectAllPm:false,
                selectAllAuthor:!this.amIAuthor && this.amIAuditor,
                selectAllAuditor:this.amIAuthor,
                isIndeterminatePm: false,
                isIndeterminateAuthor: false,
                isIndeterminateAuditor: false,
            })
        }
    },

    methods: {
        batchSelectionPm(selectAll){
            this.selectAllPm = selectAll
            this.isIndeterminatePm = false
            for (let i in this.checkPm){
                this.checkPm[i] = selectAll
            }
        },

        batchSelectionAuthor(selectAll){
            this.selectAllAuthor = selectAll
            this.isIndeterminateAuthor = false
            for (let i in this.checkAuthor){
                this.checkAuthor[i] = selectAll
            }
        },

        batchSelectionAuditor(selectAll){
            this.selectAllAuditor = selectAll
            this.isIndeterminateAuditor = false
            for (let i in this.checkAuditor){
                this.checkAuditor[i] = selectAll
            }
        },

        CheckedChange(a,b,c) {
            let checkedCount = 0;
            for (let i in c){
                if(c[i]) checkedCount++
            }
            this[a] = checkedCount === Object.keys(c).length;
            this[b] = checkedCount > 0 && checkedCount < Object.keys(c).length;
            this.$forceUpdate()
        },

        onOk(){
            let {docId,type,checkPm,checkAuthor,checkAuditor} = this
            let staffIds = []
            for (let i in checkPm){
                if(checkPm[i]) staffIds.push(i)
            }
            for (let i in checkAuthor){
                if(checkAuthor[i]) staffIds.push(i)
            }
            for (let i in checkAuditor){
                if(checkAuditor[i]) staffIds.push(i)
            }
            if(this.chk2msg(staffIds.length > 0, '未选择任何人员')) return
            this.req2Data(REQ.smsNotiWDoc,{docId,type,staffIds},{},()=>{
                this.ok('消息发送成功')
                this.working = false
            })
        }
    }
};