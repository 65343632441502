<template>
  <h2>请耐心等待， {{$route.path}} 正在开发中....</h2>
</template>

<script>
export default {
  name: "deving",
  created(){console.log("deving")},
};
</script>

