import REQ from "../app/REQ";
import {upWFoundById, assignMul} from "../utils/xyhUtils"

import addPrjtDlg from "@/dialg/addPrjtDlg.vue";

export default {
	name: "myPrjts",
	components:{addPrjtDlg },

	data() {
		return {
			prjts: [],
		};
	},

	created() {
		this.getMyPrjts()
	},

	methods: {
		getMyPrjts(){
			this.req2Data(REQ.myPrjts, null, null,
				({prjts,prjtStaffs,staffs}) => {
					prjts.sort((e1, e2) => e2.id - e1.id)
					upWFoundById(prjtStaffs, staffs, "staffId", (prjtStaff, staff) => prjtStaff.staffN = staff.name)
					upWFoundById(prjtStaffs, prjts, "prjtId", (prjtStaff, prjt) => {
						if(prjtStaff.role=='CPM') prjt.cpmN = prjt.cpmN ? prjt.cpmN+ '、' + prjtStaff.staffN:prjtStaff.staffN
					})
					Object.assign(this, {prjts})
				})
		},

		onPrjtAdded(){
			this.getMyPrjts()
		},

		onDelPrjt(id) {

		},

		onEditPrjt(id) {

		},
	}
};
