import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "markTopicDlg",
	props: {topic:{type:Object, default:{}}, monId:String },
	mixins: [ formDlgMix ],

	watch:{
		working(nv){ if(nv) this.answer = { opt:-1, content:''} }
	},

	data() {
		return {
			answerOpts:['是', '否', '不适用'],
			answer: {
				opt:-1,
				content:'',
			}
		}
	},

	methods: {
		onOk() {
			let {topic:{id:topicId}, monId, answer} = this
			Object.assign(answer, {monId, topicId})
			this.authedReqR(REQ.setTopicAnswer, answer, ()=>{
				this.working = false
				this.$emit("topicMarked")
			})
		}
	}
};
