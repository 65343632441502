import top from '@/components/header.vue'
import primaryNav from "@/nav/primary.vue";
import addPrjtDlg from "@/dialg/addPrjtDlg.vue";
import PubSub from "@/lib/Pub";
import REQ from "@/app/REQ";

export default {
	name: 'primaryCon',
	components: { top, primaryNav, addPrjtDlg},

	data() {
		return {
			prjts:[],
			isRouterAlive:true,
		}
	},

	created() {
		PubSub.subscribe('refresh',_=>{
			this.isRouterAlive = false
			this.$nextTick(function () {
				this.isRouterAlive = true
			})
		})
		this.req2Data(REQ.prjtsWMe, null, {prjts:'prjts'})
	},

	methods: {
		onPrjtAdded(prjt){
			this.prjts.unshift(prjt)
			this.req2Data(REQ.prjtsWMe, null, {prjts:'prjts'})
			this.goto("prjt", prjt.id)
		}
	}
}

