
const Navs = [
	{n:"中心筛选情况", p:"filter"},
	{n:"中心启动情况", p:"launch"},
	{n:"受试者招募情况", p:"subjects"},
	{n:"Issue列表", p:"issues"},
	{n:"待审阅报告", p:"mvrs"},
	{n:"任务列表（项目层面）", p:"tasks"},
	{n:"里程碑", p:"stone"},
	{n:"团队", p:"team"},
]


export default {
	name: 'prjtInfoNav',
	data() {
		return { Navs,}
	},
}
