import { render, staticRenderFns } from "./rmDocDlg.vue?vue&type=template&id=524cf47d&"
import script from "./rmDocDlg.js?vue&type=script&lang=js&"
export * from "./rmDocDlg.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports