import gDlgMix from "@/gDlgs/gDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "editFileDlg",
	mixins:[ gDlgMix ],

	data() {
		return {
			uploadUrl:XyhConf.HglSHost +'/' + REQ.putFile,
			file:'',
			files:[]
		}
	},

	methods: {
		setParams(file) {
			Object.assign(this, {file, files: file ? [{name: '文件一.doc'}]:[]})
		},

		handleChange(file,fileList){
			if (fileList.length > 1) {
				fileList.splice(0, 1);
			}
		},

		successUpload(res){
			this.file = res.data.url
		},

		results() {
			let {file} =this
			if(this.chk2msg(file, '请上传文件',)) return
			return [file]
		},
	}
};
