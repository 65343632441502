
import siteCon from "@/views/site.vue";
import deving from "@/coms/deving";

export default {
	path: 'site/:sid',
	component: siteCon,
	props: true,
	children: [
		{ path: 'subjects', component: deving, },
		{ path: 'monitors', component: deving, },
		{ path: 'mvrs', component: deving, },
		{ path: 'issues', component: deving, },
		{ path: 'capas', component: deving, },
		{ path: 'follows', component: deving, },
		{ path: 'stones', component: deving, },
		{ path: 'team', component: deving, },
		{ path: '', component: deving, },
	],
}