import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "addFollowDlg",
	props: [ "siteId" ],
	mixins:[ formDlgMix ],

	watch:{
		siteId(nv){ this.follow.siteId = nv}
	},

	data() {
		return {

			follow: {
				siteId: this.siteId,
				content: '',
				planDate: '',
				date: '',
				topic:'',
			}
		}
	},

	methods: {
		onOk() {
			this.authedReqR(REQ.addFollow, this.follow, ()=>{
				this.working = false
				this.$emit("followAdded")
			})
		}
	}
};
