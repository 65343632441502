
let ADMREQ = {
    acceptOrgReg:0,
    addAdmin:0,
    addDocTplt:0,
    addOrg:0,
    addOrgAdmin:0,
    admins:0,
    cloneDocs:0,
    commonResCfg:0,
    docPrjtsOfOrg:0,
    docsOfOrg:0,
    docTplts:0,
    orgs:0,
    orgAdmins:0,
    orgRegs:0,
    regDocFeeState:0,
    rejectOrgReg:0,
    rmAdmin:0,
    setCommonResOrg:0,
    upDocTplt:0,
    upOrg:0,
    usrsWith:0,
}

for (var p in ADMREQ) {ADMREQ[p] = 'adm/' + (ADMREQ[p] || p)}

export default ADMREQ