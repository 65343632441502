import {REQ} from '../services/config'
import {mapGetters, mapState} from 'vuex'
import PubSub from "@/lib/Pub";
import {PRODOUCT} from "@/consts";

export default {
	name: 'top',
	data() {
		return {
			switchOrg:false,
			orgs: [],
			staffs:[],
			orgIdx: 0,
			isInAdm:false,
			helpUrl:XyhConf.helpUrl,
		}
	},

	computed: {
		...mapState({
			userName: ({myself, curStaff}) => curStaff?.name || myself?.name ,
			orgName: ({ curOrg }) => curOrg?.sname || curOrg?.name ,
			orgProducts: ({ curOrg }) => curOrg?.products,
		}),
		...mapGetters(['amIPlatAdm']),
	},

	watch: {
		$route() {
			this.isInAdm = this.$route.path.match('/adm') ? true:false
		}
	},

	methods: {

		openUrl(url){
			window.open(url)
		},

		onCommand(cmd) {
			// if(cmd.startsWith('/'))  return this.$router.push(command)

		    if(cmd == 'switchOrg') {
		    	this.req2Data(REQ.myStaffs, null, undefined, ()=>this.switchOrg = true)
		    }
		},

		switchPage(){
			if (this.orgProducts.find(it => it == PRODOUCT.sop)) {
				this.$router.push('/main/myDocPrjts')
			} else if (this.orgProducts.find(it => it == PRODOUCT.stone)) {
				this.$router.push('/main/stoneMarkState')
			} else {
				this.$router.push('/personal/personalPage')
			}
		},

		onOrgSeled() {
			if (this.orgIdx >= 0) {
				let org = this.orgs[this.orgIdx]
				let staff = this.staffs.find(it => it.oid == org.id)
				this.setCurOrg(org, staff)
				this.switchPage()
				PubSub.emit("refresh",)
			}
		    this.switchOrg = false
		},

		exit() {
		    this.authedReqR(REQ.loginOut, null, null, null, ()=>{
		        this.setCurUser()
		        this.$router.push('/')
		    })
		},
	}
}
