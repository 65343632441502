
import loginDlg from "@/components/loginDlg.vue";
import LoginAdmin from "@/components/LoginAdmin.vue";
import { UserService } from "@/services";
import PubSub from "@/lib/Pub";
import Cookies from "js-cookie";

import editStrDlg from "@/gDlgs/editStrDlg.vue";
import editTextDlg from "@/gDlgs/editTextDlg.vue";
import selMulDlg from "@/gDlgs/selMulDlg.vue";
import selSglDlg from "@/gDlgs/selSglDlg.vue";
import editStrsDlg from  "@/gDlgs/editStrsDlg.vue";
import editFileDlg from "@/gDlgs/editFileDlg.vue";
import editDateDlg from "@/gDlgs/editDateDlg.vue"

export default {
    name: 'App',
	components:{loginDlg, editStrDlg, editTextDlg, selMulDlg, selSglDlg, editStrsDlg, editFileDlg, editDateDlg},

	data() {
		return {
			showLogin: false,
		};
	},

	created() {
    	this.loadUserInfo()
    },

	mounted() {
		PubSub.subscribe("showLoginEvent", () => {
			this.showLogin = true;
		});
		PubSub.subscribe("LoginSuccessEvent", () => {
			this.showLogin = false;
			this.isAdmin ? this.initAdminInfo() : '';
		});

		PubSub.subscribe("loginOutEvent2", async () => {
			await UserService.loginOutAdmmin();
			window.location.reload();
		});
		PubSub.subscribe("registerEvent", () => {
			if (!this.$root.userInfo.name) {
				this.showLogin = true;
				this.$message("请先登录");
			} else {
				this.$router.push("/reg/2");
			}
		});
		PubSub.subscribe("registerUser", () => {
			this.$router.push("/reg/user");
		});

		this.initCommonDlgs()
	},
	methods: {
		async initAdminInfo() {
			let userInfo = await UserService.getAdminInfo();
			if (userInfo.code === 200) {
				let data = userInfo.data;
				this.$root.adminInfo = data;
				Cookies.set('type', data.type)
			}
		},

		async loginAdmin(data) {
			let res = await UserService.loginAdmin(data.phone, data.password);
			if (res.code == 200) {
				PubSub.publish("LoginSuccessEvent");
				// this.initAdminInfo();
				this.$message("登录成功");
			} else {
				this.$message(res.msg);
			}
		},
	},
};