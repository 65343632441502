const PLATEFORM = XyhConf.plateformN || '医维空间'

const ORG_TYPE = {
    other: 0x00,
    pesonal: 0x01,  //个体户或自由职业者 备用
    group: 0x10, //群或自由团队
    nonCom: 0x20,   //事业单位或政府机构 备用
    hospital: 0x21,
    com: 0x40,  //一般性企业 或其他企业
    drugCom: 0x41,
    CRO: 0x42,
    SMO: 0x43,
}

const Org_Type = [
	ORG_TYPE.hospital,
	ORG_TYPE.drugCom,
	ORG_TYPE.CRO,
	ORG_TYPE.SMO,
	ORG_TYPE.other,
]

const DEPT_TYPE = {
	unkown: 'unkown',
	other: 'other',
	HOO: 'HOO',
	treat: 'treat',
	qa: 'QA',
}

const SITE_TYPE = {
	common:0,
	leader:1,
}

const PRJT_ORG_ROLE = {
    other: 0,
    sponsor: 1,
    CRO: 2,
    SMO: 3,
	chief:4,
    institution: 5,
}

const PRJT_STAGE = {
    other:0,
    I:1,
    II:2,
    III:3,
    IV:4,
    PK:5,
    BE:6,
}

const CR_ROLE ={ //临床研究角色)
	OTHER:'other',

	CRC:'CRC',
	CRN:'CRN',    //研究护士
	DM:'DM',    //药品管理员
	SI:'SI',        //助理研究者 sub-investigator
	CI:'CI',        //合作研究者 co-investigator
	PI:'PI',        //主要研究者 principal investigator
	COI:'COI',       //协调研究者(coordinating investigator
	Inst:'inst',    //机构办管理

	CPM:'CPM',
	CRA:'CRA',
	auditor:'auditor',  //稽查员
	director:'director' ,
	PMgr:'PMgr' ,
	medical:'medical' ,
	QA:'QA' ,
	Reg:'Reg' ,
	CTA:'CTA' ,
}

const STONE_SUIT_STATE= {
	editing: 0,
	available: 5,
	closed: 10,
}

const StoneRoles=[
	CR_ROLE.CPM,
	CR_ROLE.CRA,
	CR_ROLE.director,
	CR_ROLE.PMgr,
	CR_ROLE.medical,
	CR_ROLE.QA,
	CR_ROLE.Reg,
	CR_ROLE.CTA,
]

const PrjtRoles=[
	CR_ROLE.director,
	CR_ROLE.CPM,
	CR_ROLE.PMgr,
	CR_ROLE.CRA,
]

const SiteRoles=[
	CR_ROLE.CRA,
]

const STONE_TYPE={
	must1:1,
	max1:2,
	min1:3,
	any:4,

	monthly:11,
	dmonthly:12,
	seasonly:13,
	yearly:14,
}

const RESEARCH_STAGE={
	all:0,
	preparing:5,
	doing:10,
	done:15,
}

const SITE_STATE = {
	init: 0,
	toopen: 1,
	working: 2,
	closed: 3,
}

const SITE_STATE_C = { //研究中心补充状态， 不再使用
	working: 1,
	transfering: 2,
	closing: 3
}

const MON_TYPE = {
	other: 'other',
	filterSite: 'filterSite',
	openSite: 'openSite',
	transfer: 'transfer',
	closeSite: 'closeSite',
	check: 'check',
	common: 'common',
	together: 'together',
	gov: 'gov',
	govNMPA: 'govNMPA',
	govFDA: 'govNMPA',
	govEMA: 'govEMA',
	govOther:'govOther',
}

const MON_STATE = {
	todo: 0,
	done: 1,
	pass: 2,
	failed: 3,
}

const APPOINTMENT_STATE = {
	todo: 0,
	done: 1,
	pass: 2,
	nopass: 3,
	canceled: 4,
}

const MONDAY_STATE = {
	nopass: -2,
	canceled: -1,
	todo: 0,
	done: 1,
	partPass:2,
	pass: 3,
}

const MONVERI_STATE = {
	nopass: -2,
	relless: -1,
	none: 0,
	pass: 3,
}

const MONTOPIC_CLASS = {
	c1:1,
	c2:2,
	c3:3,
	c4:4,
	c5:5,
	c6:6,
	c7:7,
	c8:8,
	c9:9,
	c10:10,
}

const MONTOPIC_ANSWER = {
	noavail:0,
	not:1,
	yes:2,
}

const RATE = {
	satisfied: 3,
	perfect: 4,
	noRate: 5,
	canBetter: 6,
}

const MVR_STATE = {
	editing:0,
	editing2:1,
	blocked:2,
	submitted:3,
	submitted2:4,
	passed:5,
}

const LAW_STATE = {
	drafting:0x10,
	advising:0x20,
	released:0x30,
	vaild:0x40,
}

const LAW_TYPE = {
	law: 'law',
	regulation: 'regulation',
	deptRegu: 'deptRegu',
	guide: 'guide',
	admRegu: 'admRegu',
	other: 'other',
}

const STONE_CRUCIAL = {
	oth: 1,
	modest: 5,
	very: 10,
}

const STONE_LV ={
	org:1,
	prjt:2,
	site:3,
	siteLeader:4,
	siteNonleader:5,
}

const Stone_Lv =[
	STONE_LV.site,
	STONE_LV.siteLeader,
	STONE_LV.siteNonleader,
]

const GXP = {
	GCP: "GCP",
	GPVP: "GPVP",
	GLP: "GLP",
	GMP: "GMP",
	GSP: "GSP",
	GAMP: "GAMP",
}

const HA = {
	FDA: "FDA",
	CDE: "CDE",
	CFDI: "CFDI",
	EMA: "EMA",
	ICH: "ICH",
	ISO: "ISO",
	WHO: "WHO",
}

const ORGREG_STATE = {
	drafting: 0x10,
	rejected: 0x20,
	submitted: 0x30,
	accepted : 0x40,
}

const PRODOUCT = {
	stone: 'stone',
	sop: 'sop',
	docLib: 'docLib',
}

const DOC_TYPE = {
	sop: "sop",
	policy: "policy",
	manual: "manual",
	annex: "annex",
	file: "file",
}

const DOC_ROLE = {
	PM: "PM",
	author: "author",
	translator: "translator",
	auditor: "auditor",
}

const PrjtDocRoles = [
	DOC_ROLE.author,
	DOC_ROLE.translator,
	DOC_ROLE.auditor,
]

const DocPrjtRoles = [
	DOC_ROLE.PM,
	DOC_ROLE.author,
]

const REMARK_STATE = {
	normal:0x01,
	accepted:0x10,
	rejected:0x20,
	canceled:0x30,
	closed:0x50,
}

const LANGUAGE_MODEL = {
	Cn: 'Cn',
	En: 'En',
	CnEn: 'CnEn',
	Cn2En: 'Cn2En',
}

const SMSNOTIWDOC_TYPE = {
	docUped: 'docUped',
	docRemarked: 'docRemarked',
}

const DOC_STATE = {
	draft: 0x10,
	remarking: 0x20,
	fixed: 0x30,
	issued: 0x40,
}

const Doc_FILE_WAY = {
	gened: 'gened',
	upload: 'upload',
}

const STAFF_STATE = {
	ok: 0x00,
	stopped: 0x20,
}

const DOC_FEE_STATE = {
	todo: 0x10,
	back: 0x20,
	applied: 0x30,
	waiting: 0x40,
	received: 0x50,
}

let rawExp = {
	STAFF_STATE,

	DOC_FEE_STATE,
	DOC_ROLE,
	DOC_TYPE,
	PrjtDocRoles,
	DocPrjtRoles,
	REMARK_STATE,
	LANGUAGE_MODEL,
	SMSNOTIWDOC_TYPE,
	DOC_STATE,
	Doc_FILE_WAY,

	ORG_TYPE,
	Org_Type,
	DEPT_TYPE,
	PRJT_ORG_ROLE,

	ORGREG_STATE,

	GXP,
	HA,
	LAW_STATE,
	LAW_TYPE,

	PRJT_STAGE,
	CR_ROLE,

	SITE_TYPE,
	SITE_STATE,
	SITE_STATE_C,

	STONE_CRUCIAL,
	STONE_TYPE,
	STONE_SUIT_STATE,
	RESEARCH_STAGE,

	Stone_Lv,
	StoneRoles,
	SiteRoles,
	PrjtRoles,

	MON_TYPE,
	MON_STATE,
	MONDAY_STATE,
	MONVERI_STATE,

	APPOINTMENT_STATE,

	MONTOPIC_CLASS,
	MONTOPIC_ANSWER,

	RATE,
	MVR_STATE,

	STONE_LV,

	PRODOUCT,
}

let {...exp} = rawExp
for (let p in rawExp) exp[p+'set'] = new Set(Object.values(rawExp[p]))
Object.assign(exp, {PLATEFORM})

module.exports = exp