import siteNav from "@/nav/site.vue";
import REQ from "@/app/REQ";

let deflt = {
	site:{},
}

export default {
	name: 'siteCon',
	props:['sid'],
	components: { siteNav},

	data() {
		return Object.assign({

		}, deflt)
	},

	beforeRouteUpdate (to, from, next) {
		if(to.params.sid != this.sid)
			this.req2Data(REQ.siteInfo, to.params.sid)
		next()
	},

	created() {
		this.req2Data(REQ.siteInfo, this.sid)
	},

	methods: {
	}
}

