import Cookies from "js-cookie";
import axios from "axios";
export default {
	data() {
		return {
			formData: {
				phone: "",
				password: ""
			},
			rules: {
				phone: [{ required: true, message: "必填", trigger: "blur" }],
				password: [{ required: true, message: "请输入密码", trigger: "blur" }]
			}
		};
	},
	methods: {
		submitForm(formData) {
			this.$refs[formData].validate(async valid => {
				if (valid) {
					this.$emit("loginClick", {
						phone: this.formData.phone,
						password: this.formData.password
					});
				}
			});
		}
	}
};
