import gDlgMix from "@/gDlgs/gDlgMix";

export default {
    name: "editStrsDlg",
    mixins:[ gDlgMix ],

    data() {
        return {
            items:[],
            labelKN:'',
            wkts:{},
        }
    },

    methods: {

        setParams(items, labelKN,obj) {
            Object.assign(this,{items,labelKN,wkts:obj || {}})
        },

        results() {
            return [this.wkts]
        },
    }
};
