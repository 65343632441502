import REQ from "../app/REQ";
import {upWFoundById} from "@/utils/xyhUtils";
import editLinkOrgDlg from "@/dialg/editLinkOrgDlg.vue";
import editLinkOrgDeptDlg from "@/dialg/editLinkOrgDeptDlg.vue"

export default {
	name: "linkOrgs",
	components: {editLinkOrgDlg, editLinkOrgDeptDlg,},

	data() {
		return {
			linkOrgs: [],
			linkOrg: null,
			lOrgId: '',
			lOrgDepts: [],
			lOrgDept: null,
			manyLinkOrgs: null,
			inputVisible: [],
			inputValue: [''],
		};
	},

	created() {
		this.getlinkOrgs()
	},

	methods: {
		getlinkOrgs() {
			this.req2Data(REQ.linkOrgs, null, 'linkOrgs',)
		},

		editLinkOrg(linkOrg) {
			this.linkOrg = linkOrg
			this.showDlg('editLinkOrgDlg')
		},

		addLinkOrgDept(id) {
			this.lOrgId = id
			this.showDlg('editLinkOrgDeptDlg')
		},

		getlinkOrg(id) {
			this.req2Data(REQ.linkOrg, {id}, {depts: 'lOrgDepts'}, () => {
				upWFoundById(this.lOrgDepts, this.DeptTypes, 'type', (lOrgDept, DeptType) => lOrgDept.typeN = DeptType.name)
			})
		},

		editLinkOrgDept(lOrgDept) {
			this.lOrgDept = lOrgDept
			this.showDlg('editLinkOrgDeptDlg')
		},

		addDept(idx) {
			this.manyLinkOrgs[idx].depts.push({name: this.inputValue[idx], type: this.DEPT_TYPE.treat})
			this.inputValue[idx] = ''
			this.inputVisible[idx] = false
		},

		submit() {
			let {manyLinkOrgs} = this
			let add = true
			manyLinkOrgs.forEach(it => {if (this.chk2msg(it.name, '医院名不可为空')) {
				add = false
				return false
			}})
			if(add)
			this.req2Data(REQ.addLinkOrgs, {lOrgs: manyLinkOrgs}, {}, () => {
				this.manyLinkOrgs = null
				this.getlinkOrgs()
			})
		},

		insert(idx) {
			this.manyLinkOrgs.splice(idx, 0, {sname: '', name: '', dept: '', depts: []})
		},

		remove(idx) {
			this.manyLinkOrgs.splice(idx, 1)
		},

		//文件上传调用的方法
		beforeUpload(file) {
			let XLSX = require("xlsx");
			let reader = new FileReader();
			reader.onload = e => {
				let data = e.target.result;
				let workbook = XLSX.read(data, {type: 'binary'});
				let sheetNames = workbook.SheetNames; // 工作表名称集合
				let worksheet = workbook.Sheets[sheetNames[0]]; // 这里我们只读取第一张sheet
				let title = {
					name: '医院名称',
					dept: '科室',
				}                    //标题
				let math = [] //全部的数值
				let allCell = [] // 全部的数据
				let names = {} //符合标题的项
				let someData = {}   //前十行
				let titleCoordinate = ''  //标题位置
				let LinkOrgs = []
				for (let it in worksheet) {
					let Regx = /^[A-Z0-9]*$/;
					if (Regx.test(it)) allCell.push(it)
				}
				allCell.forEach(it => {
					math.push(it.replace(/[^0-9]/g, ''))
				})
				for (let it in worksheet) {
					if (it.replace(/[^0-9]/g, '') <= 10) {
						someData[it] = worksheet[it]
					}
				}
				for (let it in someData) {
					for (let i in title) {
						if (someData[it].v == title[i]) {
							names[i] = it.replace(/[^a-zA-Z]/g, '')
							titleCoordinate = it.replace(/[^0-9]/g, '')
						}
					}
				}
				if (Object.keys(names).length < Object.keys(title).length) {
					this.$message('读取失败，格式有误，请修改后再次读取')
					return
				}
				LinkOrgs = Array.from(
					{length: Math.max(...math)},
					() => ({name: '', dept: '', depts: []})
				);
				LinkOrgs.forEach((it, idx) => {
						for (let j in title) {
							if (worksheet[names[j] + (idx + 1)]) it[j] = worksheet[names[j] + (idx + 1)].v
						}
					}
				)
				LinkOrgs.splice(0, titleCoordinate)
				LinkOrgs.forEach(it => {
					it.type = this.ORG_TYPE.hospital
					it.dept.split(/[,，、 ]/).forEach((item, idx) => {
						it.depts[idx] = {name: item, type: this.DEPT_TYPE.treat}
					})
				})
				this.inputVisible = this.inputVisible.concat(new Array(LinkOrgs.length).fill(false))
				this.manyLinkOrgs = LinkOrgs
				this.$message({
					message: '读取成功，请确认有无遗漏后进行提交',
					type: 'success'
				});
			};
			reader.readAsBinaryString(file);
			return false;
		},

		deleteLinkOrg() {

		}
	}
};
