import REQ from '@/app/REQ'
import addStoneDlg from "./addStoneDlg.vue";
import {mapGetters} from "vuex";

export default {
    components:{addStoneDlg},
    props:['id'],

    data() {
        return {
            name:'',
            suit: {},
            studyOrSite:false,
            stones: [],
        }
    },

    computed: {
        ...mapGetters(['amIAdmin']),
    },

    created() {
        if(this.id && this.id!= 'new') this.getStones()
    },

    methods: {
        getStones() {
            this.req2Data(REQ.stoneSuit, {id:this.id}, {stones:'stones',suit:'suit'})
        },

        renew(){
            this.editStr(this.suit.name,'修改模板名称',name=> {
                this.req2Data(REQ.upStoneSuit, {id: this.id, name})
                this.getStones()
            })
        },

        Submit(){
            if(this.chk2msg(this.name,'请填写模板名称')) return
            if(!this.id || this.id == 'new') this.req2Data(REQ.addStoneSuit, {name:this.name,stones:this.stones},'suit',
                ()=>{this.goto('stoneSuit/' + this.suit.id)})
        },

        swap(idx){
            let t =this.stones[idx]
            this.stones.splice(idx,1)
            this.stones.splice(idx-1,0,t)
        },

        upData() {
            this.req2Data(REQ.stoneSuit, {id:this.id}, {stones:'stones',suit:'suit'})
        },

        disableStone(id){
            this.$confirm('此操作将永久废除该里程碑, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.req2Data(REQ.disableStone, {id},{},()=>this.getStones())
                this.$message({type: 'success', message: '废除成功!'});
            }).catch(() => {
                this.$message({type: 'info', message: '已取消废除'});
            });
        },

        addStone(stone){
            this.stones.push(stone)
        },

        onStoneAdded(stone) {
            if(this.id && this.id != 'new')this.upData()
        },

        remove(idx) {
            this.stones.splice(idx, 1)
        },
    },

}
