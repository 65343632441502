<template>
  <div class="remark">
    <p v-for="(remark,idx) in remarks || []" v-if="showComment && !remark.repliedId && remark.state != REMARK_STATE.canceled" :key="remark.id"
       :style="remark.textStyle">
      {{ remark.remarker }}：{{ remark.content }}<br/>
      <span class="reply">{{ remark.createDate }}
        <span v-if="remark.state != REMARK_STATE.closed">
          <a @click="closeRemark(remark)">关闭</a>
          <a @click="cancelRemark(remark)">取消 </a>
          <a @click="comment(remark.fdPath,remark.id)">回复</a>
        </span>
        <a v-if="remark.remarks.length > 0 && remark.state == REMARK_STATE.closed" @click="onShowReply(idx)">{{ showReply[idx] ? '收起':'展开' }}</a>
      </span><br/>
      <span v-for="it in remark.remarks" :key="it.id" v-if="(remark.state != REMARK_STATE.closed || showReply[idx]) && it.state != REMARK_STATE.canceled" :style="it.textStyle" :class="it.repliedId ? 'indent':{}">
        {{ it.remarker }}：{{ it.content }}<br/>
        <span class="reply" :class="it.repliedId ? 'indent':{}">{{ it.createDate }}
          <span v-if="remark.state != REMARK_STATE.closed">
            <a @click="cancelRemark(it)">取消 </a>
            <a @click="comment(it.fdPath,it.id)">回复</a>
          </span>
        </span><br/>
      </span>
    </p>
  </div>
</template>

<script>
import docEditState from "@/doc/docEditState";

export default {
  name: "docItemRemarks",
  props:['path'],
  inject:['comment','cancelRemark','closeRemark',],

  data() {
    return{
      showReply:[],
    }
  },

  computed: {
    showComment: _ => docEditState.showComment,  //评论显示状态
    remarks(){ return docEditState.remarksMap[this.path] || [] },
  },

  created() {
    this.showReply = this.remarks.map(_ => false)
  },

  methods:{
    onShowReply(idx){
      this.$set(this.showReply,idx,!this.showReply[idx])
    },
  }
}
</script>

<style lang="less" src="../assets/css/doc.less" scoped/>