import { render, staticRenderFns } from "./follow.vue?vue&type=template&id=1c94d23a&scoped=true&"
import script from "./follow.js?vue&type=script&lang=js&"
export * from "./follow.js?vue&type=script&lang=js&"
import style0 from "./follow.less?vue&type=style&index=0&id=1c94d23a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c94d23a",
  null
  
)

export default component.exports