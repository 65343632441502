import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import {DOC_ROLE} from "@/consts"

export default {
	name: "addDocPrjtStaffDlg",
	mixins: [formDlgMix],
	props: ['Id','addPM','addDocStaff'],

	data() {
		return {
			staffId:'',
			ourStaffs: [],
			role:'',
			roleDes: [
				{
					PM: '项目经理具有本项目的所有权限，包括项目信息的修改，项目或文档成员的的管理，文档的创建、内容编辑、审阅',
					author: '项目作者能够在本项目内创建文档',
					auditor: '项目审阅者能够审阅本项目内的所有文档，撰写审阅意见',
				}, {
					author:'文档作者能够修改本文档的信息，管理本文档的成员，撰写审阅意见',
					translator:'文档翻译能够修改本文档的内容，撰写针对本文档内容部分的审阅意见',
					auditor:'文档审阅者能够针对本文档的内容撰写审阅意见',
				}
			],
		}
	},

	watch: {
		working() {
			this.req2Data(REQ.ourStaffs,null,'ourStaffs')
			Object.assign(this, {staffId:'', role:'',})
			if(this.addDocStaff) this.role = DOC_ROLE.auditor
		}
	},

	methods: {
		ok() {
			let {Id, staffId, role, addDocStaff,addPM} = this
			if(addDocStaff) {
				this.reqThenBk(REQ.addDocStaff, {docId: Id, staffId, role,})
			} else {
				if(addPM) role = DOC_ROLE.PM
				this.reqThenBk(REQ.addDocPrjtStaff, {prjtId: Id, staffId, role,})
			}
		}
	}
};
