
import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import { CR_ROLE, PRJT_ORG_ROLE, PRJT_STAGE, } from '@/consts.js'


export default {
	name: "addPrjtDlg",
	mixins:[ formDlgMix ],

	data() {
		return {
			prjt: {
				num: '',
				name: '',
				des: '',
				diseaseN: '',
				stage: PRJT_STAGE.III,
				myRole: CR_ROLE.CPM,
				orgRole: PRJT_ORG_ROLE.CRO,
				// protocolNum:'',
				sponsorN:'',
			}
		}
	},

	methods: {
		onOk() {
			this.authedReqR(REQ.addPrjt, this.prjt, ()=>{
				this.working = false
				this.$emit("prjtAdded")
			})
		}
	}
};
