import { SignComponyUrl, 
        SignMechanismUrl, 
        InstitutionListUrl, 
        SignTeachUrl, 
        InstitutionSignInfoUrl,
        EnterpriseSignInfoUrl,
        TeachSignInfoUrl,
        DownloadEnterUrl,
        DownloadInsUrl,
        DownloadTeachUrl,
      } from './config'
import $http from './base'



function signCompony(formData) {
  return $http({
    url: SignComponyUrl,
    data: formData,
  }).then(function(res) {
    return res.data
  })
}

function signMechansim(formData) {
  return $http({
    url: SignMechanismUrl,
    data: formData,
  }).then(function(res) {
    return res.data
  })
}

function getInstitutionList() {
  return $http({
    url: InstitutionListUrl
  }).then(res => res.data)
}

function signTeacher(data) {
  return $http({
    url: SignTeachUrl,
    data
  }).then(res => res.data)
}

function getSignInfo(type) {
  let urlList = [
    InstitutionSignInfoUrl,
    EnterpriseSignInfoUrl,
    TeachSignInfoUrl,
  ];
  return $http({
    url: urlList[type-1],
  }).then(res => res.data)
}

function downloadFile(type, data) {
  let urlList = [
    DownloadInsUrl,
    DownloadEnterUrl,
    DownloadTeachUrl,
  ];

  return $http({
    url: urlList[type - 1],
    data,
    headers: {
      'content-type': 'application/msword',
    },
    responseType: 'blob',
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    let fileName = [
      '临床试验机构申请表.docx',
      '企业成员申请申请表.docx',
      '带教老师申请表.docx',
    ];
    link.href = url;
    link.setAttribute('download', fileName[type-1]);
    document.body.appendChild(link);
    link.click();
  })
}

export default {
  signCompony,
  signMechansim,
  signTeacher,
  getInstitutionList,
  getSignInfo,
  downloadFile,
}
