import currencyInput from "./currencyInput.vue";
import docEditState from "@/doc/docEditState";
import {LANGUAGE_MODEL} from "@/consts";

export default {
    name: "docItem",
    props:['valCn','valEn','path','w','modifiedCn','modifiedEn','type', 'relation', 'options', 'refObj'],
    inject: ['getRemarksMap','comment',],
    components:{currencyInput},

    data() {
        return{
        }
    },

    computed:{
        CnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.En
        },
        EnOn(){
            return docEditState.lanMode !== LANGUAGE_MODEL.Cn
        }
    },

    methods:{

        upData(v, L){
            if(typeof v == 'object'){
                this.$emit('update:valCn', v.name)
                this.$emit('update:valEn', v.name_E)
                this.$emit('update:valId', v.id)
            }else {
                if(L == 'C'){
                    this.$emit('update:valCn', v)
                }else if(L == 'E'){
                    this.$emit('update:valEn', v)
                }
            }
        }
    }
}