import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "addCapaDlg",
	props: [ "issueId" ],
	mixins:[ formDlgMix ],

	watch:{
		issueId(nv){ this.capa.issueId = nv}
	},

	data() {
		return {
			capa: {
				issueId: this.issueId,
				content: '',
				planDate: '',
				date: '',
			}
		}
	},

	methods: {
		onOk() {
			this.authedReqR(REQ.addCapa, this.capa, ()=>{
				this.working = false
				this.$emit("capaAdded")
			})
		}
	}
};
