import REQ from "../app/REQ";
import {upWFoundById} from "../utils/xyhUtils"
import capaCard from "@/cards/capa.vue"
import addCapaDlg from "@/dialg/addCapaDlg.vue";

export default {
	name: "issue",
	components:{capaCard, addCapaDlg},
	props: ['id'],

	data() {
		return {
			prjt: {},
			site:{},
			hospital:{},
			dept:{},
			monitor:{},
			issue:{},
			capas:[],
			topic:{},
		};
	},

	created() {
		this.req2Data(REQ.issueInfo, this.id)
	},

	methods: {
		onNewCapa() {},

	}
};
