import REQ from "../app/REQ";
import {upWFoundById} from "@/utils/xyhUtils";

export default {
	name: "mySites",


	data() {
		return {
			prjts: [],
			sites: [],
			hospitals: [],
			depts: [],
		};
	},

	created() {
		this.getSites()
	}
	,

	methods: {
		getSites() {
			this.req2Data(REQ.mySites, null, null,
				({sites, prjts, staffs, siteStaffs}) => {
					upWFoundById(siteStaffs, staffs, "staffId", (siteStaff, staff) => siteStaff.name = staff.name)
					upWFoundById(siteStaffs, sites, "siteId", (siteStaff, site) => {
						if(siteStaff.role == 'CRA')site.craN = site.craN ? site.craN + '、' + siteStaff.name : siteStaff.name
					})
					upWFoundById(sites, prjts, 'prjtId', (site, prjt) => {
						site.prjtN = prjt.name
						site.prjtNum = prjt.num
					})
				}
				)
		},

		onDelPrjt(id) {

		}
		,

		onEditPrjt(id) {

		}
		,
	}
}
;
