import {PLATEFORM} from '@/consts'
import primaryCon from './primary.vue'
import myPrjts from "@/coms/myPrjts.vue";
import mySites from "@/coms/mySites.vue";
import site from "@/coms/site.vue"
import monitor from "@/coms/monitor.vue"
import issue from "@/coms/issue.vue"
import myOrg from "@/org/myOrg.vue";
import prjtRts from "@/views/prjtRts.js";
import deving from "@/coms/deving.vue";
import {genPgRtP, genPgRt} from './rtUtls'
import taskCon from '@/views/task.vue'
import taskRts from "@/views/taskRts";
import sops from "@/doc/sops.vue"
import stone from "@/stone/Stone.vue"
import stoneSuit from "@/stone/stoneSuit.vue";
import stoneSuits from "@/stone/stoneSuits.vue";
import stoneMarkState from "@/stone/stoneMarkState.vue";
import abbrs from "@/doc/abbrs.vue"
import terms from "@/doc/terms.vue"
import refLaws from "@/doc/refLaws.vue";
import docTplts from "@/doc/docTplts.vue"
// import useTime from "../stone/useTime.vue";
import companyPrjt from "@/coms/companyPrjt.vue";
import editSop from "@/doc/editSop.vue";
import linkOrgs from "@/stone/linkOrgs.vue"
import myDocPrjts from "@/doc/myDocPrjts.vue"
import myDocs from "@/doc/myDocs.vue"
import libDocPrjts from "@/docLib/libDocPrjts.vue";

export default {
	path: '/main',  component: primaryCon,
	children: [
		// prjtRts,
		taskRts,


		genPgRt(myPrjts, 'myPrjts', '我的项目'),
		genPgRt(mySites, 'mySites', '我的中心'),
		genPgRt(companyPrjt, 'companyPrjt', '公司项目'),
		genPgRt(myOrg, 'myOrg', '我的单位'),
		// genPgRt(useTime, 'useTime', '里程碑工时配置'),



		//带参数的路由
		//genPgRtP(prjt, 'prjt', '项目详情'),
		genPgRtP(site, 'site','中心详情'),
		genPgRtP(monitor, 'monitor','监查详情'),
		genPgRtP(issue, 'issue','Issue详情'),

		{path: 'tasks', component: taskCon},
		{path: 'issues', component: deving},
		{path: 'msgs', component: deving},
		{path: 'abbrs', component: abbrs},
		{path: 'terms', component: terms},
		{path: 'refLaws', component: refLaws},
		{path: 'sops',component: sops},
		{path: 'editSop', component: editSop},
		{path: 'myDocs',component: myDocs},
		{path: 'myDocPrjts', component: myDocPrjts},
		{path: 'libDocPrjts', component:libDocPrjts},

		{path: 'stoneSuits',component:stoneSuits },
		{path: 'stoneMarkState', component: stoneMarkState},
		{path: 'stoneSuit/:id', props: true, component: stoneSuit},
		{path: 'stone/:id', props: true, component: stone},
		{path: 'linkOrgs', component: linkOrgs},
		{path: 'docTplts', component: docTplts},


		{
			path: 'statistics', name:'statistics', meta: { title: '机构统计' },
			component: () => import('../components/organization/statistics.vue'),
		},
		{
			path: 'auditOrg/:type', name:'review', meta: { title: '组织审核' },
			component: () => import('./review/index.vue'),
		},
		{
			path: 'craOrgStatistics', name:'craOrgStatistics', meta: { title: '机构统计' },
			component: () => import('../components/craOrganization/craOrgStatistics.vue'),
		},
		{
			path: 'craManagement', name:'craManagement', meta: { title: 'CRA管理'},
			component: () => import('../components/craOrganization/craManagement.vue'),
		},
		{
			path: 'workingTime/:id', name:'workingTime', meta: { title: '监查工时报表'},
			component: () => import('../components/craOrganization/workingTime.vue'),
		},
		{
			path: 'inspectParticulars/:id', name:'inspectParticulars', meta: { title: '项目监查详情' },
			component: () => import('../components/craOrganization/inspectParticulars.vue'),
		},
		{
			path: 'projectTabel', name:'projectTabel', meta: { title: '项目监查报表'},
			component: () => import('../components/craOrganization/projectTabel.vue'),
		},
		{
			path: 'CraDetails/:id', name:'CraDetails', meta: { title: 'CRA管理'},
			component: () => import('../components/craOrganization/CraDetails.vue'),
		},
		{
			path: 'CraBidding', name:'CraBidding', meta: { title: 'CRA管理' },
			component: () => import('../components/craBidding/craBidding.vue'),
		},
		{
			path: 'CramouthStatistics', name:'CramouthStatistics', meta: { title: 'CRA管理' },
			component: () => import('../components/craBidding/CramouthStatistics.vue'),
		},
		{
			path: 'statistics1', name:'statistics1', meta: { title: 'CRC人数及SMO分布' },
			component: () => import('../components/organization/statistics1.vue'),
		},
		{
			path: 'statistics2', name:'statistics2', meta: { title: 'CRC项目数量排行' },
			component: () => import('../components/organization/statistics2.vue'),
		},
		{
			path: 'statistics3', name:'statistics3', meta: { title: '科室CRC和项目数分布' },
			component: () => import('../components/organization/statistics3.vue'),
		},
		{
			path: 'orCrcParticulars/:id', name:'orCrcParticulars', meta: { title: 'CRC详情' },
			component: () => import('../components/organization/orCrcParticulars.vue'),
		},
		{
			path: 'officeList', name:'officeList', meta: { title: '机构统计-科室人员',
			component: () => import('../components/organization/officeList.vue'),
			}
		},
		{
			path: 'crcWorkList', name:'crcWorkList', meta: { title: '����ͳ��-CRC������',
			component: () => import('../components/organization/crcWorkList.vue'),
			}
		},
		{
			path: 'projectWorkList', name:'projectWorkList', meta: { title: '����ͳ��-��Ŀ������',
			component: () => import('../components/organization/projectWorkList.vue'),
			}
		},
		{
			path: 'officeProject', name:'officeProject', meta: { title: '机构统计-科室项目' },
			component: () => import('../components/organization/officeProject.vue'),
		},
		{
			path: 'crcProject', name:'crcProject', meta: { title: '机构统计-crc项目' },
			component: () => import('../components/organization/crcProject.vue'),
		},
		{
			path: 'mouthStatistics', name:'mouthStatistics', meta: { title: '机构统计-月度项目' },
			component: () => import('../components/organization/mouthStatistics.vue'),
		},
		{
			path: 'smoStatistics', name:'smoStatistics', meta: { title: 'SMO统计' },
			component: () => import('../components/smo/smoStatistics.vue'),
		},
		{
			path: 'somProjectParticulars/:id', name:'somProjectParticulars', meta: { title: '项目详情' },
			component: () => import('../components/smo/somProjectParticulars.vue'),
		},
		{
			path: 'organizationList', name:'organizationList', meta: { title: 'SMO统计-机构人员' },
			component: () => import('../components/smo/organizationList.vue'),
		},
		{
			path: 'projectList', name:'projectList', meta: { title: 'SMO统计-项目人员' },
			component: () => import('../components/smo/projectList.vue'),
		},
		{
			path: 'platformStatistics', name:'platformStatistics', meta: { title: '平台统计' },
			component: () => import('../components/platform/platformStatistics.vue'),
		},
		{
			path: 'plProjectParticulars/:id', name:'plProjectParticulars', meta: { title: '项目详情' },
			component: () => import('../components/platform/plProjectParticulars.vue'),
		},
		{
			path: 'plCrcParticulars/:id', name:'plCrcParticulars', meta: { title: 'CRC详情' },
			component: () => import('../components/platform/plCrcParticulars.vue'),
		},
		{
			path: 'smoPersonnel', name:'smoPersonnel', meta: { title: '平台统计-SMO人员' },
			component: () => import('../components/platform/smoPersonnel.vue'),
		},
		{
			path: 'organizationPersonnel', name:'organizationPersonnel', meta: { title: '平台统计-机构人员' },
			component: () => import('../components/platform/organizationPersonnel.vue'),
		},
		{
			path: 'organizationProject', name:'organizationProject', meta: { title: '平台统计-机构项目' },
			component: () => import('../components/platform/organizationProject.vue'),
		},
		{
			path: 'crcManagement', name:'crcManagement', meta: { title: 'crc管理' },
			component: () => import('../components/crc/crcManagement.vue'),
		},
		{
			path: 'crcParticulars/:id', name:'crcParticulars', meta: { title: 'crc详情' },
			component: () => import('../components/crc/crcParticulars.vue'),
		},
		{
			path: 'projectManage', name:'projectManage', meta: { title: '项目管理' },
			component: () => import('../components/project/projectManage.vue'),
		},
		{
			path: 'interviewParticulars/:id', name:'interviewParticulars', meta: { title: '访视详情' },
			component: () => import('../components/project/interviewParticulars.vue'),
		},
		{
			path: 'subjects/:patientId/:projectId', name:'subjects', meta: { title: '受试者' },
			component: () => import('../components/project/subjects.vue'),
		},
		{
			path: 'projectParticulars/:id', name:'projectParticulars', meta: { title: '项目详情' },
			component: () => import('../components/project/projectParticulars.vue'),
		},
		{
			path: 'sickList/:id', name:'sickList', meta: { title: '患者列表' },
			component: () => import('../components/project/sickList.vue'),
		},
		{
			path: 'sickParticulars/:id/:patientId', name:'sickParticulars', meta: { title: '患者详情' },
			component: () => import('../components/project/sickParticulars.vue'),
		},
		{
			path: 'addProject/:id/:isEidt', name:'addProject', meta: { title: '添加项目' },
			component: () => import('../components/project/addProject.vue'),
		},
		{
			path: 'reportQuery', name:'reportQuery', meta: { title: '报表查询' },
			component: () => import('../components/platform/reportQuery.vue'),
		},
		{
			path: 'crcTest', name:'crcTest', meta: { title: 'crcTest' },
			component: () => import('../components/project/vue-date.vue'),
		},
		{
			path: 'assess', name:'assess', meta: { title: '人员评估' },
			component: () => import('../components/personnel/assess.vue'),
		},
		{
			path: 'detail/:id', name:'detail', meta: { title: '人员详情' },
			component: () => import('../components/personnel/detail.vue'),
		},
		{
			path: 'dailyProgress', name:'dailyProgress', meta: { title: '人员评估' },
			component: () => import('../components/organization/dailyProgress.vue'),
		},
		{
			path: 'changePassword', name:'changePassword', meta: { title: '修改密码' },
			component: () => import('../components/craOrganization/changePassword.vue'),
		},
		{
			path: 'CraMechanismList', name:'CraMechanismList', meta: { title: 'CRA管理' },
			component: () => import('../components/craMechanism/CraMechanismList.vue'),
		},
		{
			path: 'CraDepartment/:id', name:'CraDepartment', meta: { title: 'CRA管理' },
			component: () => import('../components/craMechanism/CraDepartment.vue'),
		},
		{
			path: 'BidCompanyList', name:'BidCompanyList', meta: { title: 'CRA管理' },
			component: () => import('../components/craMechanism/BidCompanyList.vue'),
		},
		{
			path: 'ProjectList/:id', name:'ProjectList', meta: { title: 'CRA管理' },
			component: () => import('../components/craMechanism/ProjectList.vue'),
		},
		{
			path: 'CrcManageList/:id', name:'CrcManageList', meta: { title: 'CRA管理' },
			component: () => import('../components/craMechanism/CrcManageList.vue'),
		},
		{
			path: 'CrcSelectList/:id', name:'CrcSelectList', meta: { title: 'CRA管理' },
			component: () => import('../components/craMechanism/CrcSelectList.vue'),
		},
		{
			path: 'CrcMyList/:id', name:'CrcMyList', meta: { title: 'CRA管理' },
			component: () => import('../components/craMechanism/CrcMyList.vue'),
		},
		{
			path: 'profiles', name:'profiles', meta: { title: '方案列表' },
			component: () => import('../components/profile/profiles.vue'),
		},
		{
			path: 'profile/:id',
			props: true, name:'profile', meta: { title: '方案查看' },
			component: () => import('../components/profile/profile.vue'),
		},
		{
			path: 'editProfile/:id',
			props: true, name:'editProfile', meta: { title: '方案编辑' },
			component: () => import('../components/profile/editProfile.vue'),
		},
		{
			path: 'editSop/:id',
			props: true, name:'editSop', meta: { title: 'sop编辑' },
			component: () => import('../doc/editSop.vue'),
		},
		{
			path: 'editPolicy/:id',
			props: true, name:'editPolicy', meta: { title: '政策文件编辑' },
			component: () => import('../doc/editPolicy.vue'),
		},
		{
			path: 'editManual/:id',
			props: true, name:'editManual', meta: { title: '质量手册编辑' },
			component: () => import('../doc/editManual.vue'),
		},
		{
			path: 'docTabs/:id',
			props: true, name:'docTabs', meta: { title: '文档辅助设计' },
			component: () => import('../doc/docTabs.vue'),
		},
		{
			path: 'refLaws/:id',
			props: true, name:'editRefLaw', meta: { title: '法规编辑'},
			component: () => import('../doc/editRefLaw.vue')
		},
		{
			path: 'departments', name:'departments', meta: { title: '部门管理' },
			component: () => import('../components/department/depts.vue'),
		},
		{
			path: 'prjtStoneSuit/:pid',
			props: true, name:'prjtStoneSuit', meta: { title: '项目绑定里程碑模板' },
			component: () => import('../coms/prjtStoneSuit.vue'),
		},
		{
			path: 'prjt/:pid',
			props: true, name:'prjt', meta: { title: '项目详情' },
			component: () => import('../coms/prjt.vue'),
		},
		{
			path: 'docDept/:id',
			props: true, name:'docDept', meta: { title: '文档归口部门详情' },
			component: () => import('../doc/docDept.vue'),
		},
		{
			path: 'docPrjt/:pid',
			props: true, name:'docPrjt', meta: { title: '文档项目详情' },
			component: () => import('../doc/docPrjt.vue'),
		},
		{
			path: 'docPrjtTabs/:pid',
			props: true, name:'docPrjtTabs', meta: { title: '文档项目详情' },
			component: () => import('../doc/docPrjtTabs.vue'),
		},
		{
			path: 'docTplt/:id',
			props: true, name:'docTplt', meta: { title: 'sop模板详情' },
			component: () => import('../doc/docTplt.vue'),
		},
		{
			path: 'doc/:id',
			props: true, name:'doc', meta: { title: '文档详情' },
			component: () => import('../doc/doc.vue'),
		},
		{
			path: 'docPrjtRemarks/:id',
			props: true, name:'docPrjtRemarks', meta: { title: '项目评论详情' },
			component: () => import('../doc/docPrjtRemarks.vue'),
		},
		{
			path: 'libDocPrjt/:pid',
			props: true, name:'libDocPrjt', meta: { title: '平台文档项目详情' },
			component: () => import('../docLib/libDocPrjt.vue'),
		},
		{
			path: 'libSop/:id',
			props: true, name:'libSop', meta: { title: '平台sop查看' },
			component: () => import('../docLib/libSop.vue'),
		},
		{
			path: 'libPolicy/:id',
			props: true, name:'libPolicy', meta: { title: '政策文件查看' },
			component: () => import('../docLib/libPolicy.vue'),
		},
		{
			path: 'libManual/:id',
			props: true, name:'libManual', meta: { title: '质量手册查看' },
			component: () => import('../docLib/libManual.vue'),
		},
		{
			path: 'libDoc/:id',
			props: true, name:'libDoc', meta: { title: '平台文档详情' },
			component: () => import('../docLib/libDoc.vue'),
		},
	],
}