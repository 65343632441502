export default {
	path: '/reg',
	component: () => import('./index.vue'),
	children: [
		{
			path: '',
			redirect: '/reg/user'
		},
		{
			path: 'user',
			name: 'regUser',
			component: () => import('./reg.vue')
		},
		{
			path: '2',
			name: 'SignTypeChose',
			component: () => import('./SignTypeChose.vue')
		},
		{
			path: '3',
			name: 'signMechanism',
			component: () => import('./SignMechanism.vue')
		},
		{
			path: 'org',
			name: 'regOrg',
			component: () => import('./regOrg.vue')
		},
		{
			path: '5',
			name: 'signTeacher',
			component: () => import('./SignTeacher.vue')
		}
	],
}