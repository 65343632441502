import $http from './base2'
import {
    InstitutionListPageUrl,
    EnterpriseListPageUrl,
    TeachListPageUrl,
    InstitutionInfoUrl,
    EnterpriseInfoUrl,
    TeachInfoUrl,
    AduitInstitutionUrl,
    AduitEnterpriseUrl,
    AduitTeachUrl,
    ReviewHistoryList,
} from './config'



// 机构审核列表
function getPageList(state, page, type) {
    let url;
    type = type - 0
    if (type === 1) {
        url = InstitutionListPageUrl
    } else if (type === 2) {
        url = EnterpriseListPageUrl
    } else if (type === 3) {
        url = TeachListPageUrl
    }
    return $http({
        url,
        data: {
            page: page - 1,
            state: state,
        }
    }).then(res => res.data)
}

// 企业审核列表数据
function getPageListSmo(state, page, type) {
    let url = EnterpriseListPageUrl;
    return $http({
        url,
        data: {
            page: page - 1,
            state: state,
            type: type
        }
    }).then(res => res.data)
}

function submitReview(id, state, remark, type) {
    let urlList = [
        AduitInstitutionUrl,
        AduitEnterpriseUrl,
        AduitTeachUrl];
    let url = urlList[type - 1];
    return $http({
        url,
        data: {
            id: id,
            state: state,
            remark: remark || ''
        }
    }).then(res => res.data)
}

function getDetailInfo(id, type) {
    let urlist = [
        InstitutionInfoUrl,
        EnterpriseInfoUrl,
        TeachInfoUrl,]
    let url = urlist[type - 1];
    return $http({
        url,
        data: {
            id: id
        }
    }).then(res => res.data)
}

function getHistoryInfo(id, type) {
    let url = ReviewHistoryList;
    return $http({
        url,
        data: {
            id: id,
            type: type
        }
    }).then(res => res.data)
}





export default {
    getPageList,
    getPageListSmo,
    submitReview,
    getDetailInfo,
    getHistoryInfo,
}
