import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "editLinkOrgDlg",
    mixins: [formDlgMix],
    props: ['oldLinkOrg'],

    data() {
        return {
            name:'',
            sname:'',
            type:'',
        }
    },

    watch: {
        working(){
            let {oldLinkOrg} = this
            if(oldLinkOrg){
                Object.assign(this, {name:oldLinkOrg.name, sname:oldLinkOrg.sname, type:oldLinkOrg.type,})
            }else {
                Object.assign(this, {name:'', sname:'', type:'',})
            }
        }
    },

    methods: {
        ok() {
            let {sname, name, type, oldLinkOrg} =this
            if(this.chk2msg(name, '请填写姓名', sname, '请上传word文档', type, '请选择单位类型')) return
            if(oldLinkOrg){
                this.reqThenBk(REQ.upLinkOrg, {id:oldLinkOrg.id, sname, name, type})
            }else {
                this.reqThenBk(REQ.addLinkOrg, {sname, name, type})
            }
        },
    }
};
