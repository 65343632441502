import { LoginUrl, UserInfoUrl, LoginOutUrl, SignCrcUrl, ActiveEmailUrl, ValidateEmailUrl, LoginUrl2,UserInfoUrl2 } from './config'
import Cookies from 'js-cookie'
import $http from './base'
import $http2 from './base2'

function getAdminInfo() {
  return $http2({
    url: UserInfoUrl2
  }).then(function(res) {
    return res.data
  })
}
function loginOutAdmmin(){
  Cookies.set('token2', '')
}
function loginAdmin(phone, password) {
  return $http2({
    url: LoginUrl2,
    data: {
      phone,
      password
    }
  }).then((res) => {
    let data = res.data
    if (data.code === 200) {
      Cookies.set('token2', data.data, {
        expires: 7
      })
    }
    return data;
  })
}

function getUserInfo() {
  // token    
  return $http({
    url: UserInfoUrl
  }).then(function(res) {
    return res.data
  })
}

function loginOut() {
  return $http({
    url: LoginOutUrl
  }).then(function(res) {
    Cookies.set('token', '')
    return res.data
  })
}

function signCrc(formData) {
  return $http({
    url: SignCrcUrl,
    data: formData,
  }).then(function(res) {
    return res.data
  })
}






function activeEmail(email) {
  return $http({
    url: ActiveEmailUrl,
    data: {
      email: email
    }
  }).then(function(res) {
    return res.data
  })
}

function validateEmail(email) {
  return $http({
    url: ValidateEmailUrl,
    data: {
      email: email
    }
  }).then(function(res) {
    return res.data
  })
}

export default {
  loginAdmin,
  loginOutAdmmin,
  getUserInfo,
  getAdminInfo,
  loginOut,
  signCrc,
  activeEmail,
  validateEmail,
}
