import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "reviseRefLawDlg",
    mixins: [formDlgMix],
    props: ['beRefLaw'],

    data() {
        return {
            refLaw: {
                type: '',
                state: '',
                name: '',
                org: '',
                releaseNum: '',
                gxps: [],
                authorities: [],
                adviceDate: '',
                validDate: '',
                releaseDate: '',
                linkUrl:'',
            },
        }
    },

    watch: {
        working() {
            let {beRefLaw} = this
            if (beRefLaw) {
                this.refLaw = {
                    name: beRefLaw.name,
                    type: beRefLaw.type,
                    state: beRefLaw.state,
                    org: beRefLaw.org,
                    releaseNum: beRefLaw.releaseNum,
                    gxps: beRefLaw.gxps,
                    authorities: beRefLaw.authorities,
                    adviceDate: beRefLaw.adviceDate,
                    validDate: beRefLaw.validDate,
                    releaseDate: beRefLaw.releaseDate
                }
            } else {
                this.refLaw = {
                    type: '',
                    state: '',
                    name: '',
                    org: '',
                    releaseNum: '',
                    gxps: [],
                    authorities: [],
                    adviceDate: '',
                    validDate: '',
                    releaseDate: '',
                    linkUrl: ''
                }
            }
        }
    },

    methods: {
        ok() {
            let {refLaw, beRefLaw} = this
            let {name, state, type, gxps} = refLaw
            if(this.chk2msg(name, '请输入法规名称', type, '请输入法规类型', state, '请输入法规状态', gxps.length>0, '请选择至少一个gxp分类')) return
            if (beRefLaw) {
                let newRefLaw = {id: beRefLaw.id}
                for (let it in refLaw) {
                    if (refLaw[it] != beRefLaw[it]) newRefLaw[it] = refLaw[it]
                }
                this.reqThenBk(REQ.upLaw, newRefLaw)
            } else {
                this.reqThenBk(REQ.addLaw, refLaw)
            }
        }
    }
};
