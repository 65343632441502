import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "addIssueDlg",
	props: [ "siteId" ],
	mixins:[ formDlgMix ],

	watch:{
		siteId(nv){ this.issue.siteId = nv}
	},

	data() {
		return {
			issue: {
				siteId: this.siteId,
				title: '',
				content: '',
				occurDate: '',
				findDate: Date(),
				topic:'',
			}
		}
	},

	methods: {
		onOk() {
			this.authedReqR(REQ.addIssue, this.issue, ()=>{
				this.working = false
				this.$emit("issueAdded")
			})
		}
	}
};
