import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";
import {STONE_LV,STONE_TYPE} from "@/consts"


export default {
	name: "addStoneDlg",
	mixins:[ formDlgMix ],
	props:['id','studyOrSite','stoneId'],

	data() {
		return {
			suitId:'',
			stone:{
				name:'',
				criteria:'',
				stage:'',
				level:'',
				type:STONE_TYPE.must1,
				wkts:{},
			},
			note:'',
		}
	},

	watch:{
		working() {
			if (!this.stoneId) {
				this.stone = {name: '', criteria: '', stage: '', level: STONE_LV.prjt, type: STONE_TYPE.must1, wkts: {},}
				if (!this.studyOrSite) this.stone.level = STONE_LV.site
			}
		},
		stoneId(){this.req2Data(REQ.mileStone,{id:this.stoneId},'stone')},
	},

	methods: {
		ok() {
			let {stone} = this
			if(this.chk2msg(stone.name, '请填写里程碑名称', stone.criteria, '请填写衡量标准', stone.stage!=='', '请选择阶段', stone.level, '请选择类型',)) return
			for (let i in stone.wkts) if (!stone.wkts[i]) delete stone.wkts[i]
			if(this.stoneId){
				this.reqThenBk(REQ.upStone, stone)
			} else if (!this.id || this.id == 'new') {
				this.$emit('addStone', stone)
				this.close()
			} else {
				stone.suitId=this.id
				this.reqThenBk(REQ.addStone, stone)
			}
		}
	}
};
