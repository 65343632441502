import REQ from "../app/REQ";
import {upWFoundById} from "../utils/xyhUtils"
import siteCard from '../cards/site.vue'
import addSiteDlg from "@/dialg/addSiteDlg.vue";
import addPrjtStaffDlg from "@/dialg/addPrjtStaffDlg.vue";
import {mapState} from "vuex";

export default {
	name: "prjt",
	components:{ siteCard, addSiteDlg,addPrjtStaffDlg},
	props: ['pid'],

	data() {
		return {
			prjt: [],
			sites:[],
			hospitals:[],
			depts:[],

			amICPM:false,
			amIPrjtStaff:false,
			prjtStaffs:[],
			prjtTplts: [],
			tplts: [],
			prjtTpltMap:{},
			stoneSuit: 0,
			suit:"",
			suits:[],
		};
	},

	computed: {
		...mapState({
			userId: ({curStaff}) => curStaff?.id,
		}),
	},

	created() {
		this.getPrjtInfo()
	},

	methods: {
		getPrjtInfo(){
			let {pid} = this
			this.req2Data(REQ.prjtInfo, pid, null,
				({sites, prjtStaffs,siteCras,staffs}) => {
					sites.sort((e1, e2) => e2.id - e1.id)
					// upWFoundById(sites, hospitals, "hospitalId", (s, h) => s.hospitalN = h.name)
					// upWFoundById(sites, depts, "deptId", (s, d) => s.deptN = d.name)
					upWFoundById(siteCras, staffs, "staffId", (siteCra, staff) => siteCra.staffN = staff.name)
					upWFoundById(siteCras, sites, "siteId", (siteCra, site) => {
						site.craN = site.craN ? site.craN+ '、' + siteCra.staffN:siteCra.staffN
					})
					upWFoundById(prjtStaffs,staffs,'staffId',(prjtStaff,staff)=>prjtStaff.prjtStaffN = staff.name)
					if (prjtStaffs.find(it => it.staffId == this.userId)) {
						this.amIPrjtStaff = true
						if (prjtStaffs.find(it =>it.staffId == this.userId && it.role == this.CR_ROLE.CPM)) this.amICPM = true
						else this.amICPM = false
					} else {
						this.amIPrjtStaff = false
						this.amICPM = false
					}
					this.req2Data(REQ.stoneSuits, {}, { suits: 'suits'})
				})
		},

		removePrjtStaff(prjtStaffId){
			this.req2Data(REQ.rmPrjtStaff, {prjtStaffId},{},()=>this.getPrjtInfo())
		},

		unsetSuit(){
			this.$confirm('确认解绑里程碑模板吗？','提示',{
				confirmButtonText:'确定',
				cancelButtonText:'取消',
				type:'warning',
			}).then(()=>{
				this.req2Data(REQ.unsetPrjtStoneSuit, {prjtId:this.pid},{},()=> {
					this.$message({type:'success',message:'解绑成功！'})
					this.getPrjtInfo()
				})
			})

		},

		setSuit(){
			this.selSgl(this.suits,'绑定里程碑模板',suitId=>{
				this.req2Data(REQ.setPrjtStoneSuit, {suitId,prjtId:this.pid},{},()=>this.getPrjtInfo())
			})
		},
	}
};
