
import axios from 'axios'
import merge from 'lodash/merge'
import Cookies from 'js-cookie'

export default (config)=>{
    const defaultConfig = {
        method: 'post',
        headers: {
          'content-type': 'application/json'
        }
    }
    const token = Cookies.get('token')
    if(token){
        defaultConfig.headers.token = token
    }
    return axios(merge(defaultConfig,config))
}